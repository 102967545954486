import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    background: rgba(52, 152, 219, .7);
    height: 100vh;
`;

export const Form = styled.form`
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 400px;
    padding: 20px;
    background: #FFF;

    img {
        display: block;
        width: 80%;
        margin: 10px auto 40px;
    }

    input {
        flex: 1;
        width: 100%;
        height: 46px;
        padding: 10px;
        margin-bottom: 15px;
    }
`;