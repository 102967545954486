export const Permissions = [
    {
        Title: "Usuários do sistema",
        Value: "usuarios"
    },
    {
        Title: "Produtos",
        Value: "produtos"
    },
    {
        Title: "- Produtos Cad/Upd",
        Value: "produtoscrud"
    },
    {
        Title: "Colaboladores",
        Value: "colabolador"
    },
    {
        Title: "- Colaboladores Cad/Upd",
        Value: "colaboladorcrud"
    },
    {
        Title: "Representantes",
        Value: "representante"
    },
    {
        Title: "- Representantes Cad/Upd",
        Value: "representantecrud"
    },
    {
        Title: "Pedidos",
        Value: "pedidos"
    },
    {
        Title: "- Pedidos (Financeiro)",
        Value: "pedidosfin"
    },

    {
        Title: "Rotas",
        Value: "rotas"
    },
    {
        Title: "- Rotas Cad/Upd",
        Value: "rotascrud"
    },
    {
        Title: "Veículos",
        Value: "veiculos"
    },
    {
        Title: "- Veículos Cad/Upd",
        Value: "veiculoscrud"
    },
    {
        Title: "Carregamento",
        Value: "cargas"
    },
    {
        Title: "- Carregamento Cadastro",
        Value: "cargascrud"
    },
    {
        Title: "- Carregamento Liberação",
        Value: "cargaslib"
    }
];

export const SetoresColl = [
    {
        Title: "Recursos Humanos",
        Value: "RH",
    },
    {
        Title: "Administrativo",
        Value: "admin",
    },
    {
        Title: "Expedição",
        Value: "exped",
    },
    {
        Title: "Almoxarifado",
        Value: "almox",
    },
    {
        Title: "Vendas",
        Value: "vendas",
    },
    {
        Title: "Produção",
        Value: "prod",
    },
    {
        Title: "Outros",
        Value: "other",
    }
];

export const CargosColl = {
    RH: ["Auxiliar Adm. I", "Auxiliar Adm. II", "Auxiliar Adm. III"],
    admin: ["Auxiliar Adm. I", "Auxiliar Adm. II", "Auxiliar Adm. III"],
    exped: ["Auxiliar de Prod. I", "Auxiliar de Prod. II", "Motorista"],
    almox: ["Auxiliar de Prod. I", "Auxiliar de Prod. II"],
    vendas: ["Auxiliar Adm. I", "Auxiliar Adm. II", "Auxiliar Adm. III", "Dir. Comercial", "Vendedor Interno", "Vendedor Externo"],
    prod: ["Auxiliar de Prod. I", "Auxiliar de Prod. II", "Encarregado de Prod.", "Ajudante de Prod."],
    other: ["Temporário", "Auxiliar de Prod. I", "Auxiliar de Prod. II", "Encarregado de Prod.", "Ajudante de Prod.", "Auxiliar Adm. I", "Auxiliar Adm. II", "Auxiliar Adm. III"],
};

const status_color = {
    0: "",
    1: "#2C3E50", // CINZA
    2: "#2ECC71", // VERDE
    3: "#E74C3C", // VERMELHO
    4: "#E67E22", // LARANJA
};

export const status_pedido = {
    0: {
        title: "Aguar. Aprovação (Desconto)",
        color: status_color[4]
    },
    1: {
        title: "Aguar. Aprovação (Financeiro)",
        color: status_color[4]
    },
    2: {
        title: "Orçamento Aprovado",
        color: status_color[1]
    },
    3: {
        title: "Pedido em Andamento",
        color: status_color[1]
    },
    4: {
        title: "Pedido Finalizado",
        color: status_color[2]
    },
    5: {
        title: "Pedido Finalizado (Balcão)",
        color: status_color[2]
    },
    7: {
        title: "Orçamento Expirado",
        color: status_color[3]
    },
    8: {
        title: "Orçamento Recusado",
        color: status_color[3]
    },
    9: {
        title: "Orçamento Cancelado",
        color: status_color[3]
    },
    10: {
        title: "Pedido Cancelado",
        color: status_color[3]
    }
};

export const status_financeiro = {
    0: {
        title: "Aguardando Aprovação de Pedido",
        color: status_color[0]
    },
    1: {
        title: "Aguardando Pagamento",
        color: status_color[4]
    },
    2: {
        title: "Entrada Efetuada (Pagamento Parcial)",
        color: status_color[1]
    },
    3: {
        title: "Pagamento Realizado",
        color: status_color[2]
    },
};

export const status_mercadoria = {
    0: {
        title: "Aguardando Aprovação de Pedido",
        color: status_color[0]
    },
    1: {
        title: "Aguardando Carregamento",
        color: status_color[1]
    },
    2: {
        title: "Aguardando Despacho",
        color: status_color[1]
    },
    3: {
        title: "Pedido Despachado",
        color: status_color[2]
    },
    4: {
        title: "Mercadoria Devolvida",
        color: status_color[4]
    },
    5: {
        title: "Mercadoria Entregue",
        color: status_color[2]
    },
};

export const status_carga = {
    0: {
        title: "Aguardando Carregamento",
        color: status_color[0]
    },
    1: {
        title: "Aguardando Liberação",
        color: status_color[4]
    },
    2: {
        title: "À Caminho",
        color: status_color[1]
    },
    3: {
        title: "Carregamento Finalizado",
        color: status_color[2]
    },
    4: {
        title: "Carregamento Cancelado",
        color: status_color[3]
    },
}

export const status_carga_short = status => {
    switch (status) {
        case 2:
            return "Em rota"

        case 3:
            return "Finalizado"

        case 4:
            return "Cancelado"

        default:
            return "Carregando"
    }
}

export const formPayment = {
    0: 'À vista',

    // 7 dias
    11: '2x 7 dias',
    5: '3x 7 dias',
    9: '5x 7 dias',

    // 10 dias
    6: '10 dias',

    // 14 dias
    4: '5x 14 dias',

    // 15 dias
    10: '5x 15 dias',
    12: '3x 15 dias',

    // 28 dias
    1: '28 dias',
    2: '2x 28 dias',
    3: '3x 28 dias',

    // 30 dias
    7: '30 dias',
    8: '3x 30 dias',
}

export const typePayment = {
    0: 'Dinheiro',
    1: 'Cartão',
    2: 'Cheque',
    3: 'Boleto',
    4: 'Depós./Transf.'
}

export const stringPayment = {
    0: 'Pagamento à vista',
    1: 'Parcial (Entrada)',
    2: 'Parcial (Parcela)',
    3: 'Parcial (Antecipado 50%)',
    4: 'Parcial (Antecipado 100%).'
}

export const stringCheck = {
    0: ' - ',
    1: 'Em compensação',
    2: 'Compensado',
    3: 'Voltou/Estornado'
}
