import React from 'react';
import { stringPayment } from '@components/Constants';
import { isEmpty, doMoney, formatDateToView, compareDates } from '@utils/functions';

import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalClose,
    ModalBody,
    ModalTitle,
    TabList
} from './style';
import { Table } from 'antd';

export default function PageDetails({ dataPayments, onClose }) {
    const handleClose = _ => {
        onClose()
    }

    const columns = [
        {
            title: 'Código',
            width: '10%',
            render: ({ id_payment }) => `#${(`00000${id_payment}`).substr(-5)}`
        }, {
            title: 'Request',
            width: '10%',
            render: ({ id_request }) => `#${(`00000${id_request}`).substr(-5)}`
        }, {
            title: 'Tipo',
            width: '20%',
            render: ({ tipo_pagamento }) => stringPayment[tipo_pagamento]
        }, {
            title: 'Valor',
            width: '20%',
            render: ({ valor }) => doMoney(valor)
        }, {
            title: 'Dt. Venc.',
            width: '20%',
            render: ({ data_vencimento }) => <span>{formatDateToView(data_vencimento)}</span>
        }, {
            title: 'Dt. Pgto',
            width: '20%',
            render: ({ data_pagamento, data_vencimento }) => !data_pagamento && !compareDates(data_vencimento)
                ? (
                    <span style={{ color: 'RED', fontWeight: 'bold' }}>
                        Em atraso
                    </span>
                )
                : (
                    !data_pagamento
                        ? 'Em aberto'
                        : <span>{formatDateToView(data_pagamento)}</span>
                )
        }
    ]

    return !isEmpty(dataPayments) && (
        <Modal>
            <ModalContent>
                <ModalHeader>
                    <ModalTitle>Detalhes financeiro</ModalTitle>
                    <ModalClose onClick={handleClose}></ModalClose>
                </ModalHeader>
                <ModalBody>
                    <TabList financeiro>
                        <li className="bg-clean-default">
                            <b>Total Pedidos:</b>
                            {doMoney(dataPayments.reduce((total, prox) => total + prox.valor, 0))}
                        </li>
                        <li className="bg-clean-default">
                            <b>Total Pago</b>
                            {doMoney(dataPayments.reduce((total, prox) => (
                                total + (prox.data_pagamento != null ? prox.valor : 0)
                            ), 0))}
                        </li>
                        <li className="bg-clean-default">
                            <b>Total Pendente</b>
                            {doMoney(dataPayments.reduce((total, prox) => (
                                total + (prox.data_pagamento == null ? prox.valor : 0)
                            ), 0))}
                        </li>
                    </TabList>
                    <Table
                        className="table-requests"
                        columns={columns}
                        dataSource={dataPayments}
                        size="small"
                        scroll={{ y: 400 }}
                        pagination={{ position: 'none' }}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
