import React, { useState, useEffect } from 'react';
import { URL_BASE } from "@utils/auth";

import { Row, Col } from 'react-bootstrap';
import { UploadGal, TitleSection } from './style';

const GalleryBox = ({onChange, fotos}) => {
    const [imgsGal, setImgsGal] = useState([]);

    const handleChangeFiles = async event => {
        let Files = event.target.files;

        for await (var File of Files) {
            if (
                !File.type.match('image/jpeg') &&
                !File.type.match('image/jpg') &&
                !File.type.match('image/png'))
                continue;
                
            var picReader = new FileReader();
            picReader.onloadend = e => setImgsGal(imgsGal => [...imgsGal, e.target.result]);
            picReader.readAsDataURL(File);
        }
    }

    const handleRemoveItem = async Item => {
        let temp = await imgsGal.filter((e, index) => index !== Item);
        setImgsGal(temp);
    }

    useEffect(() => {
        onChange({
            target: {
                name: 'galeria',
                value: imgsGal
            }
        })
    }, [imgsGal]);

    return (
        <>
            <div className="hardLine"></div>
            <big>Galeria de fotos</big>
            <UploadGal>
                <TitleSection>ENVIAR FOTOS</TitleSection>
                <input id="filesGal" type="file" multiple className="d-none" accept="image/*" onChange={handleChangeFiles} />
                <button type="button" onClick={() => document.getElementById('filesGal').click()} className="btn btn-info btn-large">
                    <i className="icon-docs fa-lg"></i> Selecionar Fotos
                </button>
                <Row>
                    {imgsGal.map((img, index) => (
                        <Col key={index} md="2">
                            <span className="inputPreview">
                                <i className="icon-close" onClick={() => handleRemoveItem(index)}></i>
                                <img src={img}></img>
                            </span>
                        </Col>
                    ))}
                </Row>
                {typeof fotos !== 'undefined' && (
                    <>
                        <div className="hardLine"></div>
                        <TitleSection>FOTOS ENVIADAS</TitleSection>
                        <Row>
                            {fotos.map(({ url_foto }, index) => (
                                <Col key={index} md="3">
                                    <span className="inputPreview">
                                        <img src={`${URL_BASE}/${url_foto}`}></img>
                                        {/* <div className="hardLine"></div> */}
                                        {/* <div><button type="button" className="btn btn-danger btn-block"><i className="fa fa-trash"></i> Remove</button></div> */}
                                    </span>
                                </Col>
                            ))}
                        </Row>
                    </>
                )}
            </UploadGal>
        </>
    )
}

export default GalleryBox;