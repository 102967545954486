import React from 'react';
import useTable from '@hooks/useTable';

import { Row, Col } from 'react-bootstrap';
import { Table, Input } from 'antd';

export default function PageTrajectory() {
    const { dataSource, handleSearched } = useTable('/trajectory/');
    
    const columns = [
        {
            title: 'Rota',
            render: data => <span>{data.title_route}</span>
        }, {
            title: 'Ação',
            align: 'right',
            width: '30%',
            render: data => (
                <a href={`/rotas/update/${data.id_route}`} className="btn btn-primary"><i className="icon-note" /> Editar</a>
            )
        }
    ];

    return (
        <div className="bg-padrao">
            <Row>
                <Col md="6">
                <h1>Nossos Rotas</h1>
                </Col>
                <Col md="6" className="text-right">
                    <a href="/rotas/create" className="btn btn-success">
                        <i className="icon-plus"></i>
                        Cadastrar Nova
                    </a>
                </Col>
            </Row>
            <div className="hardLine"></div>
            <Row className="justify-content-end">
                <Col md="3">
                    <div className="input-icon">
                        <Input onChange={handleSearched} placeholder="Procurar" />
                        <i className="icon-magnifier" />
                    </div>
                </Col>
            </Row>
            <Table columns={columns} dataSource={dataSource.dataTable} loading={dataSource.loading} pagination={{pageSize: 20}} />
        </div>
    )
}