import React from 'react';
import { formatDateToView } from '@utils/functions';

import { Row, Col } from 'react-bootstrap';
import { TabTitle, TabList } from './style';
import AdressBox from './AdressBox';

export default function DetailsDados({ dataInfos }) {
    return (
        <div id="dados" className="tab-pane fade active show">
            <TabTitle>
                Dados do cliente
            </TabTitle>
            <TabList>
                <li>
                    <b>Representante:</b>
                    {dataInfos.representante}
                </li>
                <li>
                    <b>Vendedor:</b>
                    {dataInfos.vendedor}
                </li>
                <li>
                    <b>Nome{dataInfos.pessoa === 'J' && ' de contato'}:</b>
                    {dataInfos.nome}
                </li>
                <li>
                    <b>E-mail:</b>
                    {dataInfos.email}
                </li>
                <li>
                    <b>Contato:</b>
                    <span className="maskTelefone">{dataInfos.contato}</span>
                </li>

                {dataInfos.pessoa === 'J' ? (
                    <>
                        <li>
                            <b>Razão Social:</b>
                            {dataInfos.razao_social}
                        </li>
                        <li>
                            <b>Nome Fantasia:</b>
                            {dataInfos.nome_fantasia}
                        </li>
                        <li>
                            <b>CNPJ:</b>
                            <span className="maskCnpj">{dataInfos.cnpj}</span>
                        </li>
                        {dataInfos.ins_estadual != null && (
                            <li>
                                <b>Insc. Estadual:</b>
                                {dataInfos.ins_estadual}
                            </li>
                        )}
                    </>
                ) : (
                        <>
                            <li>
                                <b>Dt. Nascimento:</b>
                                {formatDateToView(dataInfos.dt_nascimento)}
                            </li>
                            <li>
                                <b>CPF:</b>
                                <span className="maskCPF">{dataInfos.cpf}</span>
                            </li>
                            <li>
                                <b>RG:</b>
                                {dataInfos.rg}
                            </li>
                        </>
                    )}
            </TabList>

            <Row>
                <Col>
                    <TabTitle>Endereço de Entrega</TabTitle>
                    <AdressBox adress={dataInfos.ad_entrega} />
                </Col>
                <Col>
                    <TabTitle>Endereço de Faturamento</TabTitle>
                    <AdressBox adress={dataInfos.ad_cobranca} />
                </Col>
            </Row>
        </div>
    )
}
