import React from 'react';

import { ShippingBox, ShippingIcon, ShippingContent, ShippingInfo } from '../style';

const AdressBox = ({adress}) => {
    return (
        <ShippingBox>
            <ShippingIcon>
                <span>
                    <svg id="ui-icon--location" viewBox="0 0 48 48">
                        <path d="M24.53 35.03l-.531.53-.53-.53c-5.125-5.127-7.716-9.795-7.716-14.034a8.246 8.246 0 1 1 16.492 0c0 4.24-2.591 8.907-7.716 14.034zm-7.277-14.034c0 3.627 2.231 7.781 6.746 12.435 4.515-4.654 6.746-8.808 6.746-12.435a6.746 6.746 0 0 0-13.492 0zM24 24.76a3.755 3.755 0 1 1 0-7.51 3.755 3.755 0 0 1 0 7.51zm0-1.5a2.255 2.255 0 1 0 0-4.51 2.255 2.255 0 0 0 0 4.51z" fill="#3483FA" fill-rule="nonzero"></path>
                    </svg>
                </span>
            </ShippingIcon>
            <ShippingContent>
                <ShippingInfo>
                    {adress ? (
                        <>
                        <h4>{adress.logradouro}, {adress.numero}</h4>
                        <span>{adress.cidade}, {adress.estado} - CEP {adress.cep}</span>
                        <span>{adress.bairro}, {adress.complemento != null && `(${adress.complemento})`}</span>
                        </>
                    ) : (
                        <h4>Retirada em balcão</h4>
                    )}
                </ShippingInfo>
            </ShippingContent>
        </ShippingBox>
    )
}

export default AdressBox;
