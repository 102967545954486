import React, { useState, useEffect } from 'react';
import { isEmpty } from '@utils/functions';

import { ModalBG, ModalContent } from './style';
import AdressCard from './Card';
// import FormAdress from './Form';

const AdressModal = ({ onOpen, onClose, enderecos, selected, onSelect }) => {
    const [dataSource, setDataSource] = useState({});

    const handleOutsideClick = e => {
        if (e.target.id === "modalAdress") onClose();
    };

    const handleSelect = data => {
        onSelect({
            target: {
                name: dataSource.name,
                value: data
            }
        });

        setDataSource({});
        onClose();
    }

    useEffect(() => {
        if (onOpen !== '') {
            setDataSource({ name: onOpen })
        } else {
            setDataSource({})
        }
    }, [onOpen]);

    return !isEmpty(dataSource) && (
        <ModalBG id="modalAdress" onClick={handleOutsideClick}>
            <ModalContent>
                {enderecos.map(item => <AdressCard data={item} selected={item === selected} onSelect={handleSelect} />)}
                <div className="hardLine"></div>
                <button type="button" onClick={onClose} className="btn">Fechar</button>
            </ModalContent>
        </ModalBG>
    )
}

export default AdressModal;