import React, { useEffect, useRef, useState } from 'react';
import { doMoney } from '@utils/functions';
import Api from '@utils/api';

import { Table } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { FiltersOptions, Filters, Option } from '@assets/filter'
import Alert from '@components/Alert';

export default function PageReportNetwork() {
    const [dataSource, setDataSource] = useState([]);
    const [network, setNetwork] = useState([]);
    const [dates, setDates] = useState([]);
    const networkRef = useRef(null);
    const dateRef = useRef(null);

    useEffect(() => {
        Api('/request/requestdates/').then(response => setDates(response))
    }, []);

    const handleSetNetwork = _ => {
        const id_network = networkRef.current.value
        const date = dateRef.current.value

        Api('/network/report/', { id_network, date })
        .then(response => setDataSource(response))
    }

    useEffect(_ => {
        Api('/request/requestdates/').then(response => setDates(response))
        Api('/network/').then(response => setNetwork(response))
    }, [])

    const columns = [
        {
            title: 'Cliente',
            render: ({ id_customer, cliente, contato }) => (
                <>
                    <span className="d-block">#{(`00000${id_customer}`).substr(-5)} - {cliente}</span>
                    <span className="d-block maskTelefone">{contato}</span>
                </>
            )
        }, {
            title: 'Valor compras',
            render: ({ total_compras }) => doMoney(total_compras)
        }, {
            title: 'Total VP',
            render: ({ total_compras, publicidade }) => !publicidade
                ? 'Sem VP cadastrado'
                : doMoney(total_compras * (publicidade / 100))
        }
    ]

    return (
        <div className="bg-padrao">
            <Alert onOpen={alert} />
            <Row>
                <Col md="6">
                    <h1>Relatório - pedidos por rede</h1>
                </Col>
            </Row>
            <div className="hardLine"></div>
            <Filters>
                <FiltersOptions>
                    <Option>
                        <label className="m-none">Rede</label>
                        <select ref={networkRef} className="form-control input-sm">
                            <option value=""></option>
                            {network.map(item => <option value={item.id_network}>{item.nome}</option>)}
                        </select>
                    </Option>
                    <Option>
                        <label className="m-none">Período</label>
                        <select ref={dateRef} className="form-control input-sm">
                            <option value=""></option>
                            {dates.map(date => <option value={date.mes}>{date.mes}</option>)}
                        </select>
                    </Option>
                    <Option>
                        <button onClick={handleSetNetwork} className="btn btn-info">Buscar</button>
                    </Option>
                </FiltersOptions>
            </Filters>
            <Table
                className="table-requests"
                columns={columns}
                dataSource={dataSource}
                size="small"
                pagination={{
                    defaultPageSize: 10000,
                    hideOnSinglePage: true
                }}
            />
            <Row className="justify-content-end">
                <Col xl="2" md="3">
                    <span style={{ padding: '10px', textAlign: 'right' }} className="d-block span-p bg-clean-default">
                        <b className="d-block">Total Comprado</b>
                        {doMoney(dataSource.reduce((total, prox) => total + prox.total_compras, 0))}
                    </span>
                </Col>
                <Col xl="2" md="3">
                    <span style={{ padding: '10px', textAlign: 'right' }} className="d-block span-p bg-clean-default">
                        <b className="d-block">Total VP</b>
                        {doMoney(dataSource.reduce((total, prox) => total + (prox.total_compras * (prox.publicidade / 100)), 0))}
                    </span>
                </Col>
            </Row>
        </div>
    )
}
