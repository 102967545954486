import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useForm from '@hooks/useForm';
import Api from '@utils/api';
import { AlertReload, validaCpfCnpj, createAlert } from '@utils/functions';

import { Row, Col } from 'react-bootstrap';
import Alert from '@components/Alert';
import SlimCrop from '@components/SlimCrop';
import AdressesBox from '@components/AdressesBox';

import LoadBotton from '@components/LoadBotton';
import FilesBox from '@components/FilesBox';

export default function PageCustomerForm() {
    const { idCustomer } = useParams();
    const { values, handleChange, handleSubmit, setValuesDefault } = useForm();
    const [loading, setLoading] = useState(false);
    const [discont, setDiscont] = useState(false);
    const [representatives, setRepresentatives] = useState([]);
    const [network, setNetwork] = useState([]);
    const [alert, setAlert] = useState({});

    const handleSendForm = data => {
        if (loading) {
            return
        }

        const stringCpfCnpj = data.pessoa === 'F'
            ? data.cpf
            : data.cnpj;

        try {
            validaCpfCnpj(stringCpfCnpj)

            AlertReload(
                '/customer/',
                '/clientes/update/',
                data,
                setLoading,
                setAlert
            )
        } catch (err) {
            createAlert(err.message)
        }
    }

    useEffect(() => {
        if (typeof idCustomer !== 'undefined') {
            Api(`/customer/${idCustomer}`)
                .then(res => setValuesDefault(res));
        }

        Api('/network/')
            .then(response => setNetwork(response));

        Api('/representative/')
            .then(response => {
                const auxData = response.filter(user => user.tipo === 2);
                setRepresentatives(auxData);
            });
    }, []); // eslint-disable-line

    return (
        <>
            <Alert onOpen={alert} />
            <div className="bg-padrao">
                <Row>
                    <Col md="6">
                        <h1>
                            {typeof idCustomer === 'undefined' ? 'Cadastrar novo' : 'Editar'} Cliente
                        </h1>
                    </Col>
                    <Col md="6" className="text-right">
                        <a href="/clientes/" className="btn btn-success">
                            <i className="icon-arrow-left"></i>
                            Voltar para lista
                        </a>
                    </Col>
                </Row>
                <div className="hardLine"></div>
                <form id="customerForm" onSubmit={handleSubmit(handleSendForm)}>
                    <Row>
                        <Col xl="3" md="5">
                            <div className="form-group">
                                <label>Atendimento presencial</label>
                                <select onChange={handleChange} name="representative" value={values.representative || values.id_representative} className="form-control input-sm" required>
                                    <option value=""></option>
                                    {representatives.map(user => (
                                        <option value={user.id_representative}>{user.nome}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Tipo Pessoa</label>
                                <select onChange={handleChange} name="pessoa" value={values.pessoa} className="form-control input-sm" required disabled={typeof values.id_customer !== 'undefined'}>
                                    <option value=""></option>
                                    <option value="F">Física</option>
                                    <option value="J">Jurídica</option>
                                </select>
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Tipo Cliente</label>
                                <select onChange={handleChange} name="cliente" value={values.cliente} className="form-control input-sm" required disabled={typeof values.id_customer !== 'undefined'}>
                                    <option value=""></option>
                                    <option value="F">Consumidor Final</option>
                                    <option value="R">Revenda</option>
                                </select>
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Faturamento</label>
                                <select onChange={handleChange} name="faturamento" value={values.faturamento} className="form-control input-sm" required>
                                    <option value=""></option>
                                    <option value="0">Simples Nacional</option>
                                    <option value="1">Lucro Presumido</option>
                                    <option value="2">Lucro Real</option>
                                    <option value="3">MEI</option>
                                    <option value="4">Outro</option>
                                </select>
                            </div>
                        </Col>
                        <Col xl="3" md="5">
                            <div className="form-group">
                                <label>Rede</label>
                                <select onChange={handleChange} name="id_network" value={values.id_network} className="form-control input-sm">
                                    <option value=""></option>
                                    {network.map(item => (
                                        <option value={item.id_network}>{item.nome}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                    </Row>
                    {typeof values.pessoa !== 'undefined' && typeof values.cliente !== 'undefined' && (
                        <>
                            <div className="hardLine"></div>
                            <Row>
                                <Col md="9">
                                    <Row>
                                        {values.pessoa === "F"
                                            ? (
                                                <>
                                                    <Col md="5">
                                                        <div className="form-group">
                                                            <label>Nome completo</label>
                                                            <input onChange={handleChange} type="text" value={values.nome} className="form-control input-sm" required />
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="form-group">
                                                            <label>Dt. Nascimento</label>
                                                            <input onChange={handleChange} type="date" name="dt_nascimento" value={values.dt_nascimento} className="form-control input-sm" required />
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="form-group">
                                                            <label>CPF</label>
                                                            <input onChange={handleChange} type="text" name="cpf" value={values.cpf} className="form-control input-sm maskCPF" required />
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="form-group">
                                                            <label>RG</label>
                                                            <input maxLength="10" onChange={handleChange} type="text" name="rg" value={values.rg} className="form-control input-sm" required />
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="form-group">
                                                            <label>Orgão Exp.</label>
                                                            <input maxLength="10" onChange={handleChange} type="text" name="orgRg" value={values.orgRg} className="form-control input-sm" required />
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="form-group">
                                                            <label>Sexo</label>
                                                            <select onChange={handleChange} name="genero" value={values.genero} className="form-control input-sm" required>
                                                                <option value=""></option>
                                                                <option value="M">Masculino</option>
                                                                <option value="F">Feminino</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </>
                                            ) : (
                                                <>
                                                    <Col md="3">
                                                        <div className="form-group">
                                                            <label>CNPJ</label>
                                                            <input onChange={handleChange} type="text" name="cnpj" value={values.cnpj} className="form-control input-sm maskCnpj" required />
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="form-group">
                                                            <label>Insc. Estadual</label>
                                                            <input onChange={handleChange} type="text" name="ins_estadual" value={values.ins_estadual} className="form-control input-sm" required={values.cliente === "F"} />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="form-group">
                                                            <label>Razão Social</label>
                                                            <input onChange={handleChange} type="text" name="razao_social" value={values.razao_social} className="form-control input-sm" required />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="form-group">
                                                            <label>Nome Fantasia</label>
                                                            <input onChange={handleChange} type="text" name="nome_fantasia" value={values.nome_fantasia} className="form-control input-sm" required />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="form-group">
                                                            <label>Responsável</label>
                                                            <input onChange={handleChange} type="text" name="nome" value={values.nome} className="form-control input-sm" required />
                                                        </div>
                                                    </Col>
                                                </>
                                            )}
                                    </Row>
                                    <Row>
                                        <Col md="3">
                                            <div className="form-group">
                                                <label>Telefone</label>
                                                <input onChange={handleChange} type="text" name="contato" value={values.contato} className="form-control input-sm maskTelefone" required />
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="form-group">
                                                <label>E-mail</label>
                                                <input onChange={handleChange} type="email" name="email" value={values.email} className="form-control input-sm" />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="form-group">
                                                <label>Desconto Padrão (%)</label>
                                                <div className="input-icon input-icon-right">
                                                    <input type="text" readOnly name="desc_padrao" value={values.desc_padrao > 0 ? values.desc_padrao : '0'} className="form-control input-sm" required />
                                                    <i title="Informar desconto" onClick={_ => setDiscont(true)} className="icon-pencil" />
                                                </div>
                                            </div>
                                        </Col>
                                        {(discont || (typeof values.desc_pendente !== 'undefined' && values.desc_pendente !== null)) && (
                                            <>
                                                <Col md="2">
                                                    <div className="form-group">
                                                        <label>{!discont && values.desc_pendente !== null ? 'Desconto Pendente' : 'Novo Desconto'} (%)</label>
                                                        <div className="input-icon input-icon-right">
                                                            <input type="text" onChange={handleChange} name="desc_pendente" value={values.desc_pendente} disabled={!discont && values.desc_pendente !== null} className="form-control input-sm Mask2Casas" required />
                                                            <i className="icon-check" />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </Col>
                                <Col md="3">
                                    <label>FOTO</label>
                                    <SlimCrop onChange={handleChange} value={values.foto} name="foto" />
                                </Col>
                            </Row>
                            <AdressesBox Adresses={values.enderecos} setValues={handleChange} Alert={setAlert} />
                            <FilesBox onChange={handleChange} arquivos={values.arquivos} />
                            <div className="hardLine"></div>
                            <LoadBotton Loader={loading} />
                        </>
                    )}
                </form>
            </div>
        </>
    )
}
