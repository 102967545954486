import React, { useCallback } from 'react';

import { Table, Tag } from 'antd';

import { doMoney } from '@utils/functions';
import { status_pedido, formPayment, typePayment } from '@components/Constants';
import { CartInfo, CartContent, CartValues } from './style';

export default function DetailsPedido({ dataInfos, setState }) {
    const pesagem = useCallback(() => {
        return dataInfos.pedido.reduce((total, proxProd) => total + parseFloat(proxProd.peso), 0);
        // return values.toString().replace('.', ',');
    }, [dataInfos.pedido]);

    const columns = [
        {
            title: 'Código',
            dataIndex: 'codigo',
            width: '100px'
        }, {
            title: 'Produto',
            dataIndex: 'titulo',
        }, {
            title: (dataInfos.valor_desconto > 0 ? 'Valor Unit. / Final' : 'Valor Unit.'),
            width: '23%',
            render: data => data.valor_unit != data.valor_final ? (
                <span className="d-block">
                    {doMoney(data.valor_unit)} / <b> {doMoney(data.valor_final)} ({((data.valor_final / data.valor_unit - 1) * -100).toFixed(0)}%) </b>
                </span>
            ) : doMoney(data.valor_final)
        }, {
            title: 'Quant.',
            align: 'center',
            width: '8%',
            render: data => (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {data.alert && dataInfos.status_pedido < 7 && <span className="circle"></span>}
                    {data.quantidade}
                </div>
            )
        }, {
            title: 'Preço NFe',
            width: '15%',
            render: data => doMoney(data.valor_nfe, 4)
        }, {
            title: 'Total',
            align: 'right',
            width: '15%',
            render: data => doMoney(data.valor_final * data.quantidade)
        }
    ];

    return (
        <div id="pedido" className="tab-pane fade">
            <span className="d-inline-block span-p">
                <b className="d-block">Status Pedido:</b>
                <Tag color={status_pedido[dataInfos.status_pedido].color}>
                    {status_pedido[dataInfos.status_pedido].title}
                </Tag>
            </span>
            <span className="d-inline-block span-p bg-clean-default">
                <b className="d-block">
                    Pagamento{dataInfos.antecipado > 0 && ` (antecipado ${dataInfos.antecipado == 1 ? '50%' : '100%'})`}:
                </b>
                {`${typePayment[dataInfos.meio_pagamento]} - ${formPayment[dataInfos.forma_pagamento]}`}
            </span>

            {dataInfos.valor_entrada > 0 && (
                <span className="d-inline-block span-p bg-clean-default">
                    <b className="d-block">Valor Entrada:</b>
                    {doMoney(dataInfos.valor_entrada)}
                </span>
            )}

            {dataInfos.status_pedido < 2 && (
                <div className="actions float-right">
                    <span onClick={() => setState(dataInfos.id_request, 8)} className="btn btn-default" title="Cancelar">
                        <i className="icon-ban" />
                        Cancelar orçamento
                    </span>
                    <span onClick={() => setState(dataInfos.id_request, 2)} className="btn btn-orange" title="Aprovar">
                        <i className="fa fa-check-square-o"></i>
                        Aprovar orçamento
                    </span>
                </div>
            )}

            {dataInfos.status_pedido == 2 && (
                <div className="actions float-right">
                    <span onClick={() => setState(dataInfos.id_request, 9)} className="btn btn-default" title="Cancelar">
                        <i className="icon-ban" />
                        Cancelar orçamento
                    </span>
                    <span onClick={() => setState(dataInfos.id_request, 3)} className="btn btn-orange" title="Aprovar">
                        <i className="fa fa-check-square-o"></i>
                        Aprovar pedido
                    </span>
                </div>
            )}

            <Table
                className="table-requests"
                columns={columns}
                dataSource={dataInfos.pedido}
                size="small"
                scroll={{ y: 240 }}
                pagination={{ pageSize: 100 }}
            />
            <CartContent>
                <CartValues>
                    <CartInfo>
                        <h4>Detalhes</h4>
                        <div>
                            <span>Peso total</span>
                            <span>{pesagem().toFixed(2)} Kg</span>
                        </div>
                        <div>
                            <span>Cubagem total</span>
                            <span>{dataInfos.cubagem.toFixed(2)} M³</span>
                        </div>
                    </CartInfo>
                </CartValues>
                <CartValues>
                    <CartInfo>
                        <h4>Resumo</h4>
                        <div>
                            <span>Subtotal</span>
                            <span>{doMoney(dataInfos.valor_subtotal)}</span>
                        </div>
                        <div>
                            <span>Desconto</span>
                            <span>
                                {doMoney(dataInfos.valor_desconto)}
                                ({dataInfos.desconto * 100}%)
                            </span>
                        </div>
                    </CartInfo>
                    <CartInfo price>
                        <div>
                            <h4>Total</h4>
                            <span>{doMoney(dataInfos.valor_total)}</span>
                        </div>
                    </CartInfo>
                </CartValues>
            </CartContent>
        </div>
    )
}
