import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useForm from '@hooks/useForm';
import Api from '@utils/api';
import { AlertReload } from '@utils/functions';

import { Row, Col } from 'react-bootstrap';
import { ComponentContent } from './style';
import LoadBotton from '@components/LoadBotton';
import Alert from '@components/Alert';

export default function PageTrajectoryForm() {
  const { idRoute } = useParams();
  const { values, handleChange, handleSubmit, setValuesDefault } = useForm();
  const [rotas, setRotas] = useState({ estados: [], cidades: [] });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const addComponent = () => {
    const auxValue = { ...values };
    if (typeof auxValue.rotas === 'undefined') auxValue.rotas = [];
    auxValue.rotas.push({});

    setValuesDefault(auxValue);
  }

  const handleDeletComponent = Item => {
    const auxValue = { ...values };
    auxValue.rotas = auxValue.rotas.filter((e, index) => index !== Item)

    setValuesDefault(auxValue)
  }

  const handleFormSubmit = data => {
    if (loading) {
      return
    }

    AlertReload(
      '/trajectory/',
      '/rotas/update/',
      data,
      setLoading,
      setAlert
    )
  }

  useEffect(() => {
    Api('/trajectory/locales/')
      .then(res => setRotas(res));

    if (typeof idRoute !== 'undefined')
      Api(`/trajectory/${idRoute}`)
        .then(response => setValuesDefault(response));
  }, []); // eslint-disable-line

  return (
    <>
      <Alert onOpen={alert} />
      <div className="bg-padrao">
        <Row>
          <Col md="6">
            <h1>
              {typeof idRoute !== 'undefined' ? 'Editar' : 'Cadastrar nova'} Rota
            </h1>
          </Col>
          <Col md="6" className="text-right">
            <a href="/rotas/" className="btn btn-success">
              <i className="icon-arrow-left"></i>
                Voltar para lista
              </a>
          </Col>
        </Row>
        <div className="hardLine"></div>
        <form onSubmit={handleSubmit(handleFormSubmit, setAlert)}>
          <Row>
            <Col md="12">
              <div className="form-group">
                <label>Título</label>
                <input
                  onChange={handleChange}
                  type="text"
                  name="title_route"
                  value={values.title_route}
                  className="form-control input-sm" />
              </div>
            </Col>
          </Row>
          <div className="hardLine"></div>
          <big>Localidades</big>
          <Row>
            {values.rotas && values.rotas.map((item, index) => (
              <Col md="4">
                <ComponentContent>
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Estado</label>
                        <select
                          onChange={handleChange}
                          name={`rotas.${index}.id_state`}
                          value={item.id_state}
                          className="form-control input-sm"
                          required >
                          <option value=""> </option>
                          {rotas.estados.map(uf => (
                            <option value={uf.id_state}>
                              {uf.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Cidade</label>
                        <select
                          disabled={typeof values.rotas[index].id_state === 'undefined'}
                          onChange={handleChange}
                          name={`rotas.${index}.id_city`}
                          value={item.id_city}
                          className="form-control input-sm"
                          required >
                          <option value=""> </option>
                          {rotas.cidades.map(city =>
                            city.id_state == values.rotas[index].id_state && (
                              <option value={city.id_city}>
                                {city.nome}
                              </option>
                            ))}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <div className="hardLine"></div>
                  <div className="footer">
                    <button type="button" onClick={() => handleDeletComponent(index)} className="btn btn-danger">
                      <i className="fa fa-trash"></i> Remove
                    </button>
                  </div>
                </ComponentContent>
              </Col>
            ))}
          </Row>
          <button type="button" onClick={addComponent} className="btn btn-info btn-large btn-block">
            <i className="icon-plus"></i> Nova Cidade
          </button>
          <div className="hardLine"></div>
          <LoadBotton Loader={loading} />
        </form>
      </div>
    </>
  )
}
