import React, { useEffect, useState } from 'react';
import { AlertReload, doMoney } from '@utils/functions';
import { useParams } from 'react-router-dom';
import useForm from '@hooks/useForm';
import Api from '@utils/api';

import { Row, Col } from 'react-bootstrap';
import AdressModal from './AdressModal';
import InputAuto from '@components/InputAuto';
import Alert from '@components/Alert';
import AdressBox from './AdressBox';

export default function PageRequestForm() {
    const { idRequest } = useParams();
    const { values, handleChange, handleSubmit, setValuesDefault } = useForm();
    const [alterClient, setAlterClient] = useState(true);
    const [alterAdress, setAlterAdress] = useState('');
    const [novoProduto, setNovoProduto] = useState({});
    const [adresses, setAdresses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({});

    const handleSendForm = data => {
        if (loading) {
            return
        }

        AlertReload(
            `/request/${idRequest || ''}`,
            '/pedidos/',
            data,
            setLoading,
            setAlert
        )
    }

    // Call Cliente autocomplete
    const callCliente = data => {
        const requestData = {
            ...values,
            id_customer: data.id_customer,
            tipoCliente: data.cliente,
            desconto: data.desc_padrao,
            NETWORK: data.NETWORK,
            endereco_cobranca: data.enderecos[0],
        }

        if (!values.produtos) {
            requestData.produtos = []
            requestData.valor_subtotal = 0
            requestData.valor_entrada = 0
            requestData.valor_total = 0
            requestData.cubagem = 0
            requestData.endereco_entrega = null
            requestData.retirada = false
            requestData.antecipado = null
        }

        if (data.enderecos.length == 0) {
            setAlert({
                html: 'Cliente não possui endereços cadastrados.',
                type: 'warning'
            })
        }

        setAdresses([...data.enderecos])
        setValuesDefault({ ...requestData })
    }

    // Call Products autocomplete
    const callProduct = (data, clear) => {
        setNovoProduto({ ...data, eventClear: clear })
        document.getElementById('productQuant').focus()
        document.getElementById('productDiscount').value = values.desconto
    }

    const handleSetProduct = _ => {
        const discount = document.getElementById('productDiscount').value
        const quantity = document.getElementById('productQuant').value

        if (!novoProduto.codigo) {
            setNovoProduto({ quantidade: '' })
            return
        }

        if (quantity <= 0) {
            setAlert({
                html: 'Por favor, informe a quantidade do item selecionado.',
                type: 'warning'
            })

            document.getElementById('productQuant').focus()
            return
        }

        const hasProduct = values.produtos.filter(prod => prod.codigo === novoProduto.codigo)

        if (hasProduct.length > 0) {
            setAlert({
                html: `
                Este item já foi informado.<br /><br />
                Caso queira adicionar mais unidades, excluia o item e adicione novamente.
                `,
                type: 'warning'
            })

            return
        }

        // Clear autocomplete
        novoProduto.eventClear('')
        delete novoProduto.eventClear

        const parsePercent = 1 - discount / 100
        novoProduto.quantidade = parseInt(quantity)
        novoProduto.valor_final = novoProduto.valor * parsePercent

        const produtos = [...values.produtos, novoProduto]
        setValuesDefault({ ...values, produtos })
        setNovoProduto({})

        document.getElementById('productDiscount').value = null
        document.getElementById('productQuant').value = null
    }

    const removeProduct = index => {
        const produtos = [...values.produtos]
        produtos.splice(index, 1);

        setValuesDefault({ ...values, produtos });
    }

    // Values calculations
    const handleCalc = _ => {
        let cubagem = 0
        let valor_subtotal = 0
        let valor_total = 0

        values.produtos.map(item => {
            cubagem += (item.volume * item.quantidade)
            valor_subtotal += (item.valor * item.quantidade)
            valor_total += (item.valor_final * item.quantidade)
        })

        setValuesDefault({
            ...values,
            cubagem: cubagem.toFixed(2),
            valor_subtotal,
            valor_total
        })
    }

    const removeAddress = _ => {
        const auxValues = { ...values }
        auxValues.retirada = !auxValues.retirada

        if (auxValues.retirada) {
            delete (auxValues.endereco_entrega)
        }

        setValuesDefault({ ...auxValues })
    }

    useEffect(_ => {
        if (values.produtos) handleCalc()
    }, [values.produtos, values.desconto, values.forma_pagamento]); // eslint-disable-line

    useEffect(_ => {
        if (!idRequest) {
            return
        }

        Api(`/request/update/${idRequest}`).then(response => {
            const requestData = {
                id_customer: response.id_customer,
                nome_cliente: response.nome_cliente,
                tipoCliente: response.cliente,
                produtos: [...response.pedido],
                meio_pagamento: response.meio_pagamento,
                forma_pagamento: response.forma_pagamento,
                desconto: response.desconto,
                valor_entrada: doMoney(response.valor_entrada) || 0,
                endereco_cobranca: response.charge_adress,
                endereco_entrega: response.delivery_address,
                retirada: response.delivery_address === null,
                antecipado: response.antecipado
            }

            setAdresses(response.adresses)
            setValuesDefault(requestData)
            setAlterClient(false)

            document.addEventListener('keydown', function (event) {
                setAlterClient(true);
            });
        })
    }, []); // eslint-disable-line

    return (
        <>
            <Alert onOpen={alert} />
            <div className="bg-padrao">
                <Row>
                    <Col md="6">
                        <h1>Novo Orçamento</h1>
                    </Col>
                    <Col md="6" className="text-right">
                        <a href="/pedidos/" className="btn btn-success">
                            <i className="icon-arrow-left"></i>
                        Voltar para lista
                    </a>
                    </Col>
                </Row>
                <div className="hardLine"></div>
                <form id="formRequestModal" onSubmit={handleSubmit(handleSendForm, setAlert)}>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Cliente</label>
                                {!alterClient ? (
                                    <input type="text" value={values.nome_cliente} className="form-control input-sm" disabled />
                                ) : (
                                        <InputAuto
                                            callback={callCliente}
                                            urlSearch={`/customer/search/`}
                                            placeholder="Cliente / Razão social / Nome Fantasia"
                                        />
                                    )}
                            </div>
                            {typeof values.endereco_cobranca !== 'undefined' && (
                                <>
                                    {!!values.NETWORK && (
                                        <div style={{ padding: '20px', margin: '10px 0' }} className="alert-default">
                                            Cliente da rede: {values.NETWORK}
                                        </div>
                                    )}
                                    <AdressModal
                                        onOpen={alterAdress}
                                        onClose={_ => setAlterAdress('')}
                                        enderecos={adresses}
                                        selected={values[alterAdress]}
                                        onSelect={handleChange}
                                    />
                                    <Row>
                                        <Col xl="4" md="6">
                                            <label>Endereço de Faturamento</label>
                                            <AdressBox
                                                values={values.endereco_cobranca}
                                                alterAdress={_ => setAlterAdress('endereco_cobranca')}
                                            />
                                        </Col>
                                        <Col xl="4" md="6">
                                            <span>
                                                Endereço de Entrega
                                                <label htmlFor="retirada" style={{ padding: '0 25px' }}>
                                                    <input id="retirada" defaultChecked={values.retirada} type="checkbox" style={{
                                                        marginRight: '5px',
                                                        position: 'relative',
                                                        verticalAlign: 'bottom',
                                                        top: '-4.5px'
                                                    }} onChange={removeAddress} /> <label style={{ margin: 0 }}>Retirada</label>
                                                </label>
                                            </span>
                                            <AdressBox disabled={values.retirada}
                                                values={values.endereco_entrega}
                                                alterAdress={_ => setAlterAdress('endereco_entrega')}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                    {typeof values.endereco_cobranca !== 'undefined' && (
                        <>
                            <div className="hardLine">
                                <span>Informe o produto para adicionar ao pedido</span>
                            </div>
                            <Row>
                                <Col xl="6" md="5">
                                    <div className="form-group">
                                        <label>Produto</label>
                                        <InputAuto
                                            callback={callProduct}
                                            urlSearch={`/products/search/${values.endereco_cobranca.uf}/${values.tipoCliente}/`}
                                            placeholder="Código / Título"
                                        />
                                    </div>
                                </Col>
                                <Col xl="1" md="2">
                                    <div className="form-group">
                                        <label>Quan.</label>
                                        <input
                                            id="productQuant"
                                            type="number"
                                            className="form-control input-sm notSubmit"
                                            disabled={!novoProduto.codigo}
                                        />
                                    </div>
                                </Col>
                                <Col xl="1" md="2">
                                    <div className="form-group">
                                        <label>Desconto</label>
                                        <input
                                            id="productDiscount"
                                            type="text"
                                            className="form-control input-sm notSubmit Mask3Casas"
                                            disabled={!novoProduto.codigo}
                                        />
                                    </div>
                                </Col>
                                <Col md="2">
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                        onClick={handleSetProduct}
                                        disabled={!novoProduto.codigo}
                                        style={{ marginTop: '18px' }}>
                                        <i className="fa fa-plus"></i> Adicionar
                                    </button>
                                </Col>
                            </Row>
                            <table className="table" style={{ background: 'rgba(0, 0, 0, .1)' }}>
                                <thead>
                                    <tr>
                                        <th width="100px">Código</th>
                                        <th width="50px"></th>
                                        <th>Produto</th>
                                        <th width="150px">Valor Unit.</th>
                                        <th width="80px" style={{ textAlign: "center" }}>Quant.</th>
                                        <th width="150px" className="text-right">Valor Total</th>
                                        <th width="10px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {values.produtos.map((item, index) => (
                                        <tr>
                                            <td>{item.codigo}</td>
                                            <td>
                                                <i
                                                    className={item.estoque >= item.quantidade ? 'icon-check' : 'icon-close'}
                                                    style={{ color: item.estoque >= item.quantidade ? '#2ECC71' : '#E74C3C' }}
                                                    title={item.estoque >= item.quantidade
                                                        ? 'Item em estoque'
                                                        : `Item sem estoque no momento, disponível ${item.estoque} unídades`
                                                    }
                                                />
                                            </td>
                                            <td>{item.label}</td>
                                            <td>{doMoney(item.valor_final)}</td>
                                            <td>{item.quantidade}</td>
                                            <td className="text-right">
                                                {doMoney(item.valor_final * item.quantidade)}
                                            </td>
                                            <td className="text-right">
                                                <i onClick={_ => removeProduct(index)} className="icon-minus" />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="hardLine">
                                <span>DETALHES</span>
                            </div>
                            <Row>
                                <Col xl="1" md="2">
                                    <div className="form-group">
                                        <label>Cubagem</label>
                                        <input
                                            type="text"
                                            value={`${values.cubagem} m³`}
                                            className="form-control input-sm text-right"
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col xl="1" md="2">
                                    <div className="form-group">
                                        <label>Quan.</label>
                                        <input
                                            type="text"
                                            value={values.produtos.reduce((total, prox) => total + prox.quantidade, 0)}
                                            className="form-control input-sm text-right"
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col xl="2" md="3">
                                    <div className="form-group">
                                        <label>Subtotal</label>
                                        <input
                                            type="text"
                                            value={doMoney(values.valor_subtotal)}
                                            className="form-control input-sm text-right"
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col xl="2" md="3">
                                    <div className="form-group">
                                        <label>Valor Total</label>
                                        <input
                                            type="text"
                                            value={doMoney(values.valor_total)}
                                            className="form-control input-sm text-right"
                                            disabled
                                        />
                                    </div>
                                </Col>
                                {!!values.forma_pagamento && (
                                    <Col xl="2" md="3">
                                        <div className="form-group">
                                            <label>Valor Entrada</label>
                                            <input
                                                type="text"
                                                name="valor_entrada"
                                                onBlur={handleChange}
                                                onChange={handleChange}
                                                value={values.valor_entrada}
                                                className="form-control input-sm text-right maskMoney"
                                            />
                                        </div>
                                    </Col>
                                )}
                                <Col xl="5" md="12">
                                    <div className="hardLine">
                                        <span>Antecipado</span>
                                    </div>
                                    <Row>
                                        <Col>
                                            <label>
                                                <input type="radio" onChange={handleChange} name="antecipado" defaultChecked={values.antecipado == 0} value="0" />
                                                &nbsp;À prazo
                                            </label>
                                        </Col>
                                        {/* <Col>
                                            <label>
                                                <input type="radio" onChange={handleChange} name="antecipado" defaultChecked={values.antecipado == 1} value="1" />
                                                &nbsp;Antecipado 50%
                                            </label>
                                        </Col> */}
                                        <Col>
                                            <label>
                                                <input type="radio" onChange={handleChange} name="antecipado" defaultChecked={values.antecipado == 2} value="2" />
                                                &nbsp;Antecipado 100%
                                            </label>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="hardLine">
                                <span>Meio de pagamento</span>
                            </div>
                            <Row>
                                <Col md="12">
                                    <div className="radio-group">
                                        <label>
                                            <input type="radio" onChange={handleChange} name="meio_pagamento" defaultChecked={values.meio_pagamento == 0} value="0" />
                                            Em dinheiro
                                        </label>
                                        <label>
                                            <input type="radio" onChange={handleChange} name="meio_pagamento" defaultChecked={values.meio_pagamento == 1} value="1" />
                                            Em cartão
                                        </label>
                                        <label>
                                            <input type="radio" onChange={handleChange} name="meio_pagamento" defaultChecked={values.meio_pagamento == 2} value="2" />
                                            Em cheque
                                        </label>
                                        <label>
                                            <input type="radio" onChange={handleChange} name="meio_pagamento" defaultChecked={values.meio_pagamento == 3} value="3" />
                                            Em boleto
                                        </label>
                                        <label>
                                            <input type="radio" onChange={handleChange} name="meio_pagamento" defaultChecked={values.meio_pagamento == 4} value="4" />
                                            Depós./Transf.
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <div className="hardLine">
                                <span>Forma de pagamento</span>
                            </div>
                            <Row>
                                <Col md="12">
                                    <div className="radio-group">
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 0} value="0" />
                                            À Vista
                                        </label>

                                        {/* 7 dias */}
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 11} value="11" />
                                            7/14 dias
                                        </label>
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 5} value="5" />
                                            7/14/21 dias
                                        </label>
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 9} value="9" />
                                            7/14/21/28/35 dias
                                        </label>

                                        {/* 10 dias */}
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 6} value="6" />
                                            10 dias
                                        </label>

                                        {/* 14 dias */}
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 4} value="4" />
                                            28/42/56/70/84 dias
                                        </label>

                                        {/* 15 dias */}
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 12} value="12" />
                                            30/45/60 dias
                                        </label>
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 10} value="10" />
                                            30/45/60/75/90 dias
                                        </label>

                                        {/* 28 dias */}
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 1} value="1" />
                                            28 dias
                                        </label>
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 2} value="2" />
                                            28/56 dias
                                        </label>
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 3} value="3" />
                                            28/56/84 dias
                                        </label>

                                        {/* 30 dias */}
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 7} value="7" />
                                            30 dias
                                        </label>
                                        <label>
                                            <input type="radio" onChange={handleChange} name="forma_pagamento" defaultChecked={values.forma_pagamento == 8} value="8" />
                                            30/60/90 dias
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="hardLine">
                                        <span>Observação</span>
                                    </div>
                                    <div className="form-group">
                                        <input onChange={handleChange} type="text" name="observacao" value={values.observacao} className="form-control input-sm" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-end">
                                <Col xl="2" md="3">
                                    <a href="/pedidos/" disabled={loading} className="btn btn-block btn-default">Cancelar</a>
                                </Col>
                                <Col xl="2" md="3">
                                    <button type="submit" disabled={loading} className="btn btn-block btn-info">Concluir</button>
                                </Col>
                            </Row>
                        </>
                    )}
                </form>
            </div>
        </>
    )
}
