import React, { useState } from 'react';
import useTable from '@hooks/useTable';
import Api from '@utils/api';
import { downloadFile } from '@utils/functions';
import { status_carga } from '@components/Constants';

import { Row, Col } from 'react-bootstrap';
import { Table, Input, Tag } from 'antd';
import Romaneio from '@components/Romaneio';
import Alert from '@components/Alert';
import PageDetails from './Details';

export default function PageCargo() {
    const { dataSource, handleSearched, handleUpdate } = useTable('/cargo/')
    const [dataInfo, setDataInfo] = useState([])
    const [cargo, setCargo] = useState({})
    const [alert, setAlert] = useState({})

    const handleGetCargo = idCargo => {
        Api(`/cargo/${idCargo}`)
            .then(response => setCargo(response))
            .catch(error => setAlert({
                html: error.message,
                type: 'warning'
            }));
    }

    const handleGetDocuments = idCargo => {
        Api(`/cargo/documents/${idCargo}`)
            .then(response => downloadFile(response, 'pdf', `metalback-entrega-${idCargo}`))
    }

    const handlePayments = idCargo => {
        Api(`/cargo/gen-requests-payments/${idCargo}`)
            .then(res =>
                setAlert({
                    title: 'Faturamento realizado com sucesso!'
                }))
    }

    const handleGetReport = idCargo => {
        Api(`/cargo/cargo-products/${idCargo}`)
            .then(response => downloadFile(response, 'pdf', `relatorio-produtos-${idCargo}`))
    }

    const handleGetCities = idCargo => {
        Api(`/cargo/cargo-nfe/${idCargo}`)
            .then(response => {
                setAlert({
                    title: 'Lista de cidades e chaves NFE',
                    html: (
                        <>
                            <textarea className="form-control input-sm" rows="5">
                                {response.chaves}
                            </textarea>
                            <hr />
                            <textarea className="form-control input-sm" rows="5">
                                {response.cidades}
                            </textarea>
                            <div className="btn-mapLink">
                                <a href={response.mapURL} target="_blank" className="btn btn-info btn-icons" title="Link google Maps">
                                    <i className="icon-direction m-none" />
                                </a>
                                <a href={response.fullMapURL} target="_blank" className="btn btn-info btn-icons" title="Link google Maps (FULL)">
                                    <i className="icon-directions m-none" />
                                </a>
                            </div>
                        </>
                    )
                })
            })
    }

    const getRequestsNotCargo = () => {
        Api(`/cargo/requests-not-cargo/`)
            .then(response => {
                const links = [];

                for (const [UF, mapas] of Object.entries(response.mapas)) {
                    mapas.forEach((link, index) => {
                        const preName = mapas.length > 1 ? (index + 1) : '';

                        links.push({ name: `${UF} ${preName}`, link });
                    });
                }

                setAlert({
                    title: 'Lista de pedidos sem carregamento',
                    html: (
                        <>
                            <label>Pedido - Cidade</label>
                            <textarea className="form-control input-sm" rows="10">
                                {response.cidades}
                            </textarea>
                            <div style={{ padding: '10px 0 15px' }}>
                                {links.map(({ name, link }) => (
                                    <a href={link} target="_blank" className="btn btn-default" title="Link google Maps">
                                        <i className="icon-map" /> {name}
                                    </a>
                                ))}
                            </div>
                        </>
                    )
                })
            })
    }

    const handleSetStatus = (idCargo, status, alert = false) => {
        setAlert({})

        if (!alert) {
            setAlert({
                title: 'ATENÇÃO!!!',
                hasButton: false,
                type: 'warning',
                html: (
                    <>
                        <span>Confirmar alteração de carregamento?</span>
                        <div className="hardLine"></div>
                        <div className="text-right">
                            <span onClick={() => handleSetStatus(idCargo, status, true)} className="btn btn-orange" title="Confirmar ação">
                                <i className="fa fa-check-square-o"></i> Sim
                            </span>
                            <span onClick={() => setAlert({})} className="btn btn-primary" title="Cancelar ação">
                                <i className="icon-ban" /> Não
                            </span>
                        </div>
                    </>
                )
            })

            return
        }

        Api(`/cargo/set-status/${idCargo}/${status}`)
            .then(() => {
                const auxVar = dataSource.dataAll

                auxVar.map(item => item.id_cargo === idCargo && (item.status = status))
                handleUpdate(auxVar)
            })
            .catch(err => setAlert({
                title: 'Nos detectamos um problema',
                html: err.message,
                type: 'warning'
            }))
    }

    const handleStatistic = idCargo => {
        Api(`/cargo/statistic/${idCargo}`)
            .then(response => setDataInfo(response))
    }

    const columns = [
        {
            title: 'Geral',
            render: data => (
                <>
                    <span className="d-block"><b>#{data.id_cargo} ROTA:</b> {data.route}</span>
                    <span className="d-block">Motorista: {data.nome}</span>
                    <span className="d-block">Contato: <span className="maskTelefone">{data.celular}</span></span>

                    <div className="actions ">
                        <span onClick={() => handleGetReport(data.id_cargo)} className="btn btn-default btn-icons" title="Relatório de produtos">
                            <i className="icon-list m-none" />
                        </span>
                        <span onClick={() => handleGetCities(data.id_cargo)} className="btn btn-default btn-icons" title="Relatório de cidades">
                            <i className="icon-map m-none" />
                        </span>

                        {data.status < 2 && (
                            <span onClick={() => handlePayments(data.id_cargo)} className="btn btn-default btn-icons" title="Gerar faturamento">
                                <i className="icon-wallet m-none" />
                            </span>
                        )}

                        {data.status > 0 && (
                            <>
                                <span onClick={() => handleGetDocuments(data.id_cargo)} className="btn btn-default btn-icons" title="Documentos de entrega">
                                    <i className="icon-docs m-none" />
                                </span>
                                <span onClick={() => handleStatistic(data.id_cargo)} className="btn btn-default btn-icons" title="Estatísticas">
                                    <i className="icon-chart m-none" />
                                </span>
                            </>
                        )}
                    </div>

                    <div className="actions d-none">
                        <span className="btn btn-default btn-icons" title="Observações Carregamento">
                            <i className="icon-note m-none" />
                        </span>
                        <span className="btn btn-default btn-icons" title="Custos">
                            <i className="icon-wallet m-none" />
                        </span>
                    </div>
                </>
            )
        }, {
            title: 'Veículo',
            render: data => (
                <>
                    <span className="d-block"><b>{data.model}</b></span>
                    <span className="d-block">Cubagem: {data.cubagem} m³</span>
                    <span className="d-block">Espaço Livre: {(data.cubagem - data.cubagem_usada).toPrecision(2)} m³</span>
                    <span className="d-block">Peso carregamento: {!!data.peso_carregamento ? data.peso_carregamento : 0} Kg</span>
                    <div className="actions d-none">
                        <span className="btn btn-default btn-icons" title="Observações Veículo">
                            <i className="icon-note m-none" />
                        </span>
                    </div>
                </>
            )
        }, {
            title: 'Cronograma',
            render: data => (
                <>
                    <Tag color={status_carga[data.status].color}>
                        {status_carga[data.status].title}
                    </Tag>
                    <span className="d-block">Saida: <input disabled type="date" value={data.data_saida} /></span>
                    {data.data_finalizada !== null && (
                        <span className="d-block">
                            Finalizada: <input disabled type="date" value={data.data_finalizada} />
                        </span>
                    )}
                    <div className="actions">
                        <span onClick={() => handleGetCargo(data.id_cargo)} className="btn btn-default btn-icons" title="Romanéio">
                            <i className="icon-directions m-none" />
                        </span>

                        {data.status == 0 && (
                            <>
                                <span onClick={() => handleSetStatus(data.id_cargo, 1)} className="btn btn-default btn-icons" title="Fechar Carregamento">
                                    <i className="fa fa-truck m-none"></i>
                                </span>
                                <span onClick={() => handleSetStatus(data.id_cargo, 4)} className="btn btn-default btn-icons" title="Cancelar Carregamento">
                                    <i className="fa fa-times m-none"></i>
                                </span>
                            </>
                        )}

                        {data.status == 1 && (
                            <>
                                <span onClick={() => handleSetStatus(data.id_cargo, 2)} className="btn btn-default btn-icons" title="Liberar Veículo">
                                    <i className="icon-cursor m-none" />
                                </span>
                                <span onClick={() => handleSetStatus(data.id_cargo, 4)} className="btn btn-default btn-icons" title="Cancelar Carregamento">
                                    <i className="fa fa-times m-none"></i>
                                </span>
                            </>
                        )}

                        {data.status == 2 && (
                            <span onClick={() => handleSetStatus(data.id_cargo, 3)} className="btn btn-default btn-icons" title="Entrega Finalizada">
                                <i className="fa fa-check-square-o m-none"></i>
                            </span>
                        )}
                    </div>
                </>
            )
        }
    ];

    return (
        <>
            <Alert onOpen={alert} />
            <PageDetails
                dataInfo={dataInfo}
                onClose={() => setDataInfo({})}
            />
            <Romaneio dataCargo={cargo} setAlert={setAlert} />
            <div className="bg-padrao">
                <Row>
                    <Col md="6">
                        <h1>Carregamento / Cargas</h1>
                    </Col>
                    <Col md="6" className="text-right">
                        <span onClick={getRequestsNotCargo} className="btn btn-default btn-icons" title="Pedidos sem carregamento">
                            <i className="icon-directions" /> Pedidos sem carregamento
                        </span>
                        <a href="/cargas/create" className="btn btn-success">
                            <i className="icon-plus"></i>
                            Cadastrar Nova
                        </a>
                    </Col>
                </Row>
                <div className="hardLine"></div>
                <Row className="justify-content-end">
                    <Col md="3">
                        <div className="input-icon">
                            <Input onChange={handleSearched} placeholder="Procurar" />
                            <i className="icon-magnifier" />
                        </div>
                    </Col>
                </Row>
                <Table
                    className="v-align-all"
                    columns={columns}
                    dataSource={dataSource.dataTable}
                    loading={dataSource.loading}
                    pagination={{ pageSize: 20 }}
                />
            </div>
        </>
    )
}
