import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useForm from '@hooks/useForm';
import Api from '@utils/api';
import { AlertReload } from '@utils/functions';

import { Row, Col } from 'react-bootstrap';
import LoadBotton from '@components/LoadBotton';
import GalleryBox from '@components/GalleryBox';
import Alert from '@components/Alert';

export default function PageProductForm() {
    const { idProduct } = useParams()
    const { values, handleChange, handleSubmit, setValuesDefault } = useForm()
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({})

    const handleSendForm = data => {
        if (loading) {
            return
        }

        AlertReload(
            '/products/',
            '/produtos/update/',
            data,
            setLoading,
            setAlert
        )
    }

    useEffect(() => {
        if (typeof idProduct === 'undefined') {
            return
        }

        Api(`/products/${idProduct}`)
            .then(res => setValuesDefault(res))
    }, []);

    return (
        <>
            <Alert onOpen={alert} />
            <div className="bg-padrao">
                <Row>
                    <Col md="6">
                        <h1>
                            {typeof idProduct === 'undefined' ? 'Cadastrar novo' : 'Editar'} Produto
                    </h1>
                    </Col>
                    <Col md="6" className="text-right">
                        <a href="/produtos/" className="btn btn-success">
                            <i className="icon-arrow-left"></i>
                        Voltar para lista
                    </a>
                    </Col>
                </Row>
                <div className="hardLine"></div>
                <form onSubmit={handleSubmit(handleSendForm, setAlert)}>
                    <Row>
                        <Col md="2">
                            <div className="form-group">
                                <label>Código</label>
                                <input onChange={handleChange} type="text" name="codigo" value={values.codigo} className="form-control input-sm" required disabled={typeof idProduct !== 'undefined'} />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Categoria</label>
                                <select onChange={handleChange} name="categoria" value={values.categoria} className="form-control input-sm" required >
                                    <option value=""></option>
                                    <option value="21">Vitrô</option>
                                    <option value="22">Veneziana</option>
                                    <option value="23">Porta de Giro</option>
                                    <option value="24">Porta de Correr 2F</option>
                                    <option value="25">Porta de Correr 4F</option>
                                    <option value="26">Maxim Ar</option>
                                    <option value="27">Alçapão</option>
                                </select>
                            </div>
                        </Col>
                        <Col md="8">
                            <div className="form-group">
                                <label>Título</label>
                                <input onChange={handleChange} type="text" name="titulo" value={values.titulo} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="form-group">
                                <label>Descrição</label>
                                <textarea onChange={handleChange} name="descricao" value={values.descricao} className="form-control input-sm" required></textarea>
                            </div>
                        </Col>
                    </Row>
                    <div className="hardLine">
                        <span>Variação de valores</span>
                    </div>
                    <Row>
                        <Col md="2">
                            <div className="form-group">
                                <label>Valor Varejo</label>
                                <input onBlur={handleChange} onChange={handleChange} type="text" name="valor_varejo" value={values.valor_varejo} className="form-control input-sm maskMoney" required />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Valor S. Nacional</label>
                                <input onBlur={handleChange} onChange={handleChange} type="text" name="valor_simples" value={values.valor_simples} className="form-control input-sm maskMoney" required />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Valor B2C PR</label>
                                <input onBlur={handleChange} onChange={handleChange} type="text" name="valor_b2c_pr" value={values.valor_b2c_pr} className="form-control input-sm maskMoney" required />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Valor B2C f-PR</label>
                                <input onBlur={handleChange} onChange={handleChange} type="text" name="valor_b2c_fpr" value={values.valor_b2c_fpr} className="form-control input-sm maskMoney" required />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Valor B2B PR</label>
                                <input onBlur={handleChange} onChange={handleChange} type="text" name="valor_b2b_pr" value={values.valor_b2b_pr} className="form-control input-sm maskMoney" required />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Valor B2B f-PR</label>
                                <input onBlur={handleChange} onChange={handleChange} type="text" name="valor_b2b_fpr" value={values.valor_b2b_fpr} className="form-control input-sm maskMoney" required />
                            </div>
                        </Col>
                    </Row>
                    <div className="hardLine">
                        <span>Envio / Estoque / Disponibilidade</span>
                    </div>
                    <Row>
                        <Col md="2">
                            <div className="form-group">
                                <label>Peso (kg)</label>
                                <input placeholder="0.00" maxLength="6" onChange={handleChange} type="text" name="peso" value={values.peso} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Comprimento (m)</label>
                                <input placeholder="0.00" maxLength="6" onChange={handleChange} type="text" name="comprimento" value={values.comprimento} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Altura (m)</label>
                                <input placeholder="0.00" maxLength="6" onChange={handleChange} type="text" name="altura" value={values.altura} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Largura (m)</label>
                                <input placeholder="0.00" maxLength="6" onChange={handleChange} type="text" name="largura" value={values.largura} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Estoque</label>
                                <input maxLength="6" onChange={handleChange} type="number" name="estoque" value={values.estoque} className="form-control input-sm" required disabled={typeof idProduct !== 'undefined'} />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Status</label>
                                <select onChange={handleChange} name="status" value={values.status} className="form-control input-sm" required >
                                    <option value=""></option>
                                    <option value="0">Desativado</option>
                                    <option value="1">Disponível</option>
                                    <option value="2">Só Ecommerce</option>
                                    <option value="3">Só Interno</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <GalleryBox onChange={handleChange} fotos={values.fotos} />
                    <div className="hardLine"></div>
                    <LoadBotton Loader={loading} />
                </form>
            </div>
        </>
    )
}
