import styled from 'styled-components';

export const Modal = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    z-index: 100;
`;

export const ModalContent = styled.div`
    position: relative;
    display: block;
    width: 90%;
    max-width: 1100px;
    background: #FFF;
`;

export const ModalHeader = styled.div`
    position: relative;
    padding: 15px 30px;
    border-bottom: 1px solid #E5E5E5;
    background-color: #eee;
`;

export const ModalClose = styled.button`
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    border: none;

    &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #666;
        transform: rotate(45deg) translate(9px, -10px);
    }

    &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #666;
        transform: rotate(-45deg) translate(9px, 9px);
    }
`;

export const ModalBody = styled.div`
    padding: 30px;
    
    table tbody tr,
    table tr td,
    table tr th,
    table {
        margin: 0 !important;
        font-size: 13px !important;
        background: none;
    }

    .ant-table-body {
        overflow-y: auto !important;
    }

    .ant-pagination {
        display: none !important;
    }
`;

export const ModalTitle = styled.h3`
    font-size: 16px !important;
    color: #666 !important;
`;

export const TabLink = styled.a`
    display: block;
    padding: 10px 15px;
    margin-right: 2px;
    color: #555;
    text-decoration: none !important;
    
    &.active {
        cursor: default;
        background: #FFF;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
    }

    &.not(.active):hover {
        color: #555;
        background: #EFEFEF;
    }
`;

export const TabTitle = styled.big`
    display: block;
    margin: 0 0 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    padding-left: 8px;
    border-left: 5px solid #999;
`;

export const BoxStatus = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #FFF;
    border: 1px solid #DDD;
    border-bottom-color: #999;
    box-shadow: 0 0 4px rgba(0, 0, 0, .1);
    user-select: none;
`;

export const StatusTitle = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px;
    color: #444;
    font-size: 32px;
    line-height: 35px;
    font-weight: 700;
`;

export const StatusTitleIcon = styled.i`
    opacity: .4;
`;

export const StatusContent = styled.div`
    font-size: 13px;
    color: #666;
`;
