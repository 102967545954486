import React, { useState, useEffect } from 'react';
import { isEmpty } from '@utils/functions';
import { AlertModal, AlertContent, AlertTitle, AlertSeparator, AlertClose } from './style';

const Alert = ({ onOpen, children }) => {
    const [data, setData] = useState({});

    const handleOutsideClick = e => {
        if (e.target.id === "alertModal") onClose();
    };

    const onClose = _ => setData({});

    useEffect(_ => {
        setData({});

        if (isEmpty(onOpen)) return;

        const options = {
            title: 'ATENÇÃO!!!',
            html: children,
            type: 'success',
            hasButton: true,
            btnTitle: 'Fechar',
        };

        setData({ ...options, ...onOpen })
    }, [onOpen]);

    return !isEmpty(data) && (
        <AlertModal id="alertModal" onClick={handleOutsideClick}>
            <AlertContent>
                <AlertTitle>
                    <i className={data.type === 'success' ? 'icon-check' : 'icon-exclamation'}></i>
                    {data.title}
                </AlertTitle>
                
                {typeof data.html === 'string'
                ? (<span dangerouslySetInnerHTML={{ __html: data.html }} />)
                : data.html}

                {data.hasButton === true && (
                    <>
                    <AlertSeparator />
                    <AlertClose onClick={onClose}>{data.btnTitle}</AlertClose>
                    </>
                )}
                
            </AlertContent>
        </AlertModal>
    )
};

export default Alert;