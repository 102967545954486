import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useForm from '@hooks/useForm';
import Api from '@utils/api';
import { AlertReload } from '@utils/functions';

import { Row, Col } from 'react-bootstrap';
import Alert from '@components/Alert';
import SlimCrop from '@components/SlimCrop';
import LoadBotton from '@components/LoadBotton';

export default function PageRepresentativeForm() {
    const { idRepresentative } = useParams()
    const { values, handleChange, handleSubmit, setValuesDefault } = useForm()
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({})

    const handleSendForm = data => {
        if (loading) {
            return
        }

        AlertReload(
            '/representative/',
            '/representantes/update/',
            data,
            setLoading,
            setAlert
        )
    }

    useEffect(() => {
        if (typeof idRepresentative !== 'undefined')
            Api(`/representative/${idRepresentative}`).then(resp => setValuesDefault(resp));
    }, []); // eslint-disable-line

    return (
        <>
            <Alert onOpen={alert} />
            <div className="bg-padrao">
                <Row>
                    <Col md="6">
                        <h1>
                            {typeof idRepresentative === 'undefined' ? 'Cadastrar novo' : 'Editar'} Representante
                        </h1>
                    </Col>
                    <Col md="6" className="text-right">
                        <a href="/representantes/list" className="btn btn-success">
                            <i className="icon-arrow-left"></i>
                            Voltar para lista
                        </a>
                    </Col>
                </Row>
                <div className="hardLine"></div>
                <form onSubmit={handleSubmit(handleSendForm, setAlert)}>
                    <Row>
                        <Col md="2">
                            <div className="form-group">
                                <label>Dt. Contrato</label>
                                <input onChange={handleChange} type="date" name="contrato" value={values.contrato} className="form-control input-sm" required disabled={typeof values.id_representative !== 'undefined'} />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Senha</label>
                                <input onChange={handleChange} type="password" name="senha" value={values.senha} className="form-control input-sm" required={typeof idRepresentative === 'undefined'} />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Status</label>
                                <select onChange={handleChange} value={values.status} name="status" className="form-control input-sm" required>
                                    <option value=""></option>
                                    <option value="1">Ativo</option>
                                    <option value="0">Inativo</option>
                                    <option value="2">Em Analise</option>
                                </select>
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Atuação</label>
                                <select onChange={handleChange} value={values.tipo} name="tipo" className="form-control input-sm" required>
                                    <option value=""></option>
                                    <option value="1">Interno</option>
                                    <option value="2">Externo</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                    {values.tipo && (
                        <>
                            <div className="hardLine"></div>
                            <Row>
                                <Col md="9">
                                    <Row>
                                        {values.tipo === 2 ? (
                                            <>
                                                <Col md="4">
                                                    <div className="form-group">
                                                        <label>Nome para contato</label>
                                                        <input onChange={handleChange} type="text" name="nome" value={values.nome} className="form-control input-sm" required />
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="form-group">
                                                        <label>R. Social</label>
                                                        <input onChange={handleChange} type="text" name="razao_social" value={values.razao_social} className="form-control input-sm" required />
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="form-group">
                                                        <label>N. Fantasia</label>
                                                        <input onChange={handleChange} type="text" name="nome_fantasia" value={values.nome_fantasia} className="form-control input-sm" required />
                                                    </div>
                                                </Col>
                                                <Col md="3">
                                                    <div className="form-group">
                                                        <label>CNPJ</label>
                                                        <input onChange={handleChange} type="text" name="cnpj" value={values.cnpj} className="form-control input-sm maskCnpj" required />
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="form-group">
                                                        <label>I. Estadual</label>
                                                        <input onChange={handleChange} type="text" name="ins_estadual" value={values.ins_estadual} className="form-control input-sm" />
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="form-group">
                                                        <label>Site</label>
                                                        <input onChange={handleChange} type="text" name="site" value={values.site} className="form-control input-sm" />
                                                    </div>
                                                </Col>
                                            </>
                                        ) : (
                                                <>
                                                    <Col md="6">
                                                        <div className="form-group">
                                                            <label>Nome completo</label>
                                                            <input onChange={handleChange} type="text" name="nome" value={values.nome} className="form-control input-sm" required />
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="form-group">
                                                            <label>Dt. Nascimento</label>
                                                            <input onChange={handleChange} type="date" name="dt_nascimento" value={values.dt_nascimento} className="form-control input-sm" required />
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="form-group">
                                                            <label>CPF</label>
                                                            <input onChange={handleChange} type="text" name="cpf" value={values.cpf} className="form-control input-sm maskCPF" required />
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="form-group">
                                                            <label>RG</label>
                                                            <input maxLength="10" onChange={handleChange} type="text" name="rg" value={values.rg} className="form-control input-sm" required />
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="form-group">
                                                            <label>Orgão Exp.</label>
                                                            <input maxLength="10" onChange={handleChange} type="text" name="orgRg" value={values.orgRg} className="form-control input-sm" required />
                                                        </div>
                                                    </Col>
                                                </>
                                            )}
                                        <Col md="4">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input onChange={handleChange} type="email" name="email" value={values.email} className="form-control input-sm" required />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div className="form-group">
                                                <label>Contato</label>
                                                <input onChange={handleChange} type="text" name="contato" value={values.contato} className="form-control input-sm maskTelefone" required />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="3">
                                    <label>FOTO</label>
                                    <SlimCrop onChange={handleChange} value={values.foto} name="foto" />
                                </Col>
                            </Row>
                            <div className="hardLine"></div>
                            <Row>
                                <Col md="2">
                                    <div className="form-group">
                                        <label>CEP</label>
                                        <input onChange={handleChange} type="text" name="cep" value={values.cep} className="form-control input-sm maskCep" required />
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="form-group">
                                        <label>Endereço</label>
                                        <input onChange={handleChange} type="text" name="endereco" value={values.endereco} className="form-control input-sm" required />
                                    </div>
                                </Col>
                                <Col md="1">
                                    <div className="form-group">
                                        <label>Número</label>
                                        <input maxLength="10" onChange={handleChange} type="text" name="numero" value={values.numero} className="form-control input-sm" required />
                                    </div>
                                </Col>
                                <Col md="2">
                                    <div className="form-group">
                                        <label>Bairro</label>
                                        <input onChange={handleChange} type="text" name="bairro" value={values.bairro} className="form-control input-sm" required />
                                    </div>
                                </Col>
                                <Col md="1">
                                    <div className="form-group">
                                        <label>Estado</label>
                                        <input maxLength="2" onChange={handleChange} type="text" name="uf" value={values.uf} className="form-control input-sm" required />
                                    </div>
                                </Col>
                                <Col md="2">
                                    <div className="form-group">
                                        <label>Cidade</label>
                                        <input onChange={handleChange} type="text" name="cidade" value={values.cidade} className="form-control input-sm" required />
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="form-group">
                                        <label>Complemento</label>
                                        <input onChange={handleChange} type="text" name="complemento" value={values.complemento} className="form-control input-sm" />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                    <div className="hardLine"></div>
                    <LoadBotton Loader={loading} />
                </form>
            </div>
        </>
    )
}
