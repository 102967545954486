import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useForm from '@hooks/useForm';
import Api from '@utils/api';
import { AlertReload } from '@utils/functions';
import { SetoresColl, CargosColl } from '@components/Constants';

import { Row, Col } from 'react-bootstrap';
import Alert from '@components/Alert';
import LoadBotton from '@components/LoadBotton';
import SlimCrop from '@components/SlimCrop';
import FilesBox from '@components/FilesBox';

const Escolaridade = [
    "Ensino Fundamental Completo",
    "Ensino Fundamental Incompleto",
    "Ensino Médio Completo",
    "Ensino Médio Incompleto",
    "Ensino Superior Completo",
    "Ensino Superior Incompleto",
    "Pós-Graduação Completo",
    "Pós-Graduação Incompleto",
    "Mestrado Completo",
    "Mestrado Incompleto",
    "Phd/Doutorado Completo",
    "Phd/Doutorado Incompleto",
];

const EsCivil = [
    "Solteiro",
    "Casado",
    "Divorciado",
    "Viúvo",
    "União Estável",
];

export default function PageCollaboratorForm() {
    const { idCollaborator } = useParams()
    const { values, handleChange, handleSubmit, setValuesDefault } = useForm()
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({})

    const handleSendForm = data => {
        if (loading) {
            return
        }

        AlertReload(
            '/collaborator/',
            '/colaboradores/update/',
            data,
            setLoading,
            setAlert
        )
    }

    useEffect(() => {
        if (typeof idCollaborator === 'undefined')
            return;

        Api(`/collaborator/${idCollaborator}`)
            .then(response => setValuesDefault(response));
    }, []);

    return (
        <>
            <Alert onOpen={alert} />
            <div className="bg-padrao">
                <Row>
                    <Col md="6">
                        <h1>
                            {typeof idCollaborator === 'undefined' ? 'Cadastrar novo' : 'Editar'} Colaborador
                        </h1>
                    </Col>
                    <Col md="6" className="text-right">
                        <a href="/colaboradores/" className="btn btn-success">
                            <i className="icon-arrow-left"></i>
                            Voltar para lista
                        </a>
                    </Col>
                </Row>
                <div className="hardLine"></div>
                <form onSubmit={handleSubmit(handleSendForm, setAlert)}>
                    <Row>
                        <Col md="9">
                            <Row>
                                <Col md="3">
                                    <div className="form-group">
                                        <label>CPF</label>
                                        <input onChange={handleChange} type="text" name="cpf" value={values.cpf} className="form-control input-sm maskCPF" required />
                                    </div>
                                </Col>
                                <Col md="2">
                                    <div className="form-group">
                                        <label>RG</label>
                                        <input maxLength="10" onChange={handleChange} type="text" name="rg" value={values.rg} className="form-control input-sm" required />
                                    </div>
                                </Col>
                                <Col md="2">
                                    <div className="form-group">
                                        <label>Orgão Exp.</label>
                                        <input maxLength="10" onChange={handleChange} type="text" name="orgRg" value={values.orgRg} className="form-control input-sm" required />
                                    </div>
                                </Col>
                                <Col md="2">
                                    <div className="form-group">
                                        <label>Gênero</label>
                                        <select onChange={handleChange} name="genero" className="form-control input-sm" required>
                                            <option value=""></option>
                                            <option selected={values.genero === "M"} value="M">Masculino</option>
                                            <option selected={values.genero === "F"} value="F">Feminino</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <label>Nome completo</label>
                                        <input onChange={handleChange} type="text" name="nome" value={values.nome} className="form-control input-sm" required />
                                    </div>
                                </Col>
                                <Col md="3">
                                    <div className="form-group">
                                        <label>Dt. Nascimento</label>
                                        <input maxLength="10" onChange={handleChange} type="date" name="dt_nascimento" value={values.dt_nascimento} className="form-control input-sm" required />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="3">
                                    <div className="form-group">
                                        <label>Celular</label>
                                        <input onChange={handleChange} type="text" name="celular" value={values.celular} className="form-control input-sm maskTelefone" required />
                                    </div>
                                </Col>
                                <Col md="3">
                                    <div className="form-group">
                                        <label>Dt. Admissão</label>
                                        <input onChange={handleChange} type="date" name="admissao" value={values.admissao} className="form-control input-sm" required />
                                    </div>
                                </Col>
                                <Col md="3">
                                    <div className="form-group">
                                        <label>Dt. Demissão</label>
                                        <input onChange={handleChange} type="date" name="demissao" value={values.demissao} className="form-control input-sm" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <div className="form-group">
                                        <label>E-mail</label>
                                        <input onChange={handleChange} type="email" name="email" value={values.email} className="form-control input-sm" />
                                    </div>
                                </Col>
                                <Col md="2">
                                    <div className="form-group">
                                        <label>Senha</label>
                                        <input onChange={handleChange} type="password" name="senha" value={values.senha} className="form-control input-sm" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="3">
                            <label>FOTO</label>
                            <SlimCrop onChange={handleChange} value={values.foto} name="foto" />
                        </Col>
                    </Row>
                    <div className="hardLine"></div>
                    <Row>
                        <Col md="4">
                            <div className="form-group">
                                <label>Escolaridade</label>
                                <select onChange={handleChange} name="escolaridade" className="form-control input-sm" required>
                                    <option value=""></option>
                                    {Escolaridade.map(data => (
                                        <option selected={values.escolaridade === data} value={data}>{data}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Estado Civil</label>
                                <select onChange={handleChange} name="estadoCivil" className="form-control input-sm" required>
                                    <option value=""></option>
                                    {EsCivil.map(data => (
                                        <option selected={values.estadoCivil === data} value={data}>{data}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="form-group">
                                <label>Setor</label>
                                <select onChange={handleChange} name="setor" className="form-control input-sm" required>
                                    <option value=""></option>
                                    {SetoresColl.map(data => (
                                        <option selected={values.setor === data.Value} value={data.Value}>{data.Title}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="form-group">
                                <label>Cargo</label>
                                <select disabled={!values.setor} onChange={handleChange} name="cargo" className="form-control input-sm" required>
                                    <option value=""></option>
                                    {!!values.setor && CargosColl[values.setor].map(data => (
                                        <option selected={values.cargo === data} value={data}>{data}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <div className="hardLine"></div>
                    <Row>
                        <Col md="3">
                            <div className="form-group">
                                <label>CEP</label>
                                <input onChange={handleChange} type="text" name="cep" value={values.cep} className="form-control input-sm maskCep" required />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group">
                                <label>Endereço</label>
                                <input onChange={handleChange} type="text" name="endereco" value={values.endereco} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="form-group">
                                <label>Número</label>
                                <input maxLength="10" onChange={handleChange} type="text" name="numero" value={values.numero} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="form-group">
                                <label>Bairro</label>
                                <input onChange={handleChange} type="text" name="bairro" value={values.bairro} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="form-group">
                                <label>Estado</label>
                                <input maxLength="2" onChange={handleChange} type="text" name="uf" value={values.uf} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="form-group">
                                <label>Cidade</label>
                                <input onChange={handleChange} type="text" name="cidade" value={values.cidade} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="form-group">
                                <label>Complemento</label>
                                <input onChange={handleChange} type="text" name="complemento" value={values.complemento} className="form-control input-sm" />
                            </div>
                        </Col>
                    </Row>
                    <FilesBox onChange={handleChange} arquivos={values.arquivos} />
                    <div className="hardLine"></div>
                    <LoadBotton Loader={loading} />
                </form>
            </div>
        </>
    )
}
