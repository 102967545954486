import styled from 'styled-components'
import { Link } from 'react-router-dom'
import './styleCSS.css'

export const MenuWrap = styled.div`
    position: fixed;
    float: left;
    width: 250px;
    height: 100vh;
    background: #E5E5E5;
    padding-top: 10px;
    z-index: 10;
    transition: 0.2s;

    @media (max-width: 1025px) {
        left: -250px;

        ${props => props.onOpen && 'left: 0;'}
    }
`

export const ButtonToggle = styled.button`
    position: absolute;
    right: -45px;
    top: 20px;
    background: #212121;
    color: #FFF;

    @media (min-width: 1025px) {
        display: none;
    }
`

export const Menu = styled.ul`
    clear: left;
    margin: 12px 0;
    padding: 0;
    list-style: none;
`

export const SubMenu = styled.div`
    position: relative;
    display: none;
    background-color: #D5D5D5;
`

export const MenuItem = styled.li`
    margin: 0;
    padding: 0;
    cursor: pointer;
    user-select: none;
`

export const MenuLink = styled(Link)`
    position: relative;
    display: block;
    color: #333;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    transition: all .1s ease-in-out;

    ${SubMenu} & {
        padding: 7px 12px;
    }

    &::before {
        content: " ";
        position: absolute;
        display: none;
        top: 50%;
        right: 0;
        border: solid 8px transparent;
        border-right-color: #EBEFF2;
        margin-top: -8px;
        pointer-events: none;
    }
`

export const MenuIcon = styled.i`
    float: left;
    width: 40px;
    line-height: 20px;
    text-align: center;
    padding: 8px 0;
`

export const MenuTitle = styled.div`
    padding: 8px 0;
`

export const ButtonLogout = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: 50px;
    bottom: 0px;
    left: 0px;
    color: #FFF;
    background: #212121;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    font-weight: bold;
`
