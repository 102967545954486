import React, { useState } from 'react';
import Api from '@utils/api';

import Autocomplete from 'react-autocomplete';
import { RenderMenu } from './style';

const InputAuto = ({ callback, urlSearch, placeholder, id = null }) => {
    const [value, setValue] = useState('');
    const [data, setData] = useState([]);

    const handleSearch = event => {
        const newValue = event.target.value;
        setValue(newValue);

        if (newValue.length <= 3) {
            setData([]);
            return;
        }
        
        Api(urlSearch + newValue)
            .then(res => setData(res));
    }

    return <Autocomplete
        inputProps={{ class: 'form-control input-sm', placeholder, id }}
        wrapperStyle={{ position: 'relative' }}
        getItemValue={item => item.label}
        items={data}
        value={value}
        onChange={handleSearch}
        onSelect={(select, item) => {
            setData([])
            setValue(select)

            callback(item, setValue)
        }}
        renderMenu={children =>
            <RenderMenu>
                {children}
            </RenderMenu>
        }
        renderItem={(item, isHighlighted) =>
            <div style={isHighlighted ? { background: 'lightgray' } : null}>
                {item.label}
            </div>
        }
    />
};

export default InputAuto;