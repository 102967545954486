import { isAuthenticated, getToken, userLogout, URL_BASE } from "./auth";

const Api = (Url, data = null) => {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json; charset=UTF-8');
	// headers.append('Accept', 'application/json');

	const requestOptions = {
		method: 'GET',
		headers
	};

	if (isAuthenticated()) {
		const token = getToken();
		requestOptions.headers.append('Authorization', `Bearer ${token}`);
	}

	if (data !== null) {
		requestOptions.method = 'POST';
		requestOptions.body = JSON.stringify(data);
	}

	return fetch(URL_BASE + Url, requestOptions).then(response => {
		switch (response.status) {
			case 401:
				userLogout();
				break;

			case 403:
				window.location.href = '/403/'
				break;

			default:
				return response.text();
		}
	}).then(response => {
		// RETORNO EM BRANCO
		if (
			response === '' ||
			response === null ||
			response === 'null')
			return {}

		// RETORNOS JSON
		response = JSON.parse(response);

		if (response.status === 'ERROR')
			throw new Error(response.message);

		return response;
	});
}

export default Api;