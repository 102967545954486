import React, { useState, useEffect } from 'react';
import { downloadFile } from '@utils/functions';
import Api from '@utils/api';

import { Row, Col } from 'react-bootstrap';
import { UploadGal, TitleSection, FileIcon } from './style';

const FilesBox = ({onChange, arquivos}) => {
    const [filesData, setFilesData] = useState([]);

    const handleChangeFiles = event => {
        let Files = event.target.files;

        for (var File of Files) {
            const split = File.name.split('.');
            const fileType = split.pop();
            const fileName = split.join('.');

            var picReader = new FileReader();
            picReader.onloadend = e => setFilesData(filesData => [
                ...filesData,
                {
                    fileName,
                    fileType,
                    fileData: e.target.result
                }]);
            picReader.readAsDataURL(File);
        }
    }

    const returnIcon = fileType => {
        switch (fileType) {
            case "jpg":
                return "fa fa-file-image-o";
                
            case "png":
                return "fa fa-file-image-o";

            case "docx":
                return "fa fa-file-word-o";

            case "pdf":
                return "fa fa-file-pdf-o";

            default:
                return "fa-file";
        }
    }

    const handleRemoveItem = async Item => {
        let tempData = [...filesData]
        tempData.splice(Item, 1)
        setFilesData(tempData)
    }

    const getFile = document => {
        Api('/get-file/', { fileUrl: document.file_path })
        .then(response => downloadFile(response.file, document.file_type, document.file_name))
    }

    useEffect(() => {
        onChange({
            target: {
                name: 'newArquivos',
                value: filesData
            }
        })
    }, [filesData]);

    return (
        <>
            <div className="hardLine"></div>
            <big>Arquivos</big>
            <UploadGal>
                <TitleSection>ENVIAR ARQUIVOS</TitleSection>
                <input id="filesBox" type="file" multiple className="d-none" accept="image/*, .pdf" onChange={handleChangeFiles} />
                <button type="button" onClick={() => document.getElementById('filesBox').click()} className="btn btn-info btn-large">
                    <i className="icon-docs fa-lg"></i> Selecionar Arquivos
                </button>
                <Row>
                    {filesData.map((doc, index) => (
                        <Col key={index} md="1">
                            <FileIcon className="inputPreview">
                                <i className={returnIcon(doc.fileType)} />
                                <i className="icon-close" onClick={() => handleRemoveItem(index)}></i>
                                {doc.fileName}
                            </FileIcon>
                        </Col>
                    ))}
                </Row>
                {typeof arquivos !== 'undefined' && (
                    <>
                        <div className="hardLine"></div>
                        <TitleSection>ARQUIVOS ENVIADOS</TitleSection>
                        <Row>
                            {arquivos.map((doc, index) => (
                                <Col key={index} md="1">
                                    <FileIcon onClick={_ => getFile(doc)} className="inputPreview">
                                        <i className={returnIcon(doc.file_type)} />
                                        {doc.file_name}
                                    </FileIcon>
                                </Col>
                            ))}
                        </Row>
                    </>
                )}
            </UploadGal>
        </>
    )
}

export default FilesBox;;