import styled from 'styled-components';

export const CheckNiveis = styled.label`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;

    input {
        display: none;
    }

    i {
        margin-right: 8px;
    }
`;