import React from 'react';

import { CardAddress, CardRadio, RadioOuterCircle, RadioInnerCircle, CardContent, CardInfo, CardAction } from './style';

const AdressCard = ({ data, selected, onSelect, setUpdate }) => {
    return (
        <CardAddress for={`id_${data.id_adress}`}>
            <CardRadio>
                <input
                    type="radio"
                    name="adress-option"
                    checked={selected}
                    id={`id_${data.id_adress}`}
                    onChange={() => onSelect(data)}
                />
                <RadioOuterCircle />
                <RadioInnerCircle />
            </CardRadio>
            <CardContent>
                <CardInfo onClick={() => onSelect(data)}>
                    <h4>{data.logradouro}, {data.numero}</h4>
                    <span>{data.cidade}, {data.uf} - CEP {data.cep}</span>
                    <span>{data.complemento}</span>
                </CardInfo>
                {/* <CardAction>
                    <button type="button" onClick={() => setUpdate(data)}>Editar</button>
                </CardAction> */}
            </CardContent>
        </CardAddress>
    )
}

export default AdressCard;