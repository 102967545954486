import React, { useEffect, useState } from 'react';
import Api from '@utils/api';
import useTable from '@hooks/useTable';
import { URL_BASE } from "@utils/auth";
import { createAlert, downloadFile } from '@utils/functions';
import customFilters from '@utils/customFilters';

import { Filters, FiltersHeader, FiltersOptions, Option } from '@assets/filter';
import { Row, Col } from 'react-bootstrap';
import { Table } from 'antd';
import noImage from '@assets/not-found.jpg';

import Annotations from './Annotations';
import PageDetails from './Details';

export default function PageCustomer() {
    const [dataInfo, setDataInfo] = useState([]);
    const [annotations, setAnnotations] = useState([]);
    const [representatives, setRepresentatives] = useState([]);
    const { dataSource, handleFiltered } = useTable('/customer/');
    const filters = new customFilters(dataSource.dataAll, handleFiltered);

    useEffect(() => {
        Api('/representative/')
            .then(response => setRepresentatives(response))
    }, []);

    const downPdf = customer => {
        Api(`/customer/price-table/${customer}`)
            .then(response => downloadFile(response.file, 'pdf', `metalback-tabela-de-precos-${customer}`));
    }

    const handlePayments = customer => {
        Api(`/customer/payment-details/${customer}`)
            .then(response => {
                if (response.length == 0) {
                    createAlert("Sem registros financeiros.")
                    return
                }

                setDataInfo(response)
            })
    }

    const getAnnotation = customerID => {
        Api(`/customer/annotation/${customerID}`)
            .then(response => setAnnotations(response))
    }

    const columns = [
        {
            title: '',
            width: '100px',
            render: data => <img src={data.foto === null ? noImage : `${URL_BASE}/${data.foto}`} width="100%" />
        }, {
            title: 'Contato',
            render: data => (
                <>
                    <div><b>Nome:</b> {data.nome}</div>
                    <div><b>Contato:</b> {data.email} / <span className="maskTelefone">{data.contato}</span></div>
                    <div><b>Cidade:</b> {data.cidade}</div>
                    <div>
                        <span onClick={() => getAnnotation(data.id_customer)} className="btn btn-default btn-icons" title="Anotações">
                            <i className="icon-note m-none" />
                        </span>
                        <span onClick={() => handlePayments(data.id_customer)} className="btn btn-default btn-icons" title="Detalhes de pagamentos">
                            <i className="icon-wallet m-none" />
                        </span>
                    </div>
                </>
            )
        }, {
            title: 'Geral',
            render: data => data.pessoa == "F"
                ? (
                    <>
                        <div><b>CPF:</b> <span className="maskCPF">{data.cpf}</span></div>
                        <div><b>RG:</b> {data.rg}</div>
                    </>
                ) : (
                    <>
                        <div><b>R. Social:</b> {data.razao_social}</div>
                        <div><b>N. Fantasia:</b> {data.nome_fantasia}</div>
                        <div><b>CNPJ:</b> <span className="maskCnpj">{data.cnpj}</span></div>
                    </>
                )
        }, {
            title: '',
            align: 'right',
            render: data => (
                <>
                    <a href={`/pedidos/${data.searchClient}`} className="btn btn-default btn-icons" title="Pedidos deste cliente">
                        <i className="fa fa-list-alt m-none"></i>
                    </a>
                    <span onClick={() => downPdf(data.id_customer)} className="btn btn-default btn-icons" title="Tabela de preços">
                        <i className="icon-printer m-none" />
                    </span>
                    <a href={`/clientes/update/${data.id_customer}`} className="btn btn-primary">
                        <i className="icon-note" /> Editar
                    </a>
                </>
            )
        }
    ];

    return (
        <div className="bg-padrao">
            <PageDetails dataPayments={dataInfo} onClose={() => setDataInfo({})} />
            <Annotations annotations={annotations} />
            <Row>
                <Col md="6">
                    <h1>Nossos Clientes</h1>
                </Col>
                <Col md="6" className="text-right">
                    <a href="/clientes/create" className="btn btn-success">
                        <i className="icon-plus"></i>
                        Cadastrar Novo
                    </a>
                </Col>
            </Row>
            <div className="hardLine"></div>
            <Filters>
                <FiltersHeader>
                    <i className="fa fa-filter"></i> Filtros
                </FiltersHeader>
                <FiltersOptions id="filterOptions">
                    <Option>
                        <label className="m-none">Buscar Por</label>
                        <input type="text" name="buscaAll" className="form-control input-sm" />
                    </Option>
                    <Option>
                        <label className="m-none">Por Representante</label>
                        <select name="representatives" className="form-control input-sm">
                            <option value=""></option>
                            <option value="null"># Sem representante</option>
                            {representatives.map(item => (
                                <option value={item.id_representative}>{item.nome_fantasia || item.nome}</option>
                            ))}
                        </select>
                    </Option>
                    <Option>
                        <button onClick={() => filters.filtrate()} className="btn btn-info btn-filtrate">Filtrar</button>
                        <button onClick={() => filters.clearFilters(dataSource.dataAll)} className="btn btn-default">
                            <i className="icon-refresh m-none" />
                        </button>
                    </Option>
                </FiltersOptions>
            </Filters>
            <Table
                className="v-align-all"
                columns={columns}
                dataSource={dataSource.dataTable}
                loading={dataSource.loading}
                pagination={{ pageSize: 20 }}
            />
        </div>
    )
}
