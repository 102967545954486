import React, { useEffect, useState } from 'react';
import { userLogout } from '@utils/auth';

import Logo from '@assets/logo.png';
import {
    MenuWrap,
    Menu,
    MenuItem,
    MenuLink,
    MenuIcon,
    MenuTitle,
    SubMenu,
    ButtonLogout,
    ButtonToggle
} from './style';

export default function Sidebar() {
    const [toggle, setToggle] = useState(false);

    useEffect(_ => {
        document.querySelectorAll('#painelWrapper a').forEach(link => {
            link.addEventListener('click', function () {
                const li = document.querySelector('.current')

                if (li) {
                    li.classList.remove('current')
                }

                link.closest('li').classList.add('current')
            })
        })
    }, [])

    return (
        <MenuWrap onOpen={toggle}>
            <ButtonToggle onClick={() => setToggle(!toggle)}>
                <MenuIcon className="icon-menu m-none" />
            </ButtonToggle>
            <img src={Logo} width="180px" alt="Back Industria" style={{ display: "block", margin: "0 auto 10px" }} />
            <Menu id="painelWrapper">
                <MenuItem>
                    <MenuLink to="/dashboard">
                        <MenuIcon className="fa fa-tachometer"></MenuIcon>
                        <MenuTitle>Dashboard</MenuTitle>
                    </MenuLink>
                </MenuItem>
                <MenuItem>
                    <MenuLink to="/financeiro">
                        <MenuIcon className="fa fa-usd"></MenuIcon>
                        <MenuTitle>Financeiro</MenuTitle>
                    </MenuLink>
                    <SubMenu>
                        <MenuLink to="/financeiro/cheques">Cheques</MenuLink>
                        <MenuLink to="/financeiro/cheques_pendentes">Cheques Pendentes</MenuLink>
                        <MenuLink to="/financeiro/transferencias">Transferências</MenuLink>
                    </SubMenu>
                </MenuItem>
                <MenuItem>
                    <MenuLink to="/produtos">
                        <MenuIcon className="fa fa-cube"></MenuIcon>
                        <MenuTitle>Produtos</MenuTitle>
                    </MenuLink>
                </MenuItem>
                <MenuItem>
                    <MenuLink to="/pedidos">
                        <MenuIcon className="fa fa-shopping-bag"></MenuIcon>
                        <MenuTitle>Pedidos</MenuTitle>
                    </MenuLink>
                </MenuItem>
                <MenuItem>
                    <MenuLink to="/colaboradores">
                        <MenuIcon className="fa fa-users"></MenuIcon>
                        <MenuTitle>Colaboradores</MenuTitle>
                    </MenuLink>
                </MenuItem>
                <MenuItem>
                    <MenuLink to="/representantes">
                        <MenuIcon className="fa fa-users"></MenuIcon>
                        <MenuTitle>Representantes</MenuTitle>
                    </MenuLink>
                </MenuItem>
                <MenuItem>
                    <MenuLink to="/clientes">
                        <MenuIcon className="fa fa-address-book-o"></MenuIcon>
                        <MenuTitle>Clientes</MenuTitle>
                    </MenuLink>
                    <SubMenu>
                        <MenuLink to="/redes">Redes</MenuLink>
                    </SubMenu>
                </MenuItem>
                <MenuItem>
                    <MenuLink to="/cargas">
                        <MenuIcon className="fa fa-truck"></MenuIcon>
                        <MenuTitle>Carregamento</MenuTitle>
                    </MenuLink>
                    <SubMenu>
                        <MenuLink to="/rotas">Rotas</MenuLink>
                        <MenuLink to="/veiculos">Veiculos</MenuLink>
                    </SubMenu>
                </MenuItem>
                <MenuItem>
                    <MenuLink to="/relatorio">
                        <MenuIcon className="fa fa-list-alt"></MenuIcon>
                        <MenuTitle>Relatórios</MenuTitle>
                    </MenuLink>
                    <SubMenu>
                        <MenuLink to="/relatorio/network">Redes</MenuLink>
                        <MenuLink to="/relatorio/pedidos/dia">Pedidos por dia</MenuLink>
                        <MenuLink to="/relatorio/pedidos/cliente">Primeiro pedido por cliente</MenuLink>
                        <MenuLink to="/relatorio/pedidos/representante/date">Pedidos por Representante</MenuLink>
                        <MenuLink to="/relatorio/pedidos/representante/delivery">Faturado por Representante</MenuLink>
                    </SubMenu>
                </MenuItem>
                <MenuItem>
                    <MenuLink to="/users">
                        <MenuIcon className="fa fa-user-o"></MenuIcon>
                        <MenuTitle>Usuários SYS</MenuTitle>
                    </MenuLink>
                </MenuItem>
            </Menu>
            <ButtonLogout onClick={userLogout}>
                <MenuIcon className="icon-logout" />
                <MenuTitle>Sair</MenuTitle>
            </ButtonLogout>
        </MenuWrap>
    )
}
