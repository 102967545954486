import React from 'react';
import useTable from '@hooks/useTable';

import { Row, Col } from 'react-bootstrap';
import { Table, Tag } from 'antd';

const statusUser = {
    1: {
        title: 'Ativo',
        color: 'green'
    },
    2: {
        title: 'Inativo',
        color: 'volcano'
    }
}

export default function PageUser() {
    const { dataSource, handleSearched } = useTable('/user/');

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name'
        }, {
            title: 'E-mail',
            dataIndex: 'email'
        }, {
            title: 'Status',
            render: data => (
                <Tag color={statusUser[data.status].color}>
                    {statusUser[data.status].title}
                </Tag>
            )
        }, {
            title: '',
            align: 'right',
            width: '200px',
            render: data => (
                <a href={`/user/update/${data.id_admin}`} className="btn btn-primary">
                    <i className="icon-note" /> Editar
                </a>
            )
        }
    ];

    return (
        <div className="bg-padrao">
            <Row>
                <Col md="6">
                    <h1>Usuários do sistema</h1>
                </Col>
                <Col md="6" className="text-right">
                    <a href="/user/create" className="btn btn-success">
                        <i className="icon-plus"></i>
                        Cadastrar Novo
                    </a>
                </Col>
            </Row>
            <div className="hardLine"></div>
            <Table
                columns={columns}
                dataSource={dataSource.dataTable}
                loading={dataSource.loading}
                pagination={{ pageSize: 20 }}
            />
        </div>
    )
}