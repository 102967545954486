import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    box-shadow: none !important;
  }

  body, html {
    width: 100%;
    height: 100%;
    color: #333;
    background: #EBEFF2;
    font-family: Nunito, sans-serif;
    font-weight: 400;
    line-height: 1.5;
  }

  html {
    font-size: 62.5%;
  }

  @media (max-width: 768px) {
    font-size: 50%;
  }

  body {
    font-size: 1.6rem;
  }

  button {
    border: 1px solid #DDD;
    cursor: pointer;
  }

  /* ALERT POPUP */
  .alert {
    position: fixed;
    bottom: 30px;
    right: 15px;
    background: #E67E22;
    color: #FFF;
    padding: 20px;
    transition: all 350ms;
    z-index: 99999;
    cursor: pointer;
  }

  .alert .closebtn {
    margin-left: 15px;
    color: #FFF;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    transition: 0.3s;
  }

  .alert .closebtn:hover {
    color: #000;
  }

  .tag-notification {
    background: #E67E22;
    color: #FFF;
    border-radius: 50px;
    transform: translateY(3px);
    font-size: 11px;
    width: 20px;
    height: 20px;
    display: none;
    float: right;
    align-items: center;
    justify-content: center;
    line-height: 16px;
  }

  /* DATEPICKER */
  .datepicker-days .dow {
    padding: 3px 5px;
  }

  .datepicker-days .day:hover {
    background: #CCC !important;
  }

  .datepicker-days .day.active {
    background: #2C3E50 !important;
    color: #FFF !important;
  }

  .circle {
    float: left;
    width: 10px;
    height: 10px;
    background: #E67E22;
    border-radius: 50px;
    margin-right: 10px;
  }

  /* BACKGROUNDS COLOR */
  .bg-padrao {
    padding: 15px;
    background: #FFF;
    border: solid 1px #E9E9E9 !important;
  }

  .bg-clean-default {
    background: #ECECEC;
  }

  .bg-clean-green {
    background: rgba(68, 189, 50, .2);
  }

  .bg-clean-blue {
    background: #E9F1FD;
  }

  .bg-clean-red {
    background: #f96758;
    color: #FFF;
  }

  .radio-group {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .radio-group input {
    display: block;
    float: left;
    margin-top: 5px;
    margin-right: 5px;
  }

  h1, h2, h3 {
    padding: 0px;
    margin: 0px;
    letter-spacing: -1px;
    font-size: 25px;
    font-weight: 300;
    text-transform: uppercase;
  }

  input::placeholder,
  button::placeholder,
  select::placeholder {
    color: #999;
  }

  input.errorInput,
  select.errorInput {
    border: 1px solid #E74C3C !important;
  }

  .adressBox input:focus {
    border: 1px solid #0097E6 !important;
  }

  a.btn:not([href]):not([tabindex]) {
    color: #FFF;
  }

  .btn {
    display: inline-flex;
    align-items: center;
    min-height: 40px;
    max-height: 40px;
    font-size: 15px;
    line-height: 30px;
    text-transform: uppercase;
    border-radius: 0;
    cursor: pointer;
  }

  .btn:disabled {
    cursor: default;
  }

  .btn-large {
    min-width: 230px;
    padding: 15px 30px;
    margin-bottom: 30px;
    justify-content: center;
  }

  .btn:hover {
    opacity: .8;
  }

  .btn i {
    margin-right: 8px;
  }

  .m-none {
    margin: 0 !important;
  }

  .btn-icons {
    font-size: 15px;
    line-height: 14px;
    padding: 11px 12px;
  }

  .btn-default {
    color: #666;
    border-color: #CCC;
  }

  .btn-orange:active,
  .btn-orange:hover,
  .btn-orange {
    color: #FFF;
    border-color: #F56C2D;
    background: #F56C2D;
  }

  .btn + .btn {
    margin-left: 5px;
  }

  .btn-primary:active,
  .btn-primary:hover,
  .btn-primary {
    color: #FFF !important;
    background-color: #393939 !important;
    border-color: #393939 !important;
  }

  /* LINK MAP */
  .btn-mapLink {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .selectB {
    border: 1px solid #333;
  }

  .form-control {
    color: #777;
    font-size: 1.6rem;
    border: solid 1px #bdbdbd;
    /* border: 1px solid #DDD; */
    border-radius: 0px;
  }

  .form-group label {
    margin: 0 !important;
    white-space: nowrap;
  }

  .form-control:disabled,
  .form-control[readonly] {
    cursor: default ;
  }

  .hardLine {
    position: relative;
    display: inline-block;
    width: 100%;
    border-top: 1px solid #ecf0f1;
    margin: 10px 0;
  }

  .hardLine span {
    position: absolute;
    top: -5px;
    left: 5px;
    color: #999;
    text-transform: uppercase;
    padding: 0 5px;
    font-size: 12px;
    background: #FFF;
  }

  .alert {
    border-radius: 0;
    margin: 0;
  }

  .alert-info {
    background-color: #3498DB;
    border-color: #3498DB;
    color: #FFF;
  }

  .input-icon {
    position: relative;
    flex: 1;
  }

  .input-icon:not(.input-icon-right) input {
    padding-left: 30px;
  }

  .input-icon.input-icon-right input {
    padding-right: 30px;
  }

  .input-icon > span,
  .input-icon > i {
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 8px;
  }

  .input-icon > span {
      cursor: pointer;
  }

  .input-icon span i {
    margin-right: 8px;
  }

  .input-icon.input-icon-right > i,
  .input-icon.input-icon-right > span {
    right: 0;
  }

  big {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    margin: 15px 0;
  }

  table {
    width: 100%;
    margin: 1rem 0;
    color: #212529;
  }

  table:not(.table) tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
  }

  .ant-tag {
    margin-bottom: 10px;
    text-align: center;
    user-select: none;
  }

  .ant-table-expanded-row > td.ant-table-cell {
    padding: 5px;
  }

  .ant-table-expanded-row table,
  .ant-table-expanded-row.ant-table {
    margin: 0 !important;
  }

  .ant-table-expanded-row thead th {
    font-size: 14px !important;
    padding: 5px 0 0 !important;
  }

  .ant-table-row-expand-icon {
    background: none !important;
  }

  .ant-table-thead > tr > th,
  table thead > tr > th {
    background: none !important;
    color: #212529;
    font-weight: 700;
    font-size: 14px;
  }

  .ant-table-cell input[disabled] {
    background: none !important;
    border: none !important;
    color: initial !important;
  }

  .ant-table-tbody .actions {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .ant-table-expanded-row .ant-table {
    margin: 0 !important;
    background: #EEE !important;
  }

  .ant-table-expanded-row .ant-table thead tr {
    background: #9E9E9E !important;
  }

  .ant-table-expanded-row .ant-table thead th {
    padding: 3px 5px !important;
  }

  .v-align-all table tr td,
  .v-align table tr td:not(:last-child) {
    vertical-align: top;
  }

  /* TABELA PEDIDOS */
  .table-requests table tr {
    position: relative;
  }

  .table-requests table thead tr {
    background: #555455 !important;
  }

  .table-requests table thead tr th {
    color: #FFF !important;
    font-size: 13px !important;
  }

  .table-requests table thead .ant-table-cell-scrollbar {
    box-shadow: none !important;
  }

  .table-requests table tr td:first-child {
    padding-left: 40px;
  }

  .table-requests .line-id {
    position: absolute;
    width: 100px;
    top: 0;
    left: 0;
    opacity: .6;
    line-height: 36px;
    transform: rotate(-90deg) translate(-31%, -85%);
  }

  /* REQUESTS / NFE */
  #nfe .input-group {
    margin-bottom: 25px;
  }

  #nfe .input-group input {
    border-color: #E8E8E8 !important;
  }

  #nfe .input-group-prepend {
    width: 20%;
    cursor: default;
  }

  #nfe .input-group-text {
    width: 100%;
    border-radius: 0;
    border: 0;
    background: none;
    justify-content: flex-end;
    padding: 0;
    padding-right: 25px;
  }

  #nfe input:read-only {
    background: none;
  }

  #nfe input[type="file"] {
    display: none;
  }

  #nfe .input-icon > span,
  #nfe .input-icon > i {
    background: #2C3E50;
    color: #FFF;
  }
`
