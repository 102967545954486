import React from 'react';
import Sidebar from '../Sidebar';
import Routes from './Routes';
import Shell from './Shell';

import { PaymentProvider } from '@hooks/usePayment';
import { isAuthenticated } from '@utils/auth';

export default function App() {

  if (!isAuthenticated()) {
    return <Routes></Routes>
  }

  return (
    <PaymentProvider>
      <Sidebar />
      <Shell>
        <Routes></Routes>
      </Shell>
    </PaymentProvider>
  )
}
