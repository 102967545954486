import React, { useState, useEffect } from 'react';
import Api from '@utils/api';
import { isEmpty, printFile } from '@utils/functions';

import Alert from '@components/Alert';

export default function DetailsNFe({ dataInfos }) {
    const [fileData, setFileData] = useState({});
    const [alert, setAlert] = useState({});

    const handleChangeFile = event => {
        let File = event.target.files[0];

        if (File.type.includes('text/xml')) {
            let name = File.name;
            let picReader = new FileReader();

            picReader.onloadend = e => setFileData({ ...fileData, arquivo: name, xml: e.target.result });
            picReader.readAsDataURL(File);
        }
    }

    const sendNFE = () => {
        Api(`/request/nfe/${dataInfos.id_request}`, fileData)
        .then(res => {
            if (!isEmpty(res)) setFileData({ ...res, NS: `${res.numero} / ${res.serie}` });

            setAlert({
                title: 'SUCESSO!',
                html: 'XML anexado ao pedido.'
            })
        }).catch(err => setAlert({
            title: 'Nos detectamos um problema',
            html: err.message,
            type: 'warning'
        }));
    }

    const exDownload = type => {
        Api(`/request/nfe/${dataInfos.id_request}/${type}`)
        .then(res => printFile(res.file, type))
    }

    useEffect(() => {
        if (dataInfos.nfe != null && isEmpty(fileData))
            setFileData({
                ...dataInfos.nfe, NS: `${dataInfos.nfe.numero} / ${dataInfos.nfe.serie}`
            })
    }, [dataInfos]);

    return (
        <>
            <Alert onOpen={alert} />
            <div id="nfe" className="tab-pane fade">
                <input type="file" id="selectNFE" accept=".xml" onChange={handleChangeFile} />
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Número/Série</span>
                    </div>
                    <input type="text" readOnly className="form-control" value={fileData.NS} />
                </div>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Chave</span>
                    </div>
                    <input type="text" readOnly className="form-control" value={fileData.chave} />
                </div>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Emissão</span>
                    </div>
                    <input type="text" readOnly className="form-control" value={fileData.emissao} />
                </div>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Upload XML</span>
                    </div>
                    <div className="input-icon input-icon-right">
                        <input type="text" readOnly className="form-control" value={fileData.arquivo} />
                        <span onClick={() => document.getElementById('selectNFE').click()}>
                            <i className="icon-doc" />
                            Selecionar XML
                        </span>
                    </div>
                </div>
                <div className="float-right">
                    {typeof fileData.chave !== 'undefined' && fileData.chave !== '' && (
                        <>
                            <span onClick={() => exDownload('xml')} className="btn btn-default" title="Baixar XML">
                                <i className="icon-cloud-download" />
                                Baixar XML
                            </span>
                            <span onClick={() => exDownload('pdf')} className="btn btn-default" title="Baixar PDF">
                                <i className="icon-cloud-download" />
                                Baixar PDF
                            </span>
                        </>
                    )}
                    <span onClick={sendNFE} className="btn btn-info" title="Salvar">
                        <i className="icon-cursor" />
                        Salvar
                    </span>
                </div>
            </div>
        </>
    )
}