import styled from 'styled-components';

export const Filters = styled.div``;

export const FiltersHeader = styled.div`
    padding: 10px 20px;
    color: #FFF;
    width: 100%;
    background: #555455;
    font-size: 16px;
    font-weight: bold;
`;

export const FiltersOptions = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

export const Option = styled.div`
    flex: 1;
    padding: 10px;
    flex-basis: 20%;

    &:last-child {
        text-align: right;
    }
`;
