import React from 'react';

const LoadBotton = props => {
    const { Loader } = props;

    return (
        <button disabled={Loader} type="submit" className="btn btn-primary">
            {Loader ? (
                <>
                    <i className="fa fa-spin fa-circle-o-notch"></i>
                    Aguarde...
                </>
            ) : (
                <>
                    <i className="icon-cloud-upload"></i>
                    Salvar
                </>
            )}
        </button>
    )
}

export default LoadBotton;