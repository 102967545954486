import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App/App';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import 'antd/dist/antd.css';
import GlobalStyle from './global';

ReactDOM.render(
	<ConfigProvider locale={ptBR}>
		<GlobalStyle />
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ConfigProvider>,
	document.getElementById('root')
);

{/* <select className="btn selectB">
	<option>Aguardando Pagamento</option>
	<option>Pagamento Confirmado</option>
	<option>Pagamento Recusado</option>
	<option>Mercadoria em Produção</option>
	<option>Mercadoria em Separação</option>
	<option>Mercadoria Enviada</option>
	<option>Mercadoria Entregue</option>
	<option>Pedido Cancelado</option>
</select> */}
