import React from 'react';
import styled from 'styled-components';

const ShellContent = styled.div`
    width: 100%;
    padding: 15px 15px 15px 266px;

    @media (max-width: 1025px) {
        padding: 15px;
    }
`;

export default function Shell(props) {
    return (
        <ShellContent>
            {props.children}
        </ShellContent>
    );
}
