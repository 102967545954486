import React from 'react';

import { Table } from 'antd';
import { Row, Col } from 'react-bootstrap';

import useTable from '@hooks/useTable';
import usePayment from '@hooks/usePayment';
import { stringPayment, stringCheck } from '@components/Constants';

import { doMoney, formatDateToView, compareDates } from '@utils/functions';

const PageFinancialTranfer = () => {
    const { confirmPayment } = usePayment();
    const { dataSource, handleUpdate } = useTable('/request/financial/transfer/');

    const handlePaymentUpdate = (dataDTO) => {
        const auxData = [...dataSource.dataAll];
        const index = auxData.findIndex(item => item.id_payment === dataDTO.id_payment);
        auxData[index] = dataDTO;

        handleUpdate(auxData);
    };

    const columns = [
        {
            title: 'Código',
            render: ({ id_payment }) => `#${(`00000${id_payment}`).substr(-5)}`
        }, {
            title: 'Pedido',
            render: ({ id_request }) => `#${(`00000${id_request}`).substr(-5)}`
        }, {
            title: 'Tipo',
            render: ({ tipo_pagamento }) => stringPayment[tipo_pagamento]
        }, {
            title: 'Valor',
            render: ({ valor }) => doMoney(valor)
        }, {
            title: 'Dt. Venc.',
            render: ({ data_vencimento }) => formatDateToView(data_vencimento)
        }, {
            title: 'Dt. Pgto',
            render: ({ data_vencimento, data_pagamento }) => !data_pagamento && !compareDates(data_vencimento)
                ? (
                    <span style={{ color: 'RED', fontWeight: 'bold' }}>
                        Em atraso
                    </span>
                )
                : (
                    !data_pagamento
                        ? 'Em aberto'
                        : formatDateToView(data_pagamento)
                )
        }, {
            title: '',
            align: 'right',
            render: data => !data.data_pagamento && (
                <span
                    onClick={() => confirmPayment(data.id_payment, data.id_request, handlePaymentUpdate)}
                    className="btn btn-default btn-icons"
                    title="Dar baixa"
                >
                    <i className="icon-check m-none" />
                </span>
            )
        }
    ]

    // Tabela de cheques
    const tableInternalRender = ({ checks }) => {
        const columns = [
            {
                title: 'Data p/ dep.',
                render: ({ deposit_date, status }) => !compareDates(deposit_date) && status < 2
                    ? (
                        <span style={{ color: 'RED', fontWeight: 'bold' }}>
                            {formatDateToView(deposit_date)}
                        </span>
                    )
                    : formatDateToView(deposit_date)
            }, {
                title: 'Código',
                render: ({ code_check }) => <span className="maskCheck">{code_check}</span>
            }, {
                title: 'Status',
                render: ({ status }) => stringCheck[status]
            }, {
                title: 'Valor',
                align: 'right',
                render: ({ check_value }) => doMoney(check_value)
            }
        ]

        return <Table size="small" columns={columns} dataSource={checks} pagination={false} />;
    }

    return (
        <div className="bg-padrao">
            <Row>
                <Col md="6">
                    <h1>Financeiro</h1>
                </Col>
            </Row>
            <div className="hardLine"></div>
            <Table
                className="table-requests"
                size="small"
                columns={columns}
                loading={dataSource.loading}
                dataSource={dataSource.dataTable}
                pagination={{ pageSize: 20 }}
                expandable={{
                    rowExpandable: data => (data.checks.length > 0),
                    expandedRowRender: tableInternalRender
                }}
            />
        </div>
    )
}

export default PageFinancialTranfer;
