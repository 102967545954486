import styled from 'styled-components';

export const ImageBox = styled.div`
    position: relative;
    display: block;
    width: 100%;
    min-height: 110px;
    background: #EEE;
    transition: background-color .25s;
    cursor: pointer;

    div {
        display: none;
        width: 100%;
        text-align: center;

        span {
            position: absolute;
            font-size: 20px;
            bottom: 15px;
            z-index: 10;
            color: #444;
            background: rgba(255, 255, 255, .6);
            padding: 0px 8px;
            border-radius: 50%;
        }
    }

    input {
        display: none;
    }

    img {
        display: block;
        width: 100%;
        z-index: 5;
    }

    i:not(.fa) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 25px;
    }

    &:hover {
        background: #DDD;

        div {
            display: block;
        }

        img {
            opacity: .9;
        }
    }
`;