import React, { useState, useEffect } from 'react';
import Api from '@utils/api';

import { Row, Col } from 'react-bootstrap';
import { Container, ComponentContent } from './style';

const AdressesBox = ({ Adresses, setValues, Alert }) => {
    const [data, setData] = useState([]);

    const handleChange = async event => {
        const auxData = [...data]
        const elementIndex = event.target.dataset.index
        const elementName = event.target.name
        const elementValue = event.target.value

        auxData[elementIndex][elementName] = elementValue

        if (elementName.includes('cep') && elementValue.length > 8) {
            await Api(`/busca_cep/${elementValue}`).then(res => {
                auxData[elementIndex] = res

                // CEP COMUM CIDADE
                let inputSelect = res.logradouro !== ''
                    ? `input[data-index="${elementIndex}"][name="numero"]`
                    : `input[data-index="${elementIndex}"][name="logradouro"]`

                document.querySelector(inputSelect).focus()

            }).catch(err => Alert({ html: err.message, type: 'warning' }))
        }

        setData(auxData)
        setValues({
            target: {
                name: 'enderecos',
                value: auxData
            }
        })
    }

    const handleDeletComponent = index => {
        const auxData = [...data]
        auxData.splice(index, 1)

        setData(auxData)
    }

    const addAdress = _ => setData([...data, {}])

    useEffect(_ => {
        if (typeof Adresses !== 'undefined' && Adresses.length > 0) {
            setData([...Adresses])
        }
    }, [Adresses])

    return (
        <>
            <div className="hardLine"></div>
            <big>Endereços</big>
            <Container>
                {data.map((item, i) => (
                    <ComponentContent className="adressBox">
                        <Row>
                            <Col md="3">
                                <div className="form-group">
                                    <label>CEP</label>
                                    <input onChange={handleChange} data-index={i} name="cep" value={item.cep} type="text" className="form-control input-sm maskCep" required />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <label>Endereço</label>
                                    <input onChange={handleChange} data-index={i} name="logradouro" value={item.logradouro} type="text" className="form-control input-sm" required />
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="form-group">
                                    <label>Número</label>
                                    <input onChange={handleChange} data-index={i} name="numero" value={item.numero} maxLength="10" type="text" className="form-control input-sm" />
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="form-group">
                                    <label>Bairro</label>
                                    <input onChange={handleChange} data-index={i} name="bairro" value={item.bairro} type="text" className="form-control input-sm" required />
                                </div>
                            </Col>
                            <Col md="2">
                                <div className="form-group">
                                    <label>Estado</label>
                                    <input onChange={handleChange} data-index={i} name="uf" value={item.uf} maxLength="2" type="text" className="form-control input-sm" required />
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="form-group">
                                    <label>Cidade</label>
                                    <input onChange={handleChange} data-index={i} name="cidade" value={item.cidade} type="text" className="form-control input-sm" required />
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="form-group">
                                    <label>Complemento</label>
                                    <input onChange={handleChange} data-index={i} name="complemento" value={item.complemento} type="text" className="form-control input-sm" />
                                </div>
                            </Col>
                        </Row>
                        <div className="hardLine"></div>
                        <div className="footer">
                            <button type="button" className="btn btn-danger" onClick={_ => handleDeletComponent(i)}>
                                <i className="fa fa-trash"></i> Remove
                            </button>
                        </div>
                    </ComponentContent>
                ))}

                <button type="button" className="btn btn-info btn-large btn-block" onClick={addAdress}>
                    <i className="icon-plus"></i> novo endereço
                </button>
            </Container>
        </>
    )
}

export default AdressesBox;
