import styled from 'styled-components';

export const Modal = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    z-index: 10;
`;

export const Content = styled.div`
    position: relative;
    display: block;
    width: 90%;
    max-width: 650px;
    padding: 0 10px 10px;
    background: #FFF;
    color: #666;
    font-size: 16px;
`;

export const Title = styled.div`
    float: left;
    display: block;
    width: calc(100% + 20px);
    margin: 0 -10px 10px;
    background: #22313C;
    padding: 20px;
    font-family: 'Quicksand', sans-serif;
    color: #FFF;
    font-size: 22px;
    cursor: default;
    user-select: none;
`;

export const AnnotationWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: 55vh;
    overflow: auto;
`;

export const Annotation = styled.div`
    position: relative;
    float: left;
    width: 100%;
    background-color: ${props => props.fixed ? '#C7C7C7' : '#F5F5F5'};
    margin-bottom: 20px;
    border-radius: 0;
    font-size: 13px;
    line-height: 20px;
    padding: 10px 40px 20px 10px;
    user-select: none;

    p {
        margin: 0;
    }

    i {
        position: absolute;
        top: 7px;
        right: 7px;
        color: #c0392b;
        cursor: pointer;
    }
`;

export const Author = styled.span`
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 11px;
    color: #999;
`;

export const Separator = styled.hr`
    display: inline-block;
    width: 100%;
    margin: 10px auto;
`;

export const Options = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
`;

export const Footer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;

export const Button = styled.button`
    width: 180px;
    padding: 8px;
    background: ${props => props.send ? '#17A2B8' : '#F56C2D'};
    color: #FFF;
    border: none;
    border-radius: 0px;
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    outline: none;

    & +&  {
        margin-left: 5px;
    }
`;
