import React, { useState } from 'react';
import useForm from '@hooks/useForm';
import Api from '@utils/api';
import { login } from '@utils/auth';

import { Container, Form } from './styles';
import Alert from '@components/Alert';
import Logo from '@assets/logo.png';

export default function PageLogin() {
    const { values, handleChange, handleSubmit } = useForm();
    const [alert, setAlert] = useState({});

    const handleSendForm = data => {
        Api('/user/login/', data)
        .then(res => login(res.token))
        .catch(err => setAlert({
            html: err.message,
            type: 'error'
        }));
    }
    
    return (
        <>
            <Alert onOpen={alert} />
            <Container>
                <Form onSubmit={handleSubmit(handleSendForm)}>
                    <img src={Logo} alt="Painel do Representante" />
                    <input onChange={handleChange} type="email" placeholder="E-mail" name="email" />
                    <input onChange={handleChange} type="password" placeholder="Senha" name = "password" />
                    <button type="submit" className="btn btn-info float-right">
                        <i className="icon-login"></i>
                        Entrar
                    </button>
                </Form>
            </Container>
        </>
    )
}