import React from 'react';

import { ShippingBox, ShippingContent, ShippingInfo, ShippingAction } from './style';

const AdressBox = ({ values, alterAdress, disabled }) => {
    return (
        <ShippingBox forForm>
            <ShippingContent>
                {values && (
                <ShippingInfo>
                    <h4>{values.logradouro}, {values.numero}</h4>
                    <span>{values.cidade}, {values.uf} - CEP {values.cep}</span>
                    <span>{values.complemento}</span>
                </ShippingInfo>
                )}
                <ShippingAction style={{ textAlign: (!values && 'left'), lineHeight: (!values && '42px') }}>
                    <button disabled={disabled} type="button" onClick={alterAdress} style={{ color: (disabled && '#999') }}>
                        {values ? 'Alterar' : 'Selecionar endereço'}
                    </button>
                </ShippingAction>
            </ShippingContent>
        </ShippingBox>
    )
}

export default AdressBox;