import Api from '@utils/api';

export const doMoney = (value, FractionDigits = 2) => `R$ ${new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: FractionDigits
}).format(
    value === null ? 0 : parseFloat(value) < 0.05 ? 0 : parseFloat(value)
).replace('R$ ', '')}`;

export const isEmpty = obj => {
    return Object.keys(obj).length === 0;
}

export const validaCpfCnpj = data => {
    const valString = data.trim().replace(/[^\d]+/g, '');

    if (valString == '') {
        throw new Error('Informar documentação')
    }

    // VALIDA CPF
    if (valString.length === 11) {
        if (valString == "00000000000") {
            throw new Error('CPF inválido')
        }

        let Soma = 0;
        for (let i = 1; i <= 9; i++) {
            Soma = Soma + parseInt(valString.substring(i - 1, i)) * (11 - i);
        }

        let Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) {
            Resto = 0;
        }

        if (Resto != parseInt(valString.substring(9, 10))) {
            throw new Error('CPF inválido')
        }

        Soma = 0;
        for (let i = 1; i <= 10; i++) {
            Soma = Soma + parseInt(valString.substring(i - 1, i)) * (12 - i);
        }

        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) {
            Resto = 0;
        }

        if (Resto != parseInt(valString.substring(10, 11))) {
            throw new Error('CPF inválido')
        }

        return;
    }

    if (valString.length === 14) {
        // Elimina CNPJs invalidos conhecidos
        if (valString == "00000000000000" ||
            valString == "11111111111111" ||
            valString == "22222222222222" ||
            valString == "33333333333333" ||
            valString == "44444444444444" ||
            valString == "55555555555555" ||
            valString == "66666666666666" ||
            valString == "77777777777777" ||
            valString == "88888888888888" ||
            valString == "99999999999999") {
            throw new Error('CNPJ inválido')
        }

        // Valida DVs
        let tamanho = valString.length - 2;
        let numeros = valString.substring(0, tamanho);
        let digitos = valString.substring(tamanho);

        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;

            if (pos < 2) {
                pos = 9;
            }
        }

        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0)) {
            throw new Error('CNPJ inválido')
        }

        tamanho = tamanho + 1;
        numeros = valString.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;

            if (pos < 2) {
                pos = 9;
            }
        }

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1)) {
            throw new Error('CNPJ inválido')
        }

        return;
    }
}

export const compareDates = date => {
    if (date == null) return;

    const parts = date.split('-') // separa a data
    const today = new Date()      // data atual

    date = new Date(parts[0], parts[1] - 1, parts[2]) // formata 'date'

    // compara se a data informada é maior que a data atual
    // e retorna true ou false
    return date >= today ? true : false
}

export const formatDateToDB = date => {
    if (date == null) return;

    const parts = date.split('/')
    return new Date(parts[2], parts[1] - 1, parts[0])
}

export const formatDateToView = date => {
    if (date == null) return;
    return date.split(' ')[0].split('-').reverse().join('/')
}

export const printFile = (base64, fileType = 'pdf', fileName) => {
    const data = `data:application/${fileType};base64,${base64}`;
    const html = `<embed width=100% height=100% type="application/pdf" src="${data}"></embed>`;
    const printParams = `
        dependent=yes,
        locationbar=no,
        scrollbars=yes,
        menubar=yes,
        resizable`;

    const print = window.open('', '', printParams);
    print.document.write(html);
}

export const downloadFile = (base64, fileType, fileName) => {
    fileName = `${fileName}.${fileType}`

    fileType = fileType.includes('pdf')
        ? `application/${fileType}`
        : `image/${fileType}`

    const data = `data:${fileType};base64,${base64}`
    const downloadLink = document.createElement("a")
    downloadLink.href = data
    downloadLink.download = fileName
    downloadLink.click()
    downloadLink.remove()
}

export const createAlert = message => {
    const parsedHTML = `
    <span class="closebtn">&times;</span>
    ${message}
    `

    const alert = document.createElement('div')
    alert.classList.add('alert')
    alert.setAttribute('onclick', "this.remove()")
    alert.innerHTML = parsedHTML

    document.querySelector('#root').appendChild(alert)

    setTimeout(() => {
        document.querySelector('#root > .alert').remove()
    }, 3000)
}

export const AlertReload = async (
    sendUrl,
    redirUrl,
    data,
    setLoading,
    setAlert,
    alertInfos = {
        title: 'Sucesso!!!',
        html: 'Os dados foram salvos com sucesso!',
        type: 'success'
    }
) => {
    setLoading(true);

    await Api(sendUrl, data).then(res => {
        if (typeof res.idRecord !== 'undefined') redirUrl = redirUrl + res.idRecord;

        if (!window.location.href.includes('update')) {
            setTimeout(() => {
                window.location.href = redirUrl
            }, 100);

            alertInfos.hasButton = false
        }

        setAlert(alertInfos);
    }).catch(err => setAlert({
        title: 'Nos detectamos um problema',
        html: err.message,
        type: 'warning'
    }));

    setLoading(false);
}
