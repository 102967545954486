import React, { useCallback, useEffect, useRef, useState } from 'react';
import { doMoney, formatDateToView } from '@utils/functions';
import useTable from '@hooks/useTable';
import Api from '@utils/api';

import { Table } from 'antd';
import { Row, Col } from 'react-bootstrap';
import Alert from '@components/Alert';
import { FiltersOptions, Filters, Option } from '@assets/filter';

export default function PageReportRequestPerCustumer() {
    const [representatives, setRepresentatives] = useState([]);
    const [dates, setDates] = useState([]);
    const representativeRef = useRef(null);
    const dateRef = useRef(null);

    const { dataSource, handleFiltered } = useTable('/customer/report/firstrequests/');

    useEffect(() => {
        Api('/representative/').then(response => setRepresentatives(response))
        Api('/request/requestdates/').then(response => setDates(response))
    }, []);

    const handleGetRequests = useCallback(() => {
        const date = dateRef.current.value;
        const representative = representativeRef.current.value;

        let auxData = dataSource.dataAll;

        if (date) {
            auxData = auxData.filter(item => item.date_filter === date);
        }

        if (representative) {
            auxData = auxData.filter(item => item.id_representative === Number(representative));
        }

        handleFiltered(auxData)
    }, [dataSource.dataAll, handleFiltered]);

    const columns = [
        {
            title: 'Cliente',
            render: ({ id_customer, nome, razao_social, nome_fantasia, cidade }) =>
                !razao_social
                    ? (
                        <>
                            <span className="d-block">#{(`00000${id_customer}`).substr(-5)} - {nome}</span>
                            <span className="d-block">{cidade ? cidade : 'Retirada'}</span>
                        </>
                    ) : (
                        <>
                            <span className="d-block">#{(`00000${id_customer}`).substr(-5)} - {razao_social}</span>
                            <span className="d-block">Nome Fant.: {nome_fantasia}</span>
                            <span className="d-block">{cidade ? cidade : 'Retirada'}</span>
                        </>
                    )
        }, {
            title: 'Valor compra',
            render: ({ valor_total }) => doMoney(valor_total)
        }, {
            title: 'Data pedido',
            align: 'right',
            render: ({ dt_pedido }) => formatDateToView(dt_pedido)
        }
    ]

    return (
        <div className="bg-padrao">
            <Alert onOpen={alert} />
            <Row>
                <Col md="6">
                    <h1>Relatório - pedidos por cliente</h1>
                </Col>
            </Row>
            <div className="hardLine"></div>
            <Filters>
                <FiltersOptions>
                    <Option>
                        <label className="m-none">Período</label>
                        <select ref={dateRef} className="form-control input-sm">
                            <option value=""></option>
                            {dates.map(date => <option value={date.mes}>{date.mes}</option>)}
                        </select>
                    </Option>
                    <Option>
                        <label className="m-none">Representante</label>
                        <select ref={representativeRef} className="form-control input-sm">
                            <option value=""></option>
                            {representatives.map(item => <option value={item.id_representative}>{item.nome}</option>)}
                        </select>
                    </Option>
                    <Option>
                        <button onClick={handleGetRequests} className="btn btn-info btn-filtrate">Filtrar</button>
                    </Option>
                </FiltersOptions>
            </Filters>
            <Table
                className="table-requests"
                columns={columns}
                dataSource={dataSource.dataTable}
                size="small"
                pagination={{
                    defaultPageSize: 10000,
                    hideOnSinglePage: true
                }}
            />
            <Row className="justify-content-end">
                <Col xl="2" md="3">
                    <span style={{ padding: '10px', textAlign: 'right' }} className="d-block span-p bg-clean-default">
                        <b className="d-block">Total</b>
                        {doMoney(dataSource.dataTable.reduce((total, prox) => total + prox.valor_total, 0))}
                    </span>
                </Col>
            </Row>
        </div>
    )
}
