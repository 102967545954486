const TOKEN_KEY = "@beadmin-Token"
const endpoint = !window.location.href.includes('teste.') ? '' : 'teste.'
export const URL_BASE = `http://${endpoint}backindustria.com.br/api`

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null

export const getToken = () => localStorage.getItem(TOKEN_KEY)

export const login = token => {
    localStorage.setItem(TOKEN_KEY, token)

    window.location.href = '/dashboard/'
}

export const userLogout = () => {
    localStorage.removeItem(TOKEN_KEY)

    window.location.href = '/login/'
}
