import React from 'react'
import { isEmpty, doMoney } from '@utils/functions'

import { Row, Col } from 'react-bootstrap'
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalClose,
    ModalBody,
    ModalTitle,

    BoxStatus,
    StatusTitle,
    StatusTitleIcon,
    StatusContent
} from './style'

export default function PageDetails({ dataInfo, onClose }) {
    const handleOutsideClick = e => {
        if (e.target.id === "modalInfos") {
            onClose()
        }
    }

    const handleClose = _ => {
        onClose()
    }

    return !isEmpty(dataInfo) && (
        <Modal id="modalInfos" onClick={handleOutsideClick}>
            <ModalContent>
                <ModalHeader>
                    <ModalTitle>Detalhes financeiro</ModalTitle>
                    <ModalClose onClick={handleClose}></ModalClose>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md="4">
                            <BoxStatus>
                                <StatusTitle>
                                    {doMoney(dataInfo.total_valor)}
                                    <StatusTitleIcon className="icon-wallet" />
                                </StatusTitle>
                                <StatusContent>
                                    Valor total do carregamento
                                </StatusContent>
                            </BoxStatus>
                        </Col>
                        <Col md="4">
                            <BoxStatus>
                                <StatusTitle>
                                    # {dataInfo.total_clientes}
                                    <StatusTitleIcon className="icon-directions" />
                                </StatusTitle>
                                <StatusContent>
                                    Total entregas (únicas)
                                </StatusContent>
                            </BoxStatus>
                        </Col>
                        <Col md="4">
                            <BoxStatus>
                                <StatusTitle>
                                    # {dataInfo.total_produtos}
                                    <StatusTitleIcon className="fa fa-cube"></StatusTitleIcon>
                                </StatusTitle>
                                <StatusContent>
                                    Total itens do carregamento
                                </StatusContent>
                            </BoxStatus>
                        </Col>
                    </Row>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
