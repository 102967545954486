import styled from 'styled-components';

export const ModalBG = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 1005;
`;

export const ModalContent = styled.div`
    display: block;
    width: 400px;
    padding: 15px;
    background: #FFF;
    color: #666;
    font-size: 16px;

    button:first-child {
        margin-right: 15px;
    }
`;

export const CardAddress = styled.label`
    position: relative;
    display: block;
    width: 100%;
    color: #333;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.25;
    padding: 10px 0 10px 40px;
    border-radius: 10px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, .15);
    border: 1px solid #F5F5F5;
    background-color: #FFF;
    cursor: pointer;

    input {
        display: none;
    }
`;

export const CardRadio = styled.div`
    position: absolute;
    top: 13px;
    left: 10px;
    width: 16px;
    height: 16px;
`;

export const RadioOuterCircle = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, .25);
    transition: border-color .12s cubic-bezier(0, 0, .2,1) 0ms;
    border-radius: 50%;

    input:checked ~ & {
        border-color: #3483FA;
    }
`;

export const RadioInnerCircle = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(.5);
    transition: transform .12s cubic-bezier(0, 0, .2,1) 0ms, background-color .12s cubic-bezier(0, 0, .2,1) 0ms, -webkit-transform .12s cubic-bezier(0, 0, .2,1) 0ms;
    border-radius: 50%;

    input:checked ~ & {
        background-color: #3483FA;
    }
`;

export const CardContent = styled.div`
    display: table;
    width: 100%;
    font-size: 14px;
    color: #666;

    h4 {
        display: block;
        margin: 0;
        font-weight: 400;
        line-height: 1.25;
        font-size: 16px;
        color: #333;
    }

    span {
        display: block;
    }

    button {
        color: #3483fa;
        font-size: 14px;
        background: none;
        border: none;
    }
`;

export const CardInfo = styled.div`
    display: table-cell;
    vertical-align: middle;
`;

export const CardAction = styled.div`
    display: table-cell;
    vertical-align: middle;
    text-align: right;
`;
