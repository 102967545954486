import { formatDateToDB } from '@utils/functions';

export default class customFilters {

  data
  handleChange

  constructor(allData, handleChange) {
    this.data = allData
    this.handleChange = handleChange
  }

  filtrate() {
    if (this.data.length == 0) {
      return
    }

    this.getFilters().forEach(filter => {
      if (!filter.value) {
        return
      }

      this.getFunction(filter.getAttribute('name'), filter.value)
      this.setNewData()
    })
  }

  setNewData() {
    this.handleChange(this.data)
  }

  getFunction(filterName, filterValue) {
    switch (filterName) {
      case "initialDate":
        this.initialDate(filterValue)
        break

      case "finalDate":
        this.finalDate(filterValue)
        break

      case "statusOrder":
        this.statusOrder(filterValue)
        break

      case "statusPayment":
        this.statusPayment(filterValue)
        break

      case "requestCargo":
        this.requestCargo(filterValue)
        break

      case "statusDelivery":
        this.statusDelivery(filterValue)
        break

      case "representatives":
        this.representatives(filterValue)
        break

      case "buscaAll":
        this.buscaAll(filterValue)
        break
    }
  }

  initialDate(filterValue) {
    this.data = this.data.filter(item => {
      const date = new Date(item.created_at)
      date.setHours(0, 0, 0)

      return date >= formatDateToDB(filterValue)
    })
  }

  finalDate(filterValue) {
    this.data = this.data.filter(item => {
      const date = new Date(item.created_at)
      date.setHours(0, 0, 0)

      return date <= formatDateToDB(filterValue)
    })
  }

  statusOrder(filterValue) {
    this.data = this.data.filter(item => item.status_pedido === Number(filterValue))
  }

  statusPayment(filterValue) {
    this.data = this.data.filter(item => item.status_financeiro === Number(filterValue))
  }

  representatives(filterValue) {
    this.data = this.data.filter(item => item.id_representative === Number(filterValue))
  }

  requestCargo(filterValue) {
    this.data = this.data.filter(item => {
      if (filterValue === "null") {
        return !item.id_carga && item.status_pedido < 5
      }

      return item.id_carga == Number(filterValue)
    })
  }

  statusDelivery(filterValue) {
    this.data = this.data.filter(item => item.status_mercadoria == Number(filterValue))
  }

  buscaAll(filterValue) {
    this.data = this.data.filter(item => {
      if (filterValue.includes('##')) {
        const number = Number(filterValue.replace('##', ''));

        return item.id_payment === number
      }

      if (filterValue.includes('#')) {
        const number = Number(filterValue.replace('#', ''));

        return item.id_request === number
      }

      return Object.values(item).filter(obsValue => {
        return String(obsValue).toLowerCase().includes(filterValue.toLowerCase())
      }).length > 0
    });
  }

  clearFilters(allData) {
    this.data = allData
    this.setNewData()

    this.getFilters().forEach(filter => filter.value = '')
  }

  getFilters() {
    return document.querySelectorAll("#filterOptions input, #filterOptions select")
  }

}
