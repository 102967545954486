import React, { useState, useEffect } from 'react';
import useForm from '@hooks/useForm';
import Api from '@utils/api';
import { useParams } from 'react-router-dom';
import { AlertReload } from '@utils/functions';

import { Row, Col } from 'react-bootstrap';
import Alert from '@components/Alert';
import LoadBotton from '@components/LoadBotton';

export default function PageNetworkForm() {
    const { idNetwork } = useParams()
    const { values, handleChange, handleSubmit, setValuesDefault } = useForm()
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({})

    const handleSendForm = data => {
        if (loading) {
            return
        }

        AlertReload(
            '/network/',
            '/redes/update/',
            data,
            setLoading,
            setAlert
        )
    }

    useEffect(() => {
        if (typeof idNetwork === 'undefined')
            return

        Api(`/network/${idNetwork}`)
            .then(response => setValuesDefault(response))
    }, [idNetwork, setValuesDefault])

    return (
        <>
            <Alert onOpen={alert} />
            <div className="bg-padrao">
                <Row>
                    <Col md="6">
                        <h1>
                            {typeof idNetwork === 'undefined' ? 'Cadastrar nova' : 'Editar'} Rede
                        </h1>
                    </Col>
                    <Col md="6" className="text-right">
                        <a href="/redes/" className="btn btn-success">
                            <i className="icon-arrow-left"></i>
                            Voltar para lista
                        </a>
                    </Col>
                </Row>
                <div className="hardLine"></div>
                <form onSubmit={handleSubmit(handleSendForm, setAlert)}>
                    <Row>
                        <Col xl="6" md="10">
                            <div className="form-group">
                                <label>Nome da rede</label>
                                <input onChange={handleChange} type="text" name="nome" value={values.nome} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col xl="1" md="2">
                            <div className="form-group">
                                <label>VP</label>
                                <input onChange={handleChange} type="text" name="publicidade" value={values.publicidade} className="form-control input-sm Mask3Casas" required />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="3" md="6">
                            <div className="form-group">
                                <label>E-mail</label>
                                <input onChange={handleChange} type="email" name="email" value={values.email} className="form-control input-sm" />
                            </div>
                        </Col>
                        <Col xl="2" md="4">
                            <div className="form-group">
                                <label>CNPJ</label>
                                <input onChange={handleChange} type="text" name="cnpj" value={values.cnpj} className="form-control input-sm maskCnpj" required />
                            </div>
                        </Col>
                    </Row>
                    <div className="hardLine">
                        <span>Dados responsavel</span>
                    </div>
                    <Row>
                        <Col xl="4" md="6">
                            <div className="form-group">
                                <label>Nome</label>
                                <input onChange={handleChange} type="text" name="nome_contato" value={values.nome_contato} className="form-control input-sm" />
                            </div>
                        </Col>
                        <Col xl="2" md="3">
                            <div className="form-group">
                                <label>Telefone</label>
                                <input onChange={handleChange} type="tel" name="telefone" value={values.telefone} className="form-control input-sm maskTelefone" />
                            </div>
                        </Col>
                    </Row>
                    <div className="hardLine">
                        <span>Dados bancarios</span>
                    </div>
                    <Row>
                        <Col xl="2" md="4">
                            <div className="form-group">
                                <label>Banco</label>
                                <input onChange={handleChange} type="text" name="banco" value={values.banco} className="form-control input-sm" />
                            </div>
                        </Col>
                        <Col xl="1" md="3">
                            <div className="form-group">
                                <label>Agência</label>
                                <input maxLength="10" onChange={handleChange} type="text" name="agencia" value={values.agencia} className="form-control input-sm" />
                            </div>
                        </Col>
                        <Col xl="1" md="3">
                            <div className="form-group">
                                <label>Conta</label>
                                <input maxLength="10" onChange={handleChange} type="text" name="conta" value={values.conta} className="form-control input-sm" />
                            </div>
                        </Col>
                    </Row>
                    <div className="hardLine"></div>
                    <Row>
                        <Col xl="1" md="3">
                            <div className="form-group">
                                <label>CEP</label>
                                <input onChange={handleChange} type="text" name="cep" value={values.cep} className="form-control input-sm maskCep" required />
                            </div>
                        </Col>
                        <Col xl="4" md="6">
                            <div className="form-group">
                                <label>Endereço</label>
                                <input onChange={handleChange} type="text" name="endereco" value={values.endereco} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col xl="1" md="3">
                            <div className="form-group">
                                <label>Número</label>
                                <input maxLength="10" onChange={handleChange} type="text" name="numero" value={values.numero} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="form-group">
                                <label>Bairro</label>
                                <input onChange={handleChange} type="text" name="bairro" value={values.bairro} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col xl="1" md="2">
                            <div className="form-group">
                                <label>Estado</label>
                                <input maxLength="2" onChange={handleChange} type="text" name="uf" value={values.uf} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col xl="2" md="3">
                            <div className="form-group">
                                <label>Cidade</label>
                                <input onChange={handleChange} type="text" name="cidade" value={values.cidade} className="form-control input-sm" required />
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="form-group">
                                <label>Complemento</label>
                                <input onChange={handleChange} type="text" name="complemento" value={values.complemento} className="form-control input-sm" />
                            </div>
                        </Col>
                    </Row>
                    <div className="hardLine"></div>
                    <LoadBotton Loader={loading} />
                </form>
            </div>
        </>
    )
}
