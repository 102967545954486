import React from 'react';
import { URL_BASE } from "@utils/auth";

import { ImageBox } from './style';

const SlimCrop = ({onChange, value, name}) => {
    const handleClick = () => {
        if (typeof value === 'undefined' || value === null) {
            const element = document.querySelector(`#${name}-id input[type="file"]`);
            element.click();
        }
    }

    const handleRemove = () => {
        onChange({
            target: {
                name,
                value: null
            }
        });
    }

    const readURL = input => {
        let Files = input.target.files;

        if (Files[0]) {
            const reader = new FileReader();
    
            reader.onload = function(e) {
                onChange({
                    target: {
                        name,
                        value: e.target.result
                    }
                });
            }
    
            reader.readAsDataURL(Files[0]);
        }
    }

    const linkBase = url => {
        if (url.indexOf("data:image") != -1) {
            return url;
        }

        return `${URL_BASE}/${url}`;
    }

    return (
        <ImageBox onClick={handleClick} id={`${name}-id`}>
            {typeof value === 'undefined' || value === null ? (
                <i className="icon-picture" />
            ) : (
                <>
                <img src={linkBase(value)} />
                <div>
                    {/* <span><i className="fa fa-trash" onClick={handleRemove} /></span> */}
                </div>
                </>
            )}

            <input type="file" onChange={readURL} accept="image/*" />
        </ImageBox>
    )
}

export default SlimCrop;