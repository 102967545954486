import styled from 'styled-components';

export const Modal = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    z-index: 100;
`;

export const ModalContent = styled.div`
    position: relative;
    display: block;
    width: 100%;
    max-width: 1000px;
    min-height: 700px;
    background: #FFF;
`;

export const ModalHeader = styled.div`
    position: relative;
    padding: 15px 30px;
    border-bottom: 1px solid #E5E5E5;
    background-color: #eee;
`;

export const ModalClose = styled.button`
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    border: none;

    &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #666;
        transform: rotate(45deg) translate(9px, -10px);
    }

    &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #666;
        transform: rotate(-45deg) translate(9px, 9px);
    }
`;

export const ModalBody = styled.div`
    padding: 30px;

    .tab-content {
        margin: 20px 0;
    }

    ul > li {
        margin-bottom: -1px;
    }

    .span-p {
        padding: 5px;
        margin: 0 5px 20px 0;
    }

    table tbody tr,
    table tr td,
    table tr th,
    table {
        margin: 0 !important;
        font-size: 13px !important;
        background: none;
    }

    .ant-table-body {
        overflow-y: auto !important;
    }

    .ant-pagination {
        display: none !important;
    }
`;

export const ModalTitle = styled.h3`
    font-size: 16px !important;
    color: #666 !important;
`;

export const TabLink = styled.a`
    display: block;
    padding: 10px 15px;
    margin-right: 2px;
    color: #555;
    text-decoration: none !important;

    &.active {
        cursor: default;
        background: #FFF;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
    }

    &:hover {
        color: #555;
    }

    &:not(.active):hover {
        background: #EFEFEF;
    }
`;

export const TabTitle = styled.big`
    display: block;
    margin: 10px 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    padding-left: 8px;
    border-left: 5px solid #999;
`;

export const TabList = styled.ul`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    gap: 10px;
    width: 100%;
    list-style: none;
    margin: 0 0 30px;

    li {
        padding: ${props => props.financeiro ? '10px' : '0'};
    }

    li > b {
        display: block;
    }
`;

export const CartContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const CartValues = styled.div`
    width: 300px;
    margin-top: 20px;
`;

export const CartInfo = styled.div`
    padding: ${props => props.price ? '10px 5px' : '0'};
    background-color: ${props => props.price ? '#EDEDED' : 'none'};

    h4 {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: ${props => props.price ? '0' : '15px'};
        text-transform: uppercase;
    }

    div {
        display: flex;
        justify-content: space-between;
        margin-bottom: ${props => props.price ? '0' : '10px'};
        font-size: 13px;
    }
`;

export const AnnotationWrapper = styled.div`
    display: block;
    width: 100%;
    margin-bottom: 10px;
`;

export const Annotation = styled.div`
    position: relative;
    float: left;
    width: 100%;
    background-color: ${props => props.fixed ? '#C7C7C7' : '#F5F5F5'};
    margin-bottom: 5px;
    border-radius: 0;
    font-size: 13px;
    line-height: 20px;
    padding: ${props => props.request ? '10px 10px' : '10px 10px 20px'};
    user-select: none;
`;

export const Author = styled.span`
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 11px;
    color: #999;
`;
