import styled from 'styled-components';

export const ComponentContent = styled.div`
    float: left;
    width: 100%;
    padding: 20px;
    border: 1px solid #CCC;
    border-left: 5px solid #FF6D06;
    margin-bottom: 15px;

    div.footer {
        float: left;
        width: 100%;
        text-align: center;
    }
`;