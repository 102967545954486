import React, { useState, useEffect } from 'react';
import { Permissions } from '@components/Constants';
import { AlertReload } from '@utils/functions';
import { useParams } from 'react-router-dom';
import useForm from '@hooks/useForm';
import Api from '@utils/api';

import { CheckNiveis } from './style';
import { Row, Col } from 'react-bootstrap';
import Alert from '@components/Alert';
import LoadBotton from '@components/LoadBotton';

export default function PageUserForm() {
    const { idUser } = useParams();
    const { values, handleChange, handleSubmit, setValuesDefault } = useForm()
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({})

    const handleSendForm = data => {
        if (loading) {
            return
        }

        AlertReload(
            '/user/',
            '/user/update/',
            data,
            setLoading,
            setAlert
        )
    }

    useEffect(() => {
        if (typeof idUser !== 'undefined')
            Api(`/user/${idUser}`).then(response => setValuesDefault(response));
    }, [])

    return (
        <>
            <Alert onOpen={alert} />
            <div className="bg-padrao">
                <Row>
                    <Col md="6">
                        <h1>
                            {typeof idUser !== 'undefined' ? 'Editar' : 'Cadastrar novo'} usuário
                        </h1>
                    </Col>
                    <Col md="6" className="text-right">
                        <a href="/user/" className="btn btn-success">
                            <i className="icon-arrow-left"></i>
                            Voltar para lista
                        </a>
                    </Col>
                </Row>
                <div className="hardLine"></div>
                <Row className="justify-content-center">
                    <Col md="4">
                        <form onSubmit={handleSubmit(handleSendForm, setAlert)}>
                            <div className="form-group">
                                <label>Nome Completo</label>
                                <input onChange={handleChange} value={values.name} type="text" name="name" className="form-control input-sm" required />
                            </div>
                            <div className="form-group">
                                <label>E-mail</label>
                                <input onChange={handleChange} value={values.email} type="text" name="email" className="form-control input-sm" required />
                            </div>
                            <div className="form-group">
                                <label>Senha {typeof idUser !== 'undefined' && <small>(Deixe em branco caso não for alterar)</small>}</label>
                                <input onChange={handleChange} type="password" name="password" className="form-control input-sm" required={typeof idUser === 'undefined'} />
                            </div>
                            {typeof values.password !== 'undefined' && values.password !== '' && (
                                <div className="form-group">
                                    <label>Repita a senha</label>
                                    <input onChange={handleChange} type="password" name="passwordRep" className="form-control input-sm" required />
                                </div>
                            )}
                            <div className="form-group">
                                <label>Status</label>
                                <select onChange={handleChange} value={values.status} className="form-control input-sm" name="status" required>
                                    <option value=""></option>
                                    <option value="1">Ativo</option>
                                    <option value="2">Inativo</option>
                                </select>
                            </div>
                            <div className="hardLine"></div>
                            <div className="form-group input-check">
                                <span>Níveis de acesso <small>(Clique para alterar)</small></span>
                                {Permissions.map(level => (
                                    <CheckNiveis className={typeof values.levels !== 'undefined' && values.levels.includes(level.Value) && 'text-success'}>
                                        <input onChange={handleChange} value={level.Value} name="levels[]" type="checkbox" />
                                        <i className={typeof values.levels !== 'undefined' && values.levels.includes(level.Value) ? 'icon-check' : 'icon-close'}></i> {level.Title}
                                    </CheckNiveis>
                                ))}
                            </div>
                            <div className="hardLine"></div>
                            <LoadBotton Loader={loading} />
                        </form>
                    </Col>
                </Row>
            </div>
        </>
    )
}
