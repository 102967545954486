import React, { useState, useEffect } from 'react';
import useForm from '@hooks/useForm';
import Api from '@utils/api';
import { AlertReload } from '@utils/functions';

import { Row, Col } from 'react-bootstrap';
import InputAuto from '@components/InputAuto';
import LoadBotton from '@components/LoadBotton';
import Alert from '@components/Alert';

export default function PageCargoForm() {
  const { values, handleChange, handleSubmit, setValuesDefault } = useForm()
  const [dataForm, setDataForm] = useState({ vehicles: [], routes: [] })
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)

  const handleFormSubmit = data => {
    if (loading) {
      return
    }

    AlertReload(
      '/cargo/',
      '/cargas/',
      data,
      setLoading,
      setAlert
    )
  }

  const callCollaborator = ({ id_collaborator }) => setValuesDefault({
    ...values,
    collaborator: id_collaborator
  });

  useEffect(() => {
    Api(`/cargo/data/`)
      .then(res => setDataForm(res));
  }, []);

  return (
    <>
      <Alert onOpen={alert} />
      <div className="bg-padrao">
        <Row>
          <Col md="6">
            <h1>Cadastrar nova carga</h1>
          </Col>
          <Col md="6" className="text-right">
            <a href="/cargas/" className="btn btn-success">
              <i className="icon-arrow-left"></i>
                Voltar para lista
              </a>
          </Col>
        </Row>
        <div className="hardLine"></div>
        <form onSubmit={handleSubmit(handleFormSubmit, setAlert)}>
          <Row>
            <Col md="2">
              <div className="form-group">
                <label>Veículo</label>
                <select className="form-control input-sm" name="vehicle" onChange={handleChange} required>
                  <option value="">Seleciona uma opção</option>
                  {dataForm.vehicles.map(item => (
                    <option value={item.id_vehicle}>{item.model}</option>
                  ))}
                </select>
              </div>
            </Col>
            <Col md="2">
              <div className="form-group">
                <label>Rota</label>
                <select className="form-control input-sm" name="route" onChange={handleChange} required>
                  <option value="">Seleciona uma opção</option>
                  {dataForm.routes.map(item => (
                    <option value={item.id_route}>{item.title_route}</option>
                  ))}
                </select>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label>Colaborador</label>
                <InputAuto
                  callback={callCollaborator}
                  urlSearch={`/collaborator/search/`}
                />
              </div>
            </Col>
            <Col md="2">
              <div className="form-group">
                <label>Data Saída</label>
                <input onChange={handleChange} type="date" name="dt_saida" value={values.dt_saida} className="form-control input-sm" required />
              </div>
            </Col>
            <Col md="12">
              <div className="form-group">
                <label>Observações</label>
                <textarea onChange={handleChange} name="observacoes" rows="5" className="form-control input-sm">{values.cubagem}</textarea>
              </div>
            </Col>
          </Row>
          <div className="hardLine"></div>
          <LoadBotton Loader={loading} />
        </form>
      </div>
    </>
  )
}
