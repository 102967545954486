import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useTable from '@hooks/useTable';
import Api from '@utils/api';
import customFilters from '@utils/customFilters';
import { isEmpty, doMoney, compareDates, formatDateToView, downloadFile, createAlert } from '@utils/functions';
import { status_pedido, status_financeiro, status_mercadoria, formPayment, typePayment, status_carga_short } from '@components/Constants';

import { Row, Col } from 'react-bootstrap';
import { Table, Tag } from 'antd';
import Alert from '@components/Alert';
import PageRequestDetails from './Details';
import { Filters, FiltersHeader, FiltersOptions, Option } from '@assets/filter'

export default function PageRequestList() {
    const { cliente } = useParams();
    const { dataSource, handleUpdate, handleFiltered } = useTable('/request/');
    const [alert, setAlert] = useState({});
    const [dataFilter, setDataFilter] = useState([]);
    const [viewRequest, setViewRequest] = useState({});
    const [representatives, setRepresentatives] = useState([]);
    const filters = new customFilters(dataSource.dataAll, handleFiltered);

    useEffect(() => {
        Api('/request/filter-data/')
            .then(response => {
                setDataFilter(response.cargo);
                setRepresentatives(response.representantes);

                if (cliente) {
                    document.querySelector('input[name="buscaAll"]').value = cliente
                }

                setTimeout(() => {
                    document.querySelector('.btn-filtrate').click()
                }, 500)
            })
    }, []); // eslint-disable-line

    const getRequest = idRequest => {
        Api(`/request/${idRequest}`)
            .then(response => setViewRequest(response))
            .catch(error => setAlert({
                title: 'Nos detectamos um problema',
                html: error.message,
                type: 'warning'
            }));
    }

    const handleCopyRequest = idRequest => {
        Api(`/request/copy/${idRequest}`)
            .then(response => {
                setAlert({
                    title: 'Sucesso!',
                    html: 'Copia do pedido realizada com sucesso, lista atualizada.',
                    type: 'success'
                });

                handleUpdate(response)
            })
    }

    const setNewStatus = (idRequest, status) => {
        function Request() {
            Api(`/request/set-status/${idRequest}/${status}`)
                .then(response => {
                    const auxVar = [...dataSource.dataAll];
                    const index = auxVar.findIndex(item => item.id_request === idRequest);
                    auxVar[index] = response;

                    handleUpdate(auxVar);

                    // ALTERAR DETAILS
                    if (!isEmpty(viewRequest)) {
                        getRequest(idRequest);
                    }

                    setAlert({});
                })
                .catch(err => createAlert(err.message));
        }
        const parsedHTML = (
            <div>
                <span>Confirmar alteração de pedido para<br /><b>({status_pedido[status].title})</b>?</span>
                <div className="hardLine"></div>
                <div className="text-right">
                    <span onClick={() => setAlert({})} className="btn btn-primary" title="Cancelar ação">
                        <i className="icon-ban" /> Não
                </span>
                    <span onClick={Request} className="btn btn-orange" title="Confirmar ação">
                        <i className="fa fa-check-square-o"></i> Sim
                </span>
                </div>
            </div>
        );

        setAlert({
            title: 'ATENÇÃO!!!',
            html: parsedHTML,
            type: 'warning',
            hasButton: false
        })
    }

    const downPdf = (idRequest, hasEntrega = false) => {
        Api(`/request/get-pdf/${idRequest}${hasEntrega ? '/entrega' : ''}`)
            .then(response => downloadFile(response.data, 'pdf', `metalback-${idRequest}`))
    }

    const printTags = idRequest => {
        Api(`/products/get-tags/${idRequest}`)
            .then(response => downloadFile(response, 'pdf', `metalback-${idRequest}`))
    }

    const columns = [
        {
            title: '# Geral',
            render: ({
                status_pedido: pedido,
                id_request,
                cliente,
                contato,
                documento,
                email,
                cidade,
                outOdStock
            }) => (
                <>
                    <div>
                        <Tag color={status_pedido[pedido].color}>
                            {status_pedido[pedido].title}
                        </Tag>
                    </div>
                    <span className="line-id">#{(`00000${id_request}`).substr(-5)}</span>
                    <span className="d-block"><b>{cliente}</b></span>
                    <span className="d-block">
                        <span className="maskTelefone" style={{ marginRight: '15px' }}>
                            {contato}
                        </span>
                        <span className={documento.length <= 11 ? 'maskCPF' : 'maskCnpj'}>
                            {documento}
                        </span>
                    </span>
                    <span className="d-block">{email}{!!cidade && ', ' + cidade}</span>
                    {outOdStock > 0 && pedido < 7 && (
                        <div><Tag color="#E67E22">Possui itens sem estoque</Tag></div>
                    )}

                    {pedido == 2 && (
                        <div>
                            <span onClick={() => setNewStatus(id_request, 9)} className="btn btn-default btn-icons" title="Cancelar Orçamento">
                                <i className="icon-ban m-none" />
                            </span>
                            <span onClick={() => setNewStatus(id_request, 3)} className="btn btn-default btn-icons" title="Aprovar Pedido">
                                <i className="fa fa-check-square-o m-none"></i>
                            </span>
                        </div>
                    )}
                </>
            )
        }, {
            title: 'Financeiro',
            render: data => (
                <>
                    {data.status_pedido < 7 && (
                        <Tag color={status_financeiro[data.status_financeiro].color}>
                            {status_financeiro[data.status_financeiro].title}
                        </Tag>
                    )}
                    <span className="d-block"><b>Data Pedido:</b> {formatDateToView(data.created_at)}</span>
                    <span className="d-block"><b>Forma Pgto:</b> {`${typePayment[data.meio_pagamento]} - ${formPayment[data.forma_pagamento]}`}</span>
                    {data.valor_entrada > 0 && (
                        <span className="d-block"><b>Total Entrada:</b> {doMoney(data.valor_entrada)}</span>
                    )}
                    <span className="d-block"><b>Total Pedido:</b> {doMoney(data.valor_total)}</span>
                    <small className="d-block"><b>Total Pendente:</b> {doMoney(data.valor_total - data.valor_pago)}</small>
                </>
            )
        }, {
            title: 'Mercadoria / Envio',
            render: data => data.status_pedido < 7 && (
                <>
                    <div>
                        <Tag color={status_mercadoria[data.status_mercadoria].color}>
                            {status_mercadoria[data.status_mercadoria].title}
                        </Tag>
                    </div>
                    {data.id_carga != null ? (
                        <>
                            <span className="d-block">
                                <b>Carregamento:</b> #{`00000${data.id_carga}`.substr(-5)}
                            </span>
                            <span className="d-block">
                                <b>
                                    {compareDates(data.data_saida) ? 'Data Prevista:' : 'Data Saida:'}
                                </b> {formatDateToView(data.data_saida)}
                            </span>
                            {data.data_finalizada != null && (
                                <span className="d-block">
                                    <b>Data Entrega:</b> {formatDateToView(data.data_finalizada)}
                                </span>
                            )}
                            <span className="d-block">
                                <b>NFe:</b> {data.path_nfe != null ? 'Emitida' : 'Sem NFe'}
                            </span>
                        </>
                    ) : data.status_pedido == 3 && data.status_mercadoria == 1 && (
                        <div>
                            <span onClick={() => {
                                if (data.outOdStock > 0) {
                                    return createAlert('Existem itens sem estoque neste pedido')
                                }

                                setNewStatus(data.id_request, 5)
                            }} className="btn btn-default btn-icons" title="Detalhes">
                                <i className="icon-share-alt" /> Retirada em mãos
                            </span>
                            <span onClick={() => downPdf(data.id_request, true)} className="btn btn-default btn-icons">
                                <i className="icon-printer m-none" />
                            </span>
                        </div>
                    )}
                </>
            )
        }, {
            title: '',
            align: 'right',
            render: data => (
                <>
                    {data.status_pedido >= 2 && data.status_pedido < 7 && (
                        <>
                            <span onClick={() => downPdf(data.id_request)} className="btn btn-default btn-icons" title={`Imprimir ${data.status_pedido == 2 ? 'orçamento' : 'pedido'}`}>
                                <i className="icon-printer m-none" />
                            </span>
                            <span onClick={() => printTags(data.id_request)} className="btn btn-default btn-icons" title="Imprimir códigos de barras">
                                <i className="fa fa-barcode m-none"></i>
                            </span>
                        </>
                    )}

                    <span onClick={() => getRequest(data.id_request)} className="btn btn-default btn-icons" title="Detalhes">
                        <i className="icon-eyeglass m-none" />
                    </span>
                    <span onClick={() => handleCopyRequest(data.id_request)} className="btn btn-info btn-icons" title="Criar copia">
                        <i className="icon-loop m-none" />
                    </span>

                    <a href={`/pedidos/update/${data.id_request}`} className="btn btn-primary btn-icons">
                        <i className="icon-note m-none" />
                    </a>

                    {data.status_pedido == 3 && (
                        <span onClick={() => setNewStatus(data.id_request, 10)} className="btn bg-clean-red btn-icons" title="Cancelar pedido">
                            <i className="icon-ban m-none" />
                        </span>
                    )}
                </>
            )
        }
    ];

    return (
        <>
            <Alert onOpen={alert} />
            <PageRequestDetails requestData={viewRequest} setNewStatus={setNewStatus} />
            <div className="bg-padrao">
                <Row>
                    <Col md="6">
                        <h1>Pedidos</h1>
                    </Col>
                </Row>
                <div className="hardLine"></div>
                <Filters>
                    <FiltersHeader>
                        <i className="fa fa-filter"></i> Filtros
                    </FiltersHeader>
                    <FiltersOptions id="filterOptions">
                        <Option>
                            <label className="m-none">Data Inicial</label>
                            <input type="text" name="initialDate" className="datepicker form-control input-sm" />
                        </Option>
                        <Option>
                            <label className="m-none">Data Final</label>
                            <input type="text" name="finalDate" className="datepicker form-control input-sm" />
                        </Option>
                        <Option>
                            <label className="m-none">Pedido</label>
                            <select name="statusOrder" className="form-control input-sm">
                                <option value=""></option>
                                {Object.keys(status_pedido).map(item => <option value={item}>{status_pedido[item].title}</option>)}
                            </select>
                        </Option>
                        <Option>
                            <label className="m-none">Pagamento</label>
                            <select name="statusPayment" className="form-control input-sm">
                                <option value=""></option>
                                {Object.keys(status_financeiro).map(item => <option value={item}>{status_financeiro[item].title}</option>)}
                            </select>
                        </Option>
                        <Option>
                            <label className="m-none">Buscar Por</label>
                            <input type="text" name="buscaAll" className="form-control input-sm" />
                        </Option>
                        <Option>
                            <label className="m-none">Por carga</label>
                            <select name="requestCargo" className="form-control input-sm">
                                <option value=""></option>
                                <option value="null"># Sem carregamento</option>
                                {dataFilter.map(item => <option value={item.id_cargo}>
                                    #{item.id_cargo}, {item.title_route} ({formatDateToView(item.data_saida)}), {status_carga_short(item.status)}
                                </option>)}
                            </select>
                        </Option>
                        <Option>
                            <label className="m-none">Por entrega</label>
                            <select name="statusDelivery" className="form-control input-sm">
                                <option value=""></option>
                                {Object.keys(status_mercadoria).map(item => <option value={item}>{status_mercadoria[item].title}</option>)}
                            </select>
                        </Option>
                        <Option>
                            <label className="m-none">Por Representante</label>
                            <select name="representatives" className="form-control input-sm">
                                <option value=""></option>
                                <option value="null"># Sem representante</option>
                                {representatives.map(item => (
                                    <option value={item._id}>{item.nome_fantasia || item.nome}</option>
                                ))}
                            </select>
                        </Option>
                        <Option>
                            <button onClick={() => filters.filtrate()} className="btn btn-info btn-filtrate">Filtrar</button>
                            <button onClick={() => filters.clearFilters(dataSource.dataAll)} className="btn btn-default">
                                <i className="icon-refresh m-none" />
                            </button>
                        </Option>
                    </FiltersOptions>
                </Filters>
                <Table
                    className="table-requests v-align"
                    columns={columns}
                    dataSource={dataSource.dataTable}
                    loading={dataSource.loading}
                    pagination={{ pageSize: 20 }}
                />
            </div>
        </>
    )
}
