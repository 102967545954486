import styled from 'styled-components';

export const BoxStatus = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #FFF;
    border: 1px solid #DDD;
    border-bottom-color: #999;
    box-shadow: 0 0 4px rgba(0, 0, 0, .1);
    user-select: none;
`;

export const StatusTitle = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px;
    color: #444;
    font-size: 3.2rem;
    line-height: 35px;
    font-weight: 700;
`;

export const StatusTitleIcon = styled.i`
    opacity: .4;
`;

export const StatusContent = styled.div`
    font-size: 1.4rem;
    color: #666;
`;
