import React, { useCallback, useEffect, useRef, useState } from 'react';
import { doMoney, formatDateToView } from '@utils/functions';
import Api from '@utils/api';

import { Table } from 'antd';
import { Row, Col } from 'react-bootstrap';
import Alert from '@components/Alert';
import { FiltersOptions, Filters, Option } from '@assets/filter';

export default function PageReportRequestPerRepresentative() {
    const [representatives, setRepresentatives] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [dates, setDates] = useState([]);
    const representativeRef = useRef(null);
    const dateRef = useRef(null);

    useEffect(() => {
        Api('/representative/').then(response => setRepresentatives(response))
        Api('/request/requestdates/').then(response => setDates(response))
    }, []);

    const handleGetRequests = useCallback(() => {
        const date = dateRef.current.value;
        const id_representative = representativeRef.current.value;

        Api('/request/report/requestperrepresentative/', { date, id_representative })
            .then(response => setDataSource(response))
    }, []);

    const columns = [
        {
            title: 'Cliente',
            render: ({ id_customer, nome, razao_social, nome_fantasia, cidade }) =>
                !razao_social
                    ? (
                        <>
                            <span className="d-block"><b>#{(`00000${id_customer}`).substr(-5)}</b> - {nome}</span>
                            <span className="d-block">{cidade ? cidade : 'Retirada'}</span>
                        </>
                    ) : (
                        <>
                            <span className="d-block"><b>#{(`00000${id_customer}`).substr(-5)}</b> - {razao_social}</span>
                            <span className="d-block"><b>Nome Fant.:</b> {nome_fantasia}</span>
                            <span className="d-block">{cidade ? cidade : 'Retirada'}</span>
                        </>
                    )
        }, {
            title: 'Pedido',
            render: ({ id_request, valor_total, dt_entrega }) => (
                <>
                    <span className="d-block"><b>Pedido:</b> #{(`00000${id_request}`).substr(-5)}</span>
                    <span className="d-block"><b>Valor:</b> {doMoney(valor_total)}</span>
                    <span className="d-block"><b>Data Entrega:</b> {formatDateToView(dt_entrega)}</span>
                </>
            )
        }
    ]

    return (
        <div className="bg-padrao">
            <Alert onOpen={alert} />
            <Row>
                <Col md="6">
                    <h1>Relatório - Faturado por representantes</h1>
                </Col>
            </Row>
            <div className="hardLine"></div>
            <Filters>
                <FiltersOptions id="filterOptions">
                    <Option>
                        <label className="m-none">Período</label>
                        <select ref={dateRef} className="form-control input-sm">
                            <option value=""></option>
                            {dates.map(date => <option value={date.mes}>{date.mes}</option>)}
                        </select>
                    </Option>
                    <Option>
                        <label className="m-none">Representante</label>
                        <select ref={representativeRef} className="form-control input-sm">
                            <option value=""></option>
                            {representatives.map(item => <option value={item.id_representative}>{item.nome}</option>)}
                        </select>
                    </Option>
                    <Option>
                        <button onClick={handleGetRequests} className="btn btn-info btn-filtrate">Filtrar</button>
                    </Option>
                </FiltersOptions>
            </Filters>
            <Table
                className="table-requests"
                columns={columns}
                dataSource={dataSource}
                size="small"
                pagination={{
                    defaultPageSize: 10000,
                    hideOnSinglePage: true
                }}
            />
            <Row className="justify-content-end">
                <Col xl="2" md="3">
                    <span style={{ padding: '10px', textAlign: 'right' }} className="d-block span-p bg-clean-default">
                        <b className="d-block">Total</b>
                        {doMoney(dataSource.reduce((total, prox) => total + prox.valor_total, 0))}
                    </span>
                </Col>
            </Row>
        </div>
    )
}
