import React from 'react'

export default function PageErrorPermission() {
    return (
        <div style={{
            display: "block",
            padding: "10px",
            textAlign: "center",
            background: "#3498DB",
            color: "#FFF"
            }}>
            <strong>Você não tem permissão para acessar essa página.</strong>
            <div>Entre em contato com a administração</div>
        </div>
    )
}