import React, { createContext, useCallback, useContext, useRef, useState } from 'react';

import Api from '@utils/api';
import Alert from '@components/Alert';
import { stringCheck } from '@components/Constants';
import { doMoney, formatDateToView, createAlert } from '@utils/functions';

import { Row, Col } from 'react-bootstrap';

const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [alert, setAlert] = useState({});

  const dataRef = useRef(null);
  const codeRef = useRef(null);
  const valueRef = useRef(null);

  const registerPayment = useCallback((payment, request, handleUpdate) => {
    function handleClickSave() {
      const data = {
        id_payment: payment,
        id_request: request,
        code_payment: codeRef.current.value
      };

      Api('/request/register-bank/', data)
        .then(response => {
          const auxData = response.find(item => item.id_payment === payment);

          handleUpdate(auxData);
          setAlert({ title: 'Sucesso', html: 'Boleto registrado com sucesso!' });
        }).catch(err => createAlert(err.message));
    }

    const parsedHTML = (
      <div>
        <div className="form-group">
          <label style={{ marginTop: '10px' }}>Código de identificação</label>
          <input ref={codeRef} className="form-control input-sm maskNumber" />
        </div>
        <div className="hardLine"></div>
        <div className="text-right">
          <span onClick={handleClickSave} className="btn btn-orange" title="Confirmar">
            <i className="fa fa-check-square-o"></i> Confirmar
          </span>
          <span onClick={_ => setAlert({})} className="btn btn-default" title="Cancelar">
            <i className="icon-ban" /> Cancelar
          </span>
        </div>
      </div>
    );

    setAlert({
      title: 'Registrar boleto',
      type: 'warning',
      html: parsedHTML,
      hasButton: false
    });
  }, []);

  const confirmPayment = useCallback((payment, request, handleUpdate) => {
    function handleClickSave() {
      const data = {
        id_payment: payment,
        id_request: request,
        date_payment: dataRef.current.value,
      };

      Api('/request/confirm-payment/', data)
        .then(response => {
          const auxData = response.find(item => item.id_payment === payment);

          handleUpdate(auxData);
          setAlert({ title: 'Sucesso', html: 'Pagamento confirmado com sucesso!' });
        }).catch(err => createAlert(err.message));
    }

    const parsedHTML = (
      <div>
        <span>
          Confirmar pagamento <b>#{(`00000${payment}`).substr(-5)}</b>?
        </span>
        <div style={{ marginTop: '16px' }}>
          <span style={{ display: 'inline-block', width: '220px' }}>Informe data de pagamento:</span>
          <input ref={dataRef} type="date" className="form-control" style={{ display: 'inline-block', width: '170px' }} />
        </div>
        <div className="hardLine"></div>
        <div className="text-right">
          <span onClick={handleClickSave} className="btn btn-orange" title="Confirmar">
            <i className="fa fa-check-square-o"></i> Confirmar
          </span>
          <span onClick={() => setAlert({})} className="btn btn-default" title="Cancelar">
            <i className="icon-ban" /> Cancelar
          </span>
        </div>
      </div>
    );

    setAlert({
      title: 'Confirmar pagamento',
      type: 'warning',
      html: parsedHTML,
      hasButton: false
    });
  }, []);

  const registerCheck = useCallback((data, handleUpdate) => {
    function handleClickSave() {
      const request = {
        id_payment: data.id_payment,
        id_request: data.id_request,
        code_check: codeRef.current.value,
        date_check: dataRef.current.value,
        value_check: valueRef.current.value,
      };

      Api('/request/register-check/', request)
        .then(response => {
          const auxData = response.find(item => item.id_payment === data.id_payment);

          handleUpdate(auxData);
          setAlert({ title: 'Sucesso', html: 'Cheque registrado com sucesso!' });
        }).catch(err => createAlert(err.message));
    }

    const parsedHTML = (
      <div>
        <Row>
          <Col md="4">
            <div className="form-group">
              <label>Data de depósito</label>
              <input ref={dataRef} defaultValue={formatDateToView(data.vencimento)} className="form-control input-sm maskDate" />
            </div>
          </Col>
          <Col md="4">
            <div className="form-group">
              <label>Valor</label>
              <input ref={valueRef} defaultValue={doMoney(data.valor)} className="form-control input-sm maskMoney" />
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label style={{ marginTop: '10px' }}>Código de identificação</label>
              <input ref={codeRef} className="form-control input-sm maskCheck" />
            </div>
          </Col>
        </Row>
        <div className="hardLine"></div>
        <div className="text-right">
          <span
            onClick={handleClickSave}
            className="btn btn-orange"
            title="Confirmar"
          >
            <i className="fa fa-check-square-o"></i> Salvar
        </span>
          <span
            onClick={() => setAlert({})}
            className="btn btn-default"
            title="Cancelar"
          >
            <i className="icon-ban" /> Cancelar
        </span>
        </div>
      </div>
    );

    setAlert({
      title: 'Registrar cheque',
      type: 'warning',
      html: parsedHTML,
      hasButton: false
    });
  }, []);

  const changeCheck = useCallback((id_payment, id_check, action, handleUpdate) => {
    function handleClickSave() {
      const data = {
        id_payment,
        id_check,
        action,
        deposit: dataRef.current?.value
      };

      Api('/request/change-check/', data)
        .then(response => {
          const auxData = response.find(item => item.id_payment === id_payment);

          handleUpdate(auxData);
          setAlert({ title: 'Sucesso', html: 'Pagamento confirmado com sucesso!' });
        }).catch(err => createAlert(err.message));
    }

    const parsedHTML = (
      <div>
        <label style={{ marginTop: '10px' }}>
          Alterar cheque <b>#{(`00000${id_check}`).substr(-5)}</b> para <b>{stringCheck[action]}</b>?
        </label>
        {action === 1 && (
          <div style={{ marginTop: '16px' }}>
            <span style={{ display: 'inline-block', width: '220px' }}>Informe data de depósito:</span>
            <input ref={dataRef} type="date" className="form-control" style={{ display: 'inline-block', width: '170px' }} />
          </div>
        )}
        <div className="hardLine"></div>
        <div className="text-right">
          <span onClick={handleClickSave} className="btn btn-orange" title="Confirmar">
            <i className="fa fa-check-square-o"></i> Confirmar
          </span>
          <span onClick={_ => setAlert({})} className="btn btn-default" title="Cancelar">
            <i className="icon-ban" /> Cancelar
          </span>
        </div>
      </div>
    );

    setAlert({
      title: `Cheque #${(`00000${id_check}`).substr(-5)}`,
      type: 'warning',
      html: parsedHTML,
      hasButton: false
    });
  }, []);

  return (
    <PaymentContext.Provider value={{ registerPayment, registerCheck, confirmPayment, changeCheck }}>
      <Alert onOpen={alert} />
      {children}
    </PaymentContext.Provider>
  )
}

export default function usePayment() {
  const context = useContext(PaymentContext);

  if (!context) {
    throw new Error('Falha ao carregar usePayment.');
  }

  return context;
}
