import React from 'react';

import { Table } from 'antd';
import { Row, Col } from 'react-bootstrap';

import useTable from '@hooks/useTable';
import usePayment from '@hooks/usePayment';
import customFilters from '@utils/customFilters';
import { stringPayment } from '@components/Constants';
import { doMoney, formatDateToView, compareDates } from '@utils/functions';

import { FiltersHeader, FiltersOptions, Filters, Option } from '@assets/filter';

const PageFinancialCheckPending = () => {
  const { registerCheck } = usePayment();
  const { dataSource, handleUpdate, handleFiltered } = useTable('/request/financial/check/pending/');
  const filters = new customFilters(dataSource.dataAll, handleFiltered);

  const handlePaymentUpdate = (dataDTO) => {
    const auxData = [...dataSource.dataAll];
    const index = auxData.findIndex(item => item.id_payment === dataDTO.id_payment);
    auxData[index] = dataDTO;

    handleUpdate(auxData);
  };

  const columns = [
    {
      title: 'Código',
      render: ({ id_payment }) => `#${(`00000${id_payment}`).substr(-5)}`
    }, {
      title: 'Pedido',
      render: ({ id_request }) => `#${(`00000${id_request}`).substr(-5)}`
    }, {
      title: 'Tipo',
      render: ({ tipo_pagamento }) => stringPayment[tipo_pagamento]
    }, {
      title: 'Valor',
      render: ({ valor }) => <div>{doMoney(valor)}</div>
    }, {
      title: '',
      align: 'right',
      render: data => (
        <span
          onClick={() => registerCheck(data, handlePaymentUpdate)}
          className="btn btn-default btn-icons"
          title="Adicionar cheque"
        >
          <i className="icon-plus m-none" />
        </span>
      )
    }
  ];

  return (
    <div className="bg-padrao">
      <Row>
        <Col md="6">
          <h1>Financeiro - Cheques Pendentes</h1>
        </Col>
      </Row>
      <div className="hardLine"></div>
      <Filters>
        <FiltersHeader>
          <i className="fa fa-filter"></i> Filtros
                </FiltersHeader>
        <FiltersOptions id="filterOptions">
          <Option>
            <label className="m-none">Buscar Por</label>
            <input type="text" name="buscaAll" className="form-control input-sm" />
          </Option>
          <Option>
            <button onClick={() => filters.filtrate()} className="btn btn-info btn-filtrate">Filtrar</button>
            <button onClick={() => filters.clearFilters(dataSource.dataAll)} className="btn btn-default">
              <i className="icon-refresh m-none" />
            </button>
          </Option>
        </FiltersOptions>
      </Filters>
      <Table
        className="table-requests"
        size="small"
        columns={columns}
        loading={dataSource.loading}
        dataSource={dataSource.dataTable}
        pagination={{ pageSize: 20 }}
      />
    </div>
  )
}

export default PageFinancialCheckPending;
