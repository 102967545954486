import styled from 'styled-components';

export const AlertModal = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    z-index: 9999;
`;

export const AlertContent = styled.div`
    position: relative;
    display: block;
    width: 90%;
    max-width: 550px;
    padding: 0 30px 20px;
    background: #FFF;
    color: #666;
    font-size: 16px;
`;

export const AlertTitle = styled.div`
    display: block;
    font-family: 'Quicksand', sans-serif;
    float: left;
    margin: 0 -30px 30px;
    width: calc(100% + 60px);
    background: #22313C;
    padding: 20px;
    color: #FFF;
    font-size: 22px;
    font-weight: 300;
    user-select: none;
    cursor: default;

    i {
        margin-right: 5px;
    }
`;

export const AlertSeparator = styled.hr`
    display: inline-block;
    width: 100%;
    margin: 0 0 30px;
`;

export const AlertClose = styled.button`
    float: right;
    width: 180px;
    padding: 8px;
    background: #F56C2D;
    color: #FFF;
    border: none;
    border-radius: 4px;
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    outline: none;
`;