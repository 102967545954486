import styled from 'styled-components';

export const ShippingBox = styled.div`
    display: table;
    width: 100%;
    padding: 20px;
    border-radius: 4px;
    cursor: default;
`;

export const ShippingIcon = styled.div`
    display: table-cell;
    vertical-align: middle;
    padding-right: 18px;

    span {
        display: block;
        width: 30px;
        height: 30px;
    }
`;

export const ShippingContent = styled.div`
    display: table;
    width: 100%;
    font-size: 14px;
    color: #666;

    h4 {
        display: block;
        margin: 0;
        font-weight: 400;
        line-height: 1.25;
        font-size: 16px;
        color: #333;
    }

    span {
        display: block;
    }

    button {
        color: #3483fa;
        font-size: 14px;
        background: none;
        border: none;
    }
`;

export const ShippingInfo = styled.div`
    display: table-cell;
    vertical-align: middle;
`;

export const ShippingAction = styled.div`
    display: table-cell;
    vertical-align: middle;
    text-align: ${props => props.selection ? 'left' : 'right'};
`;
