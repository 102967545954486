import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useForm from '@hooks/useForm';
import Api from '@utils/api';
import { AlertReload } from '@utils/functions';

import { Row, Col } from 'react-bootstrap';
import LoadBotton from '@components/LoadBotton';
import Alert from '@components/Alert';

export default function PageVehicleForm() {
  const { idVehicle } = useParams();
  const { values, handleChange, handleSubmit, setValuesDefault } = useForm()
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({})

  const handleSendForm = data => {
    if (loading) {
      return
    }

    AlertReload(
      '/vehicles/',
      '/veiculos/update/',
      data,
      setLoading,
      setAlert
    )
  }

  useEffect(() => {
    if (typeof idVehicle !== 'undefined')
      Api(`/vehicles/${idVehicle}`)
        .then(response => setValuesDefault(response));
  }, [])

  return (
    <>
      <Alert onOpen={alert} />
      <div className="bg-padrao">
        <Row>
          <Col md="6">
            <h1>
              {typeof idVehicle === 'undefined' ? 'Cadastrar novo' : 'Atualizar'} Veículo
              </h1>
          </Col>
          <Col md="6" className="text-right">
            <a href="/veiculos/" className="btn btn-success">
              <i className="icon-arrow-left"></i>
                  Voltar para lista
                </a>
          </Col>
        </Row>
        <div className="hardLine"></div>
        <form onSubmit={handleSubmit(handleSendForm, setAlert)}>
          <Row>
            <Col md="2">
              <div className="form-group">
                <label>Modelo</label>
                <input
                  onChange={handleChange}
                  type="text"
                  name="model"
                  value={values.model}
                  className="form-control input-sm" />
              </div>
            </Col>
            <Col md="2">
              <div className="form-group">
                <label>Placa</label>
                <input
                  onChange={handleChange}
                  type="text"
                  name="board"
                  value={values.board}
                  className="form-control input-sm" />
              </div>
            </Col>
            <Col md="2">
              <div className="form-group">
                <label>Cubagem (m³)</label>
                <input
                  onChange={handleChange}
                  type="text"
                  name="cubagem"
                  value={values.cubagem}
                  className="form-control input-sm" />
              </div>
            </Col>
            <Col md="12">
              <div className="form-group">
                <label>Observações</label>
                <textarea onChange={handleChange} name="observacao" rows="5" className="form-control input-sm">{values.observacao}</textarea>
              </div>
            </Col>
          </Row>
          <div className="hardLine"></div>
          <LoadBotton Loader={loading} />
        </form>
      </div>
    </>
  )
}
