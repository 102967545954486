import React from 'react';

import { Table } from 'antd';
import { Row, Col } from 'react-bootstrap';

import useTable from '@hooks/useTable';
import usePayment from '@hooks/usePayment';
import customFilters from '@utils/customFilters';
import { stringCheck } from '@components/Constants';
import { doMoney, formatDateToView, compareDates } from '@utils/functions';

import { FiltersHeader, FiltersOptions, Filters, Option } from '@assets/filter';

const PageFinancialCheck = () => {
    const { changeCheck } = usePayment();
    const { dataSource, handleUpdate, handleFiltered } = useTable('/request/financial/check/');
    const filters = new customFilters(dataSource.dataAll, handleFiltered);

    const handlePaymentUpdate = (dataDTO) => {
        const auxData = [...dataSource.dataAll];
        const index = auxData.findIndex(item => item.id_payment === dataDTO.id_payment);
        auxData[index] = dataDTO;

        handleUpdate(auxData);
    };

    const columns = [
        {
            title: 'Pedido',
            render: ({ id_request }) => `#${(`00000${id_request}`).substr(-5)}`
        }, {
            title: 'Data p/ dep.',
            render: ({ deposit_date, status }) => !compareDates(deposit_date) && status < 2
                ? (
                    <span style={{ color: 'RED', fontWeight: 'bold' }}>
                        {formatDateToView(deposit_date)}
                    </span>
                )
                : formatDateToView(deposit_date)
        }, {
            title: 'Código',
            render: ({ code_check }) => <span className="maskCheck">{code_check}</span>
        }, {
            title: 'Status',
            render: ({ status }) => stringCheck[status]
        }, {
            title: 'Valor',
            align: 'right',
            render: ({ check_value }) => doMoney(check_value)
        }, {
            title: '',
            align: 'right',
            width: '15%',
            render: ({ id: id_check, id_payment, status }) => !status
                ? (
                    <span
                        onClick={() => changeCheck(id_payment, id_check, 1, handlePaymentUpdate)}
                        className="btn btn-default btn-icons"
                        title="Depósitado"
                    >
                        <i className="fa fa-check-square-o m-none"></i>
                    </span>
                ) : status === 1 && (
                    <>
                        <span
                            onClick={() => changeCheck(id_payment, id_check, 3, handlePaymentUpdate)}
                            className="btn btn-default btn-icons"
                            title="Informar retorno/estorno"
                        >
                            <i className="fa fa-window-close-o m-none"></i>
                        </span>
                        <span
                            onClick={() => changeCheck(id_payment, id_check, 2, handlePaymentUpdate)}
                            className="btn btn-default btn-icons"
                            title="Confirmar compensação"
                        >
                            <i className="fa fa-check-square-o m-none"></i>
                        </span>
                    </>
                )
        }
    ]

    return (
        <div className="bg-padrao">
            <Row>
                <Col md="6">
                    <h1>Financeiro (Cheques)</h1>
                </Col>
            </Row>
            <div className="hardLine"></div>
            <Filters>
                <FiltersHeader>
                    <i className="fa fa-filter"></i> Filtros
                </FiltersHeader>
                <FiltersOptions id="filterOptions">
                    <Option>
                        <label className="m-none">Buscar Por</label>
                        <input type="text" name="buscaAll" className="form-control input-sm" />
                    </Option>
                    <Option>
                        <button onClick={() => filters.filtrate()} className="btn btn-info btn-filtrate">Filtrar</button>
                        <button onClick={() => filters.clearFilters(dataSource.dataAll)} className="btn btn-default">
                            <i className="icon-refresh m-none" />
                        </button>
                    </Option>
                </FiltersOptions>
            </Filters>
            <Table
                className="table-requests"
                size="small"
                columns={columns}
                loading={dataSource.loading}
                dataSource={dataSource.dataTable}
                pagination={{ pageSize: 20 }}
            />
        </div>
    )
}

export default PageFinancialCheck;
