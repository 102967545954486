import styled from 'styled-components';

export const UploadGal = styled.div`
    display: block;
    width: 100%;
    text-align: center;

    .inputPreview {
        position: relative;
        display: block;
        padding: 8px;
        background: #F5F5F5;

        i:not(.fa) {
            display: none;
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 30px;
            color: #666;
            z-index: 10;
        }

        img {
            width: 100%;
            z-index: 5;
        }

        &:hover {
            i {
                display: block;
            }

            img {
                opacity: .9
            }
        }
    }
`;

export const TitleSection = styled.span`
    float: left;
    font-size: 16px;
    color: #b4bcc2;
    display: block;
    width: 100%;
    padding: 2px 0 5px;
    text-align: left;
    border-bottom: 1px solid #EEE;
`;