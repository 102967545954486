import { useState, useEffect } from "react";
import Api from '@utils/api';

const useTable = (URL = null) => {
    const [dataSource, setDataSource] = useState({ dataAll: [], dataTable: [], loading: true });
    const [currentSearch, setSurrentSearch] = useState('');

    useEffect(() => {
        if (!URL) {
            return;
        }

        Api(URL).then(response => setDataSource(
            {
                dataAll: response,
                dataTable: response,
                loading: false
            }
        ))
    }, [URL]);

    useEffect(() => {
        var btnPagination = document.querySelectorAll('.ant-pagination-item')

        btnPagination.forEach(page => {
            page.addEventListener('click', () => window.scrollTo(0, 0))
        })
    }, [dataSource])

    const handleSearched = event => {
        const searchText = event.target.value;

        const newDataTable = dataSource.dataAll.filter(key => {
            return Object.values(key).filter(obsValue => {
                return String(obsValue).toLowerCase().includes(searchText.toLowerCase())
            }).length > 0
        });

        setSurrentSearch(searchText)
        setDataSource({ ...dataSource, dataTable: newDataTable })
    }

    const handleUpdate = data => {
        setDataSource({
            dataAll: data,
            dataTable: data,
            loading: false
        });

        if (currentSearch) {
            handleSearched(currentSearch);
        }
    }

    const handleFiltered = data => {
        const auxData = { ...dataSource }
        auxData.dataTable = data
        setDataSource(auxData)
    }

    return { dataSource, handleSearched, handleUpdate, handleFiltered };
}

export default useTable;
