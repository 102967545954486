import React, { useCallback, useEffect, useRef, useState } from 'react';

import Alert from '@components/Alert';
import Api from '@utils/api';
import { isEmpty, formatDateToView, createAlert } from '@utils/functions';

import DetailsDados from './dados';
import DetailsPagamento from './pagamento';
import DetailsPedido from './pedido';
import DetailsNFe from './nfe';

import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalClose,
    ModalBody,
    ModalTitle,
    TabLink,
    TabTitle,
    AnnotationWrapper,
    Annotation,
    Author,
} from './style';

export default function PageRequestDetails({ requestData, setNewStatus }) {
    const inputRef = useRef(null);
    const [details, setDetails] = useState({})
    const [alert, setAlert] = useState({})

    const handleOutsideClick = e => {
        if (e.target.id === "modalInfos") {
            setDetails({})
        }
    }

    const applyDiscount = useCallback(() => {
        const data = {
            id_request: requestData.id_request,
            discount: inputRef.current.value,
        }

        Api('/request/apply-discount/', data)
            .then(response => {
                setAlert({})
                setDetails(response)
                createAlert('Desconto aplicado com sucesso!')
            })
    }, [requestData.id_request]);

    useEffect(() => {
        setDetails(requestData);

        if (isEmpty(requestData)) {
            return;
        }

        document.addEventListener('keydown', function (event) {
            if (event.keyCode !== 120) {
                return;
            }

            // if (requestData.forma_pagamento > 0) {
            //     createAlert('Somente para pagamento <b>À vista</b>');
            //     return;
            // }

            const parseHTML = (
                <>
                    <div>
                        <span style={{ display: 'inline-block', width: '290px', marginBottom: '10px' }}>
                            Informe quantidade da movimentação:
                        </span>
                        <input ref={inputRef} className="form-control maskPercent" style={{ display: 'inline-block', width: '50px' }} />
                    </div>
                    <div className="hardLine"></div>
                    <div className="text-right">
                        <span onClick={() => applyDiscount()} className="btn btn-info" title="Aplicar" style={{ width: '100px' }}>
                            Aplicar
                        </span>
                        <span onClick={() => setAlert({})} className="btn btn-default" title="Cancelar" style={{ width: '100px' }}>
                            Cancelar
                        </span>
                    </div>
                </>
            )

            setAlert({
                title: 'Aplicação de desconto',
                type: 'warning',
                html: parseHTML,
                hasButton: false
            })
        });
    }, [applyDiscount, requestData])

    if (isEmpty(details)) {
        return null;
    }

    return (
        <>
            <Alert onOpen={alert} />
            <Modal id="modalInfos" onClick={handleOutsideClick}>
                <ModalContent>
                    <ModalHeader>
                        <ModalTitle>
                            Detalhes Pedido: #{(`00000${details.id_request}`).substr(-5)}
                        </ModalTitle>
                        <ModalClose onClick={_ => { setDetails({}) }}></ModalClose>
                    </ModalHeader>
                    <ModalBody>
                        <ul className="nav nav-tabs">
                            <li><TabLink data-toggle="tab" href="#dados" className="active">Dados</TabLink></li>
                            <li><TabLink data-toggle="tab" href="#pedido">Pedido</TabLink></li>
                            {details.status_pedido >= 3 && details.status_pedido <= 7 && (
                                <>
                                    <li><TabLink data-toggle="tab" href="#pagamento">Pagamento</TabLink></li>
                                    <li><TabLink data-toggle="tab" href="#nfe">NFe</TabLink></li>
                                </>
                            )}
                        </ul>

                        <div className="tab-content">
                            <DetailsDados dataInfos={details} />
                            <DetailsPedido dataInfos={details} setState={setNewStatus} />
                            {details.status_pedido >= 3 && details.status_pedido <= 7 && (
                                <>
                                    <DetailsPagamento dataInfos={details} />
                                    <DetailsNFe dataInfos={details} />
                                </>
                            )}
                        </div>

                        {details.observacao && (
                            <AnnotationWrapper>
                                <TabTitle>
                                    Observação do pedido
                                </TabTitle>
                                <Annotation fixed request>
                                    {details.observacao}
                                </Annotation>
                            </AnnotationWrapper>
                        )}

                        {details.anotacoes.length > 0 && (
                            <AnnotationWrapper>
                                <TabTitle>
                                    Observações do cliente
                                </TabTitle>
                                <div>
                                    {details.anotacoes.map(item => (
                                        <Annotation fixed={item.fixed}>
                                            {item.annotation}
                                            <Author>
                                                {item.responsible} - {formatDateToView(item.dt_register)}
                                            </Author>
                                        </Annotation>
                                    ))}
                                </div>
                            </AnnotationWrapper>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
