import { useState } from 'react';
import { createAlert } from '@utils/functions';

const useForm = _ => {
    const [values, setValues] = useState({})

    const handleChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        if (target.type === 'checkbox') {
            handleCheck(name, value)
            return
        }

        handleData(name, value)
    }

    const handleData = (name, value) => {
        const auxValues = { ...values }
        const auxArray = name.split('.')

        // CHANGE ARRAYS
        if (auxArray.length > 1) {
            auxValues[auxArray[0]][auxArray[1]][auxArray[2]] = value
        } else {
            auxValues[name] = value
        }

        setValues(auxValues)
    }

    const handleCheck = (name, value) => {
        const auxValues = { ...values }

        if (name.includes('[]')) {
            const arrayName = name.replace('[]', '')
            const arrayValue = !isNaN(value)
                ? Number(value)
                : String(value)

            // Cria o array
            !auxValues[arrayName] && (auxValues[arrayName] = [])

            // Index to remove item array
            const index = auxValues[arrayName].indexOf(arrayValue)

            auxValues[arrayName].includes(arrayValue)
                ? auxValues[arrayName].splice(index, 1)
                : auxValues[arrayName].push(arrayValue)

            setValues(auxValues)
            return
        }

        auxValues[name] = !auxValues[name]
        setValues(auxValues)
    }

    const handleSubmit = (callback, alert = false) => async event => {
        event.preventDefault()

        try {
            const elements = event.target.querySelectorAll('input, select, textarea')
            const auxValues = { ...values }

            await elements.forEach(element => {
                const inputValue = element.value
                const inputName = element.getAttribute('name')
                const required = element.hasAttribute('required')
                element.removeAttribute('style');

                if (required && !inputValue) {
                    element.setAttribute('style', 'boder-color: red');
                    throw new Error('Existem campos que devem ser preenchidos.');
                }

                if (!auxValues[inputName] && !!inputValue) {
                    auxValues[inputName] = inputValue;
                }
            })

            callback(auxValues)
        } catch (err) {
            createAlert(err.message)
        }
    }

    const setValuesDefault = data => {
        setValues(data)
    }

    return { values, handleChange, handleSubmit, setValuesDefault }
}

export default useForm
