import React, { useEffect, useState } from 'react'
import Chart from 'chart.js'
import Api from '@utils/api'
import { doMoney } from '@utils/functions'

import { Row, Col } from 'react-bootstrap'
import {
    BoxStatus,
    StatusTitle,
    StatusTitleIcon,
    StatusContent
} from './style'

const defaultValues = {
    total_pedidos: 0,
    valor_aprovado: 0,
    total_aprovado: 0,
    total_cancelado: 0
}

export default function PageHome() {
    const [dashboard, setDashboard] = useState(defaultValues)

    useEffect(_ => {
        /**
         * CHART
         */
        const dataSet = {
            labels: [],
            datasets: []
        }

        const ctx = document.getElementById('myChart').getContext('2d')
        const myChart = new Chart(ctx, {
            type: 'bar',
            data: dataSet,
            options: {
                legend: { display: false },
                responsive: true,
                tooltips: {
                    intersect: false,
                    callbacks: {
                        title: (item, data) => {
                            const label = data.datasets[item[0].datasetIndex]
                            const totalItems = label.data[item[0].index].x
                            return `${label.label}: ${totalItems}`
                        },
                        label: ({ datasetIndex, index }, data) => {
                            const label = data.datasets[datasetIndex].data[index]
                            return doMoney(label.y)
                        }
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: (value) => {
                                return doMoney(value)
                            }
                        }
                    }]
                }
            }
        })

        /**
         * DATA REQUEST
         */
        Api('/request/dashboard/')
        .then(response => {
            setDashboard(response.dashboard)
            
            /**
             * CHAT DATA
             */
            dataSet.labels = response.chartTime.labels
            dataSet.datasets = response.chartTime.datasets
            myChart.update()
        })
    }, [])

    return (
        <>
        <h1 style={{ marginBottom: '20px' }}>Dashboard</h1>
        <Row>
            <Col md="3">
                <BoxStatus>
                    <StatusTitle>
                        # {dashboard.total_pedidos}
                        <StatusTitleIcon className="icon-layers" />
                    </StatusTitle>
                    <StatusContent>
                        Total pedidos realizados nos últimos 30 dias
                    </StatusContent>
                </BoxStatus>
            </Col>
            <Col md="3">
                <BoxStatus style={{ borderBottomColor: "#27AE60" }}>
                    <StatusTitle>
                        {doMoney(dashboard.valor_aprovado)}
                        <StatusTitleIcon className="icon-wallet" />
                    </StatusTitle>
                    <StatusContent>
                        Valor total em compras aprovadas nos últimos 30 dias
                    </StatusContent>
                </BoxStatus>
            </Col>
            <Col md="3">
                <BoxStatus style={{ borderBottomColor: "#27AE60" }}>
                    <StatusTitle>
                        # {dashboard.total_aprovado}
                        <StatusTitleIcon className="icon-like" />
                    </StatusTitle>
                    <StatusContent>
                        Total pedidos aprovados nos últimos 30 dias
                    </StatusContent>
                </BoxStatus>
            </Col>
            <Col md="3">
                <BoxStatus style={{ borderBottomColor: '#E67E22' }}>
                    <StatusTitle>
                        # {dashboard.total_cancelado}
                        <StatusTitleIcon className="icon-dislike" />
                    </StatusTitle>
                    <StatusContent>
                        Orçamentos/Pedidos cancelados nos últimos 30 dias
                    </StatusContent>
                </BoxStatus>
            </Col>
            <Col>
                <div className="hardLine"></div>
                <h1 style={{ marginBottom: '20px', fontSize: '16px' }}>Acompanhamento Anual</h1>
                <canvas id="myChart" style={{ width: '100%', height: '500px' }} />
            </Col>
        </Row>
        </>
    )
}