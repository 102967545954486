import React from 'react';
import { doMoney, formatDateToView } from '@utils/functions';
import useTable from '@hooks/useTable';
import customFilters from '@utils/customFilters';

import { Table } from 'antd';
import { Row, Col } from 'react-bootstrap';
import Alert from '@components/Alert';
import { FiltersOptions, Filters, Option } from '@assets/filter'

export default function PageReportRequestPerDay() {
    const { dataSource, handleFiltered } = useTable('/request/report/requestperday');
    const filters = new customFilters(dataSource.dataAll, handleFiltered)

    const columns = [
        {
            title: 'Data',
            render: ({ day }) => formatDateToView(day)
        }, {
            title: 'Pedidos',
            dataIndex: 'pedidos'
        }, {
            title: 'Valor total',
            render: ({ valor_total }) => doMoney(valor_total)
        }
    ]

    return (
        <div className="bg-padrao">
            <Alert onOpen={alert} />
            <Row>
                <Col md="6">
                    <h1>Relatório - pedidos por dia</h1>
                </Col>
            </Row>
            <div className="hardLine"></div>
            <Filters>
                <FiltersOptions id="filterOptions">
                    <Option>
                        <label className="m-none">Buscar Por</label>
                        <input type="text" name="buscaAll" className="form-control input-sm" />
                    </Option>
                    <Option>
                        <button onClick={_ => filters.filtrate()} className="btn btn-info btn-filtrate">Filtrar</button>
                        <button onClick={_ => filters.clearFilters(dataSource.dataAll)} className="btn btn-default">
                            <i className="icon-refresh m-none" />
                        </button>
                    </Option>
                </FiltersOptions>
            </Filters>
            <Table
                className="table-requests"
                columns={columns}
                dataSource={dataSource.dataTable}
                size="small"
                pagination={{
                    defaultPageSize: 10000,
                    hideOnSinglePage: true
                }}
            />
        </div>
    )
}
