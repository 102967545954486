import React from 'react';
import useTable from '@hooks/useTable';
import { URL_BASE } from "@utils/auth";

import { Row, Col } from 'react-bootstrap';
import { Table, Input, Tag } from 'antd';
import noImage from '@assets/not-found.jpg';

export default function PageRepresentative() {
    const { dataSource, handleSearched } = useTable('/representative/');
    
    const columns = [
        {
            title: '',
            width: '100px',
            render: data => <img src={data.foto === null ? noImage : `${URL_BASE}/${data.foto}`} width="100%" />
        }, {
            title: 'Geral',
            render: data => (
                <>
                    <div><b>Nome:</b> {data.nome}</div>
                    {data.tipo == '1' ? (
                        <div><b>CPF:</b> {data.cpf}</div>
                    ) : (
                            <>
                                <div><b>CNPJ:</b> {data.cnpj}</div>
                                <div><b>R. Social:</b> {data.razao_social}</div>
                                <div><b>N. Fantasia:</b> {data.nome_fantasia}</div>
                            </>
                        )}
                </>
            )
        }, {
            title: 'Contato',
            render: data => (
                <>
                    <div><b>E-mail:</b> {data.email}</div>
                    <div><b>Tel.:</b> {data.contato}</div>
                </>
            )
        }, {
            title: 'Status',
            render: data => {
                let status = {color: '', title: ''};

                switch (data.status) {
                    case 0:
                        status.color = 'red';
                        status.title = 'Inativo';
                        break;

                    case 1:
                        status.color = 'green';
                        status.title = 'Ativo';
                        break;

                    case 2:
                        status.color = '';
                        status.title = 'Em Analise';
                        break;
                }

                return (
                    <Tag color={status.color}>
                        {status.title}
                    </Tag>
                )
            }
        }, {
            title: 'Ação',
            key: 'acao',
            align: 'right',
            width: '30%',
            render: data => (
                <a href={`/representantes/update/${data.id_representative}`} className="btn btn-primary">
                    <i className="icon-note" /> Editar
                </a>
            )
        }
    ];

    return (
        <div className="bg-padrao">
            <Row>
                <Col md="6">
                    <h1>Nossos Representantes</h1>
                </Col>
                <Col md="6" className="text-right">
                    <a href="/representantes/create" className="btn btn-success">
                        <i className="icon-plus"></i>
                        Cadastrar Novo
                    </a>
                </Col>
            </Row>
            <div className="hardLine"></div>
            <Row className="justify-content-end">
                <Col md="3">
                    <div className="input-icon">
                        <Input onChange={handleSearched} name="nome_fantasia" placeholder="Procurar (N. Fantasia)" />
                        <i className="icon-magnifier" />
                    </div>
                </Col>
            </Row>
            <Table columns={columns} dataSource={dataSource.dataTable} loading={dataSource.loading} pagination={{pageSize: 20}} />
        </div>
    )
}