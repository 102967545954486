import styled from 'styled-components';

export const ModalBG = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 1005;
`;

export const ModalContent = styled.div`
    display: block;
    width: 100%;
    color: #666;
    font-size: 16px;
    padding: 15px;
    max-width: 530px;
    background: #FFF;
`;

export const ModalHeader = styled.div`
    position: relative;
    margin: -15px;
    margin-bottom: 5px;
    padding: 15px 30px;
    border-bottom: 1px solid #E5E5E5;
    background-color: #eee;
`;

export const ModalTitle = styled.h3`
    font-size: 16px !important;
    color: #666 !important;
`;

export const ModalClose = styled.button`
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    border: none;

    &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #666;
        transform: rotate(45deg) translate(9px, -6px);
    }

    &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #666;
        transform: rotate(-45deg) translate(8px, 10px);
    }
`;

export const CardWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    margin-bottom: 20px;
`;

export const CardData = styled.label`
    position: relative;
    display: block;
    width: 100%;
    color: #333;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.25;
    padding: 10px 0;
    user-select: none;
    padding-left: ${props => props.hasClosed ? '0' : '40px'};
    cursor: ${props => props.hasClosed ? 'default' : 'pointer'};

    &:hover {
        background: #F5F5F5;
    }

    & > div:first-child {
        display: ${props => props.hasClosed ? 'none' : 'block'};
    }
`;

export const CardCheck = styled.div`
    position: absolute;
    top: 13px;
    left: 10px;
    width: 16px;
    height: 16px;

    input {
        display: none;
    }
`;

export const CheckOuter = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, .25);

    transition: border-color .12s cubic-bezier(0, 0, .2,1) 0ms;
    // border-radius: 50%;

    input:checked ~ & {
        border-color: #3483FA;
    }
`;

export const CheckInner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    transform: scale(.5);
    transition: transform .12s cubic-bezier(0, 0, .2,1) 0ms, background-color .12s cubic-bezier(0, 0, .2,1) 0ms, -webkit-transform .12s cubic-bezier(0, 0, .2,1) 0ms;
    // border-radius: 50%;

    input:checked ~ & {
        background-color: #3483FA;
    }
`;

export const CardContent = styled.div`
    width: 100%;
    font-size: 14px;
    color: #666;

    h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        margin: 0;
        font-weight: 400;
        line-height: 1.25;
        font-size: 16px;
        color: #333;
    }
`;