import React, { useState, useEffect, useRef, useCallback } from 'react';
import { formatDateToView, isEmpty } from '@utils/functions';
import Api from '@utils/api';

import {
    Modal,
    Content,
    Title,
    AnnotationWrapper,
    Annotation,
    Author,
    Separator,
    Options,
    Footer,
    Button
} from './style';

const Annotations = ({ annotations: ResponseDTO }) => {
    const [dataAnnotation, setDataAnnotation] = useState({});
    const textRef = useRef(null);
    const dateRef = useRef(null);
    const checkRef = useRef(null);

    const setAnnotation = useCallback(() => {
        const Request = {
            id_Customer: ResponseDTO.customer,
            Annotation: textRef.current.value,
            Date: dateRef.current.value,
            Fixed: checkRef.current.checked,
        }

        Api('/customer/annotation/', Request)
            .then(response => {
                setDataAnnotation(response);
                textRef.current.value = '';
                dateRef.current.value = '';
                checkRef.current.checked = false;
            });
    }, [ResponseDTO.customer]);

    const handleDrop = useCallback(id_annotation => {
        const auxValue = [...dataAnnotation.data];
        auxValue.find(item => item.id === id_annotation).fixed = false;

        setDataAnnotation({ ...dataAnnotation, data: auxValue });

        Api(`/customer/annotation/drop/${id_annotation}`)
    }, [dataAnnotation]);

    useEffect(() => {
        if (!ResponseDTO) {
            return
        }

        setDataAnnotation(ResponseDTO)
    }, [ResponseDTO])

    if (isEmpty(dataAnnotation)) {
        return null;
    }

    return (
        <Modal>
            <Content>
                <Title>Anotações</Title>
                <AnnotationWrapper>
                    {dataAnnotation.data.length > 0
                        ? dataAnnotation.data.map(item => (
                            <Annotation fixed={item.fixed}>
                                <p dangerouslySetInnerHTML={{ __html: item.annotation }}></p>
                                <Author>
                                    {item.responsible} - {formatDateToView(item.dt_register)}
                                </Author>

                                {item.fixed === 1 && (
                                    <i
                                        onClick={() => handleDrop(item.id)}
                                        className="icon-close"
                                    />
                                )}
                            </Annotation>
                        ))
                        : (
                            <Annotation>Nenhuma anotação encontrada deste usuário.</Annotation>
                        )
                    }
                </AnnotationWrapper>
                <Separator />
                <textarea ref={textRef} className="form-control input-sm" rows="4"></textarea>
                <Options>
                    <label htmlFor="checkAnnotation">
                        <input ref={checkRef} id="checkAnnotation" type="checkbox" />
                        &nbsp;Fixar anotação
                    </label>
                    <input ref={dateRef} type="date" />
                </Options>
                <Separator />
                <Footer>
                    <Button send onClick={setAnnotation}>
                        Salvar
                    </Button>
                    <Button onClick={() => setDataAnnotation({})}>
                        Fechar
                    </Button>
                </Footer>
            </Content>
        </Modal>
    )
};

export default Annotations;
