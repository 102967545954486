import styled from 'styled-components';

export const RenderMenu = styled.div`
    position: absolute;
    display: block;
    width: 100%;
    max-height: 210px;
    background: #FFF;
    color: #666;
    font-size: 12px;
    // border: 1px solid #CCC;
    // border-top: 0;
    overflow: auto;
    z-index: 5;

    div {
        padding: 0 8px;
    }
`;