import React, { useState, useEffect } from 'react';
import useForm from '@hooks/useForm';
import Api from '@utils/api';
import { isEmpty } from '@utils/functions';

import {
    ModalBG,
    ModalContent,
    ModalHeader,
    ModalTitle,
    ModalClose,
    CardWrapper,
    CardData,
    CardCheck,
    CheckOuter,
    CheckInner,
    CardContent
} from './style';

const Romaneio = ({ dataCargo, setAlert }) => {
    const { values, handleChange, setValuesDefault } = useForm()
    const [requests, setRequests] = useState([])
    const [data, setData] = useState({})

    const handleSendRequests = dataCargo => {
        Api(`/request/set-cargo/${data.id_cargo}`, dataCargo)
            .then(_ => {
                setAlert({
                    title: 'SUCESSO!!!',
                    html: 'Carregamento atualizado!'
                });
            }).catch(err => setAlert({
                html: err.message,
                type: 'warning'
            }));
    }

    useEffect(_ => {
        if (isEmpty(dataCargo)) {
            return
        }

        const requestsAlreadySelected = []
        const { data: romaneio, requests } = dataCargo;

        requests.map(order => order.selected && (
            requestsAlreadySelected.push(order.id_request)
        ));

        setData(romaneio)
        setRequests(requests)
        setValuesDefault({ request: requestsAlreadySelected })
    }, [dataCargo]) // eslint-disable-line

    useEffect(() => {
        const total_carregado = requests.filter(order => (
            values.request?.includes(order.id_request)
        )).reduce((total, obj) => total + obj.cubagem, 0);

        if (total_carregado > data.cubagem_veiculo) {
            setAlert({
                html: 'Carregamento superior ao suportado pelo veículo.',
                type: 'warning'
            });
        }
    }, [values]) // eslint-disable-line

    return !isEmpty(data) && (
        <ModalBG>
            <ModalContent>
                <ModalHeader>
                    <ModalTitle>Carregamento #{(`00000${data.id_cargo}`).substr(-5)}</ModalTitle>
                    <ModalClose onClick={_ => setData({})}></ModalClose>
                </ModalHeader>
                <CardWrapper>
                    {requests.map(request => (
                        <CardData hasClosed={data.status > 0}>
                            <CardCheck>
                                <input
                                    defaultChecked={values.request?.includes(
                                        request.id_request
                                    )}
                                    onChange={handleChange}
                                    type="checkbox"
                                    name="request[]"
                                    value={request.id_request}
                                />
                                <CheckOuter />
                                <CheckInner />
                            </CardCheck>
                            <CardContent>
                                <h4>
                                    #{request.id_request} - {request.cliente}
                                </h4>
                                <span className="d-block">
                                    Destino: {request.cidade} - {request.uf} | Contato: <span className="maskTelefone">{request.contato}</span>
                                </span>
                                <span className="d-block">
                                    Cubagem: {(request.cubagem).toFixed(2)}m³
                                </span>
                            </CardContent>
                        </CardData>
                    ))}
                </CardWrapper>
                <div className="actions float-right">
                    <span onClick={_ => setData({})} className="btn btn-default">
                        Fechar
                    </span>
                    {data.status == 0 && (
                        <span onClick={_ => handleSendRequests(values)} className="btn btn-orange">
                            <i className="fa fa-check-square-o"></i>
                            Confirmar
                        </span>
                    )}
                </div>
            </ModalContent>
        </ModalBG>
    )
}

export default Romaneio;
