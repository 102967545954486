import React, { useRef, useState } from 'react';
import Api from '@utils/api';
import { URL_BASE } from "@utils/auth";
import useTable from '@hooks/useTable';
import { printFile } from '@utils/functions';

import { Row, Col } from 'react-bootstrap';
import { Table, Input, Tag } from 'antd';
import Alert from '@components/Alert';
import noImage from '@assets/not-found.jpg';

const tagStatus = {
    0: { color: '', title: 'Desativado' },
    1: { color: '#27AE60', title: 'Diponível' },
    2: { color: '#2C3E50', title: 'Só Ecommerce' },
    3: { color: '#E67E22', title: 'Só Interno' },
};

export default function PageProduct() {
    const dateRef = useRef(null);
    const quantRef = useRef(null);
    const { dataSource, handleSearched, handleUpdate } = useTable('/products/')
    const [alert, setAlert] = useState({})

    const setStock = id_product => {
        const funcSetStock = movementType => {
            const data = {
                id_product,
                action: movementType,
                date: dateRef.current.value,
                stock: quantRef.current.value,
            }

            Api('/products/stock/', data)
                .then(response => {
                    const auxData = [...dataSource.dataAll]
                    const indexItem = auxData.findIndex(prod => prod.id_product === id_product)

                    auxData[indexItem].estoque = response.estoque
                    handleUpdate(auxData)
                    setAlert({})
                })
                .catch(error => setAlert({
                    html: error.message,
                    type: 'warning'
                }))
        }

        const parseHTML = (
            <>
                <div>
                    <span style={{ display: 'inline-block', width: '290px', marginBottom: '10px' }}>
                        Informe quantidade da movimentação:
                    </span>
                    <input ref={quantRef} type="number" className="form-control" style={{ width: '100px', display: 'inline-block' }} />
                </div>
                <div>
                    <span style={{ display: 'inline-block', width: '290px' }}>
                        Informe data da movimentação:
                    </span>
                    <input ref={dateRef} type="date" className="form-control" style={{ width: '170px', display: 'inline-block' }} />
                </div>
                <div className="hardLine"></div>
                <div className="text-right">
                    <span onClick={_ => funcSetStock(1)} className="btn btn-info" title="Entrada" style={{ width: '100px' }}>
                        Entrada
                    </span>
                    <span onClick={_ => funcSetStock(2)} className="btn btn-orange" title="Saida" style={{ width: '100px' }}>
                        Saida
                    </span>
                    <span onClick={() => setAlert({})} className="btn btn-default" title="cancelar" style={{ width: '100px' }}>
                        Cancelar
                    </span>
                </div>
            </>
        )

        setAlert({
            title: 'Alteração de estoque',
            type: 'warning',
            html: parseHTML,
            hasButton: false
        })
    }

    const getMissingProducts = _ => {
        Api('/products/stock/')
            .then(response => printFile(response.data))
    }

    const getTopSelling = _ => {
        Api('/products/selling/')
            .then(response => printFile(response.data))
    }

    const columns = [
        {
            title: '',
            width: '100px',
            render: data => <img src={data.foto === null ? noImage : `${URL_BASE}/${data.foto}`} width="100%" />
        }, {
            title: 'Código - Título',
            render: data => (
                <>
                    <div>{data.codigo}</div>
                    <div>{data.titulo}</div>
                </>
            )
        }, {
            title: 'Estoque',
            align: 'center',
            dataIndex: 'estoque'
        }, {
            title: 'Status',
            render: data => (
                <Tag color={tagStatus[data.status].color}>
                    {tagStatus[data.status].title}
                </Tag>
            )
        }, {
            title: 'Ação',
            align: 'right',
            width: '200px',
            render: data => (
                <>
                    <span onClick={_ => setStock(data.id_product)} className="btn btn-default btn-icons" title="Alterar estoque">
                        <i className="icon-equalizer m-none" />
                    </span>
                    <a href={`/produtos/update/${data.id_product}`} className="btn btn-primary">
                        <i className="icon-note" /> Editar
                    </a>
                </>
            )
        }
    ];

    return (
        <>
            <Alert onOpen={alert} />
            <div className="bg-padrao">
                <Row>
                    <Col md="6">
                        <h1>Produtos</h1>
                    </Col>
                    <Col md="6" className="text-right">
                        <span onClick={getMissingProducts} className="btn btn-default btn-icons" title="Baixar relatório de estoque">
                            <i className="icon-list m-none" />
                        </span>
                        <span onClick={getTopSelling} className="btn btn-default btn-icons" title="Baixar relatório de vendas">
                            <i className="icon-basket-loaded m-none" />
                        </span>
                        <a href="/produtos/create" className="btn btn-success">
                            <i className="icon-plus"></i>
                            Cadastrar Novo
                        </a>
                    </Col>
                </Row>
                <div className="hardLine"></div>
                <Row className="justify-content-end">
                    <Col md="3">
                        <div className="input-icon">
                            <Input onChange={handleSearched} placeholder="Procurar" />
                            <i className="icon-magnifier" />
                        </div>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={dataSource.dataTable}
                    loading={dataSource.loading}
                    pagination={{ pageSize: 20 }}
                // expandable={{
                //     rowExpandable: data => (data.components.length > 0),
                //     expandedRowRender: tableInternalRender
                // }}
                />
            </div>
        </>
    )
}
