import styled from 'styled-components';

export const UploadGal = styled.div`
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
`;

export const FileIcon = styled.label`
    display: block;
    text-align: center;
    padding-top: 60px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;

    i {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 25px;
    }

    i:not(.fa) {
        top: 5px;
        right: 5px;
        transform: none;
        font-size: initial;
    }
`;

export const TitleSection = styled.span`
    float: left;
    font-size: 16px;
    color: #b4bcc2;
    display: block;
    width: 100%;
    padding: 2px 0 5px;
    text-align: left;
    border-bottom: 1px solid #EEE;
    margin-bottom: 5px;
`;