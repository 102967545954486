import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '@utils/auth';

// VIEWS
import PageHome from '@views/PageHome';
import PageLogin from '@views/PageLogin';
import PageErrorPermission from '@views/PageErrorPermission';

import PageUser from '@views/PageUser/List';
import PageUserForm from '@views/PageUser/Form';

import PageFinancial from '@views/PageFinancial/List';
import PageFinancialCheck from '@views/PageFinancial/ListChecks';
import PageFinancialCheckPending from '@views/PageFinancial/ListChecksPending';
import PageFinancialTranfer from '@views/PageFinancial/ListTransfer';

import PageProduct from '@views/PageProduct/List';
import PageProductForm from '@views/PageProduct/Form';

import PageCollaborator from '@views/PageCollaborator/List';
import PageCollaboratorForm from '@views/PageCollaborator/Form';

import PageRepresentative from '@views/PageRepresentative/List';
import PageRepresentativeForm from '@views/PageRepresentative/Form';

import PageRequestList from '@views/PageRequest/List';
import PageRequestForm from '@views/PageRequest/Form';
import PageReportRepresentativeDelivery from '@views/PageReportRequestPerRepresentative/Delivery';
import PageReportRepresentativedate from '@views/PageReportRequestPerRepresentative/Date';
import PageReportRequestPerCustumer from '@views/PageReportRequestPerCustumer';
import PageReportRequestPerDay from '@views/PageReportRequestPerDay';

import PageCustomer from '@views/PageCustomer/List';
import PageCustomerForm from '@views/PageCustomer/Form';

import PageTrajectory from '@views/PageTrajectory/List';
import PageTrajectoryForm from '@views/PageTrajectory/Form';

import PageVehicle from '@views/PageVehicle/List';
import PageVehicleForm from '@views/PageVehicle/Form';

import PageCargo from '@views/PageCargo/List';
import PageCargoForm from '@views/PageCargo/Form';

import PageNetwork from '@views/PageNetwork/List';
import PageNetworkForm from '@views/PageNetwork/Form';
import PageReportNetwork from '@views/PageReportNetwork';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props =>
        isAuthenticated() ? (
            <Component {...props} />
        ) : (
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            )
    } />
);

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/login" component={PageLogin} />
            <PrivateRoute exact path="/dashboard" component={PageHome} />

            <PrivateRoute exact path="/users" component={PageUser} />
            <PrivateRoute exact path="/user/create" component={PageUserForm} />
            <PrivateRoute exact path="/user/update/:idUser" component={PageUserForm} />

            <PrivateRoute exact path="/financeiro" component={PageFinancial} />
            <PrivateRoute exact path="/financeiro/cheques" component={PageFinancialCheck} />
            <PrivateRoute exact path="/financeiro/cheques_pendentes" component={PageFinancialCheckPending} />
            <PrivateRoute exact path="/financeiro/transferencias" component={PageFinancialTranfer} />

            <PrivateRoute exact path="/produtos" component={PageProduct} />
            <PrivateRoute exact path="/produtos/create" component={PageProductForm} />
            <PrivateRoute exact path="/produtos/update/:idProduct" component={PageProductForm} />

            <PrivateRoute exact path="/colaboradores/" component={PageCollaborator} />
            <PrivateRoute exact path="/colaboradores/create" component={PageCollaboratorForm} />
            <PrivateRoute exact path="/colaboradores/update/:idCollaborator" component={PageCollaboratorForm} />

            <PrivateRoute exact path="/representantes" component={PageRepresentative} />
            <PrivateRoute exact path="/representantes/create" component={PageRepresentativeForm} />
            <PrivateRoute exact path="/representantes/update/:idRepresentative" component={PageRepresentativeForm} />

            <PrivateRoute exact path="/clientes" component={PageCustomer} />
            <PrivateRoute exact path="/clientes/create" component={PageCustomerForm} />
            <PrivateRoute exact path="/clientes/update/:idCustomer" component={PageCustomerForm} />

            <PrivateRoute exact path="/pedidos/:cliente?" component={PageRequestList} />
            <PrivateRoute exact path="/pedidos/update/:idRequest" component={PageRequestForm} />

            <PrivateRoute exact path="/rotas" component={PageTrajectory} />
            <PrivateRoute exact path="/rotas/create" component={PageTrajectoryForm} />
            <PrivateRoute exact path="/rotas/update/:idRoute" component={PageTrajectoryForm} />

            <PrivateRoute exact path="/veiculos" component={PageVehicle} />
            <PrivateRoute exact path="/veiculos/create" component={PageVehicleForm} />
            <PrivateRoute exact path="/veiculos/update/:idVehicle" component={PageVehicleForm} />

            <PrivateRoute exact path="/cargas" component={PageCargo} />
            <PrivateRoute exact path="/cargas/create" component={PageCargoForm} />

            <PrivateRoute exact path="/redes" component={PageNetwork} />
            <PrivateRoute exact path="/redes/create" component={PageNetworkForm} />
            <PrivateRoute exact path="/redes/update/:idNetwork" component={PageNetworkForm} />

            <PrivateRoute exact path="/relatorio" component={PageReportRequestPerDay} />
            <PrivateRoute exact path="/relatorio/network" component={PageReportNetwork} />
            <PrivateRoute exact path="/relatorio/pedidos/dia" component={PageReportRequestPerDay} />
            <PrivateRoute exact path="/relatorio/pedidos/cliente" component={PageReportRequestPerCustumer} />
            <PrivateRoute exact path="/relatorio/pedidos/representante/delivery" component={PageReportRepresentativeDelivery} />
            <PrivateRoute exact path="/relatorio/pedidos/representante/date" component={PageReportRepresentativedate} />

            <PrivateRoute path="/403" component={PageErrorPermission} />
            <Redirect to={{ pathname: "/dashboard" }} />
        </Switch>
    );
}
